import { breakpoints } from "customHooks/useBreakpoint";
import styled from "styled-components";

export const Image = styled.img`
  object-fit: cover;
  max-height: 35vh;
  padding: 0.5rem;
  flex-grow: 1;
  @media (max-width: ${breakpoints.md}px) {
    max-height: 20vh;
  }
`;

export const Description = styled.div<{ breakpoint?: string }>`
  font: inherit;
  color: var(--primary-colors-5-capgemini-dark-grey);
  line-height: 1.5;
  font-size: 1rem;
  display: inline-block;
  margin: .5rem 0;
  text-align: center;
  @media (max-width: ${breakpoints.md}px) {
    font-size: 0.75rem;
  }
`;

export const ImageWithDescriptionWrapper = styled.div`
  display: flex;
  width: 50%;
  flex-direction: column;
  @media (max-width: ${breakpoints.sm}px) {
    width: 100%;
  }
`;