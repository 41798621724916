import React from "react";
import CloseButton from "components/closeButton/CloseButton";
import { BuildingContent } from "components/buildingContent/buildingContent";
import ContentLoader from "components/contentLoader/ContentLoader";
import { BreakpointI } from "customHooks/useBreakpoint";
import sanitizer from "util/sanitizer";
import linkIcon from "assets/icon-link.svg";
import emailIcon from "assets/icon-envelope-regular.svg";
import phoneIcon from "assets/icon-mobile-phone.svg";
import { WroclawPopupDataI } from "assets/backup/wroclaw/office/popupBackupData";
import {
  DivideLine,
  WroclawOfficePopupContainer,
  CloseButtonContainer,
  TopSection,
  MiddleSection,
  BottomSection,
  BottomSectionColumn,
  HostInfoContainer,
  HostEmail,
  HostName,
  HostPhone,
  YourPersonal,
  GradientSection,
  HostImage,
  MainContentSection,
} from "./WroclawPopupStyles";

export interface WroclawPopupI extends BreakpointI {
  togglePopup?: () => void;
  popupData: WroclawPopupDataI;
}

export const WroclawPopupDesktop = ({
  popupData,
  togglePopup,
  breakpoint,
}: WroclawPopupI) => {
  const {
    host_image,
    building_k_photo,
    building_k_reception,
    building_k_name,
    building_k_street,
    building_k_city,
    building_h_photo,
    building_h_reception,
    building_h_name,
    building_h_street,
    building_h_city,
  } = popupData;

  const closePopup = () => {
    if (togglePopup) togglePopup();
  };

  return (
    <WroclawOfficePopupContainer breakpoint={breakpoint}>
      {!Object.keys(popupData).length ? (
        <ContentLoader isDarkMode />
      ) : (
        <>
          <CloseButtonContainer>
            <CloseButton buttonType="onDark" onClick={closePopup} />
          </CloseButtonContainer>
          <HostInfoContainer>
            <HostImage src={host_image} alt="host image" />
            <HostName
              // secured by sanitizer
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: sanitizer(popupData!.host_name),
              }}
            />
            <YourPersonal>
              <p>{` ${popupData!.host_description} `}</p>
            </YourPersonal>
            <HostEmail
              dangerouslySetInnerHTML={{
                __html: sanitizer(
                  `<img src=${emailIcon} alt="email icon"/>`
                ).concat(popupData!.host_email),
              }}
            />
            <HostPhone
              dangerouslySetInnerHTML={{
                __html: sanitizer(
                  `<img src=${phoneIcon} alt="phone icon" />`
                ).concat(popupData!.host_phone),
              }}
            />
          </HostInfoContainer>
          <MainContentSection>
            <GradientSection>
              <TopSection>
                <BuildingContent
                  building="Building K"
                  buildingDesk={building_k_reception}
                  buildingCity={building_k_city}
                  buildingName={building_k_name}
                  buildingStreet={building_k_street}
                  image={building_k_photo}
                />
                <BuildingContent
                  building="Building H"
                  buildingDesk={building_h_reception}
                  buildingCity={building_h_city}
                  buildingName={building_h_name}
                  buildingStreet={building_h_street}
                  image={building_h_photo}
                />
              </TopSection>
              <DivideLine />
              <MiddleSection>
                <a
                  target="_blank"
                  href="https://goo.gl/maps/7dzfEL9Spv2dXwFA8"
                  id="middle_a"
                  rel="noreferrer"
                >
                  {popupData.parking_field}
                </a>
                <a
                  href="https://goo.gl/maps/7dzfEL9Spv2dXwFA8"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img src={linkIcon} alt="link icon" />
                </a>
              </MiddleSection>
            </GradientSection>
            <BottomSection>
              <BottomSectionColumn>
                <BottomSectionColumn
                  // secured by sanitizer
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(popupData!.content1),
                  }}
                />
              </BottomSectionColumn>

              <BottomSectionColumn
                // secured by sanitizer
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{
                  __html: sanitizer(popupData!.content2),
                }}
              />

              <BottomSectionColumn
                // secured by sanitizer
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{
                  __html: sanitizer(popupData!.content3),
                }}
              />
            </BottomSection>
          </MainContentSection>
        </>
      )}
    </WroclawOfficePopupContainer>
  );
};
