import React, { useContext } from "react";
import { HotelsApiI } from "containers/hotels/Hotels";
import ContentLoader from "components/contentLoader/ContentLoader";
import TextDataIcon from "components/textDataIcon/TextDataIcon";
import Popup from "components/popup/Popup";
import { BreakpointI } from "customHooks/useBreakpoint";
import { ReactComponent as IconPhone } from "assets/icon-mobile-phone.svg";
import { ReactComponent as IconLink } from "assets/icon-link.svg";
import { WroclawContext } from "pages/Wroclaw/Wroclaw";
import {
  Contact,
  DivideLine,
  HotelImage,
  HotelSubtitle,
  HotelTitle,
  InformationSection,
  LinkSection,
  PopupWrapper,
  SubtitleContent,
  UpperSection,
} from "./HotelPopupStyles";

interface HotelPopupI extends BreakpointI {
  hotelsData: Array<HotelsApiI>;
}

export const HotelPopupMobile = ({ hotelsData, breakpoint }: HotelPopupI) => {
  const activeHotels = hotelsData.filter((hotel) => !!hotel.is_visible);
  const { hotelRef } = useContext(WroclawContext);
  return (
    <PopupWrapper ref={hotelRef}>
      {activeHotels.length &&
        activeHotels.map(
          ({
            name,
            address,
            image,
            website_address,
            phone_number,
            how_to_get_there,
          }: any) => (
            <Popup key={name} breakpoint={breakpoint}>
              {!activeHotels.length ? (
                <ContentLoader isDarkMode />
              ) : (
                <>
                  <UpperSection>
                    <HotelImage src={image} />
                    <InformationSection>
                      <HotelTitle>{name}</HotelTitle>
                      <HotelSubtitle>Address and contact</HotelSubtitle>
                      <SubtitleContent>
                        <p>{address}</p>
                        <Contact>
                          <TextDataIcon
                            title={phone_number}
                            textSize={0}
                            Icon={IconPhone}
                            iconPosition="left"
                          />
                          {website_address && (
                            <TextDataIcon
                              title="website"
                              href={website_address}
                              textSize={0}
                              Icon={IconLink}
                            />
                          )}
                        </Contact>
                      </SubtitleContent>
                    </InformationSection>
                  </UpperSection>
                  <DivideLine />
                  <LinkSection>
                    <TextDataIcon
                      title="How to get there"
                      href={how_to_get_there}
                      textSize={0}
                      Icon={IconLink}
                    />
                  </LinkSection>
                </>
              )}
            </Popup>
          )
        )}
    </PopupWrapper>
  );
};
