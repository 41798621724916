import React, { useState } from "react";
import { ServiceShape } from "components/serviceShape/ServiceShape";
import {
  serviceAssetIcons,
  serviceAssetMaps,
} from "assets/services/serviceAssetsList";
import {
  AboutServicesContainer,
  ServicesMapContainer,
} from "./AboutCapgeminiServicesStyles";

export interface AboutServicesI {
  aboutServicesData: aboutServicesDataI;
}

interface aboutServicesDataI {
  tile_1_title: string;
  tile_1_content: string;
  tile_2_title: string;
  tile_2_content: string;
  tile_3_title: string;
  tile_3_content: string;
  tile_4_title: string;
  tile_4_content: string;
}
export const AboutCapgeminiServices = ({
  aboutServicesData,
  ...props
}: AboutServicesI) => {
  const [selectedShape, setSelectedShape] = useState(0);
  const selectShape = (serviceName: number) => {
    setSelectedShape(serviceName);
  };
  return (
    <AboutServicesContainer {...props}>
      <span>
        <ServiceShape
          serviceIcon={serviceAssetIcons.softwareIcon}
          contentHeader={aboutServicesData.tile_1_title}
          contentText={aboutServicesData.tile_1_content}
          isFocused={selectedShape === 1}
          onClick={() => selectShape(1)}
        />
      </span>
      <span>
        <ServiceShape
          serviceIcon={serviceAssetIcons.businessIcon}
          contentHeader={aboutServicesData.tile_2_title}
          contentText={aboutServicesData.tile_2_content}
          isFocused={selectedShape === 2}
          onClick={() => selectShape(2)}
        />
      </span>
      <span>
        <ServiceShape
          serviceIcon={serviceAssetIcons.cloudIcon}
          contentHeader={aboutServicesData.tile_3_title}
          contentText={aboutServicesData.tile_3_content}
          isFocused={selectedShape === 3}
          onClick={() => selectShape(3)}
        />
      </span>
      <span>
        <ServiceShape
          serviceIcon={serviceAssetIcons.applicationIcon}
          contentHeader={aboutServicesData.tile_4_title}
          contentText={aboutServicesData.tile_4_content}
          isFocused={selectedShape === 4}
          onClick={() => selectShape(4)}
        />
      </span>
      <ServicesMapContainer>
        <img src={serviceAssetMaps[selectedShape]} alt="service_map" />
      </ServicesMapContainer>
    </AboutServicesContainer>
  );
};
