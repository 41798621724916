import React, { useEffect, useState } from "react";
import { AgendaTopText } from "components/agendaTopText/AgendaTopText";
import { AgendaCalendar } from "containers/agendaCalendar/AgendaCalendar";
import { Timeline } from "components/timeline/Timeline";
import {
  AgendaEvent,
  AgendaEventDataI,
} from "containers/agendaEvent/AgendaEvent";
import { AgendaI } from "containers/agendaTokenPopup/AgendaTokenPopup";
import { checkDateTimePeriod } from "util/timeChecks/timeChecks";
import { SidepageTopNavigation } from "components/sidepageTopNavigation/SidepageTopNavigation";
import { DownloadButton } from "containers/agendaContent/downloadButton/DownloadButton";
import {
  AgendaContentWrapper,
  AgendaEventsWrapper,
  AgendaEventWithTimelineWrapper,
} from "./AgendaContentStyles";

interface AgendaContentI {
  breakpoint: string;
  agendaData: AgendaI;
}
export const AgendaContent = ({ breakpoint, agendaData }: AgendaContentI) => {
  const days = agendaData.agenda_days.map((element) => ({
    date: element.date,
    weekday: element.weekday,
  }));

  const setPresentDay = () => {
    const today = new Date().setHours(0, 0, 0, 0);
    const daysInAgenda = days.map((day) =>
      new Date(day.date).setHours(0, 0, 0, 0)
    );
    const presentDay =
      daysInAgenda.indexOf(today) >= 0 ? daysInAgenda.indexOf(today) : 0;
    return presentDay;
  };

  const [selectedDay, setSelectedDay] = useState(setPresentDay());
  const [period, setPeriod] = useState("");
  const [agendaEventsData, setAgendaEventsData] = useState(
    agendaData.agenda_days[selectedDay].agenda_events
  );

  const agendaPeriod = () => {
    const firstDay = days[0].date.slice(-2);
    const lastDay = days[days.length - 1].date.split("-");
    return `${firstDay}-${lastDay[2]}.${lastDay[1]}.${lastDay[0]}`;
  };
  useEffect(() => {
    setAgendaEventsData(agendaData.agenda_days[selectedDay].agenda_events);

    setPeriod(checkDateTimePeriod(agendaData.agenda_days[selectedDay].date));
  }, [selectedDay]);

  return (
    <>
      <SidepageTopNavigation
        breakpoint={breakpoint}
        logo={agendaData.company_logo}
      />
      <AgendaTopText
        logo={agendaData.company_logo}
        breakpoint={breakpoint}
        companyName={agendaData.company_name}
        period={agendaPeriod()}
      />
      <DownloadButton />
      <AgendaContentWrapper>
        <AgendaCalendar
          dates={days}
          selectedDay={selectedDay}
          setSelectedDay={setSelectedDay}
        />
        <AgendaEventsWrapper>
          {agendaEventsData.map((event) => (
            <AgendaEventWithTimelineWrapper
              key={`${event.title}-${event.start_time}-day:${selectedDay}`}
            >
              <Timeline
                eventStart={event.start_time.substring(0, 5)}
                eventEnd={event.finish_time.substring(0, 5)}
                period={period}
              />
              <AgendaEvent
                data={event as AgendaEventDataI}
                breakpoint={breakpoint}
              />
            </AgendaEventWithTimelineWrapper>
          ))}
        </AgendaEventsWrapper>
      </AgendaContentWrapper>
    </>
  );
};
