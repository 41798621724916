import { breakpoints } from "customHooks/useBreakpoint";
import styled from "styled-components";

interface ImageI {
  src: string,
  alt: string
}

export const BoldParagraph = styled.p`
  font: inherit;
  color: var(--primary-colors-5-capgemini-dark-grey);
  line-height: 1.33;
  font-weight: 500;
  font-size: 1.125rem;
`;

export const Paragraph = styled.p`
  font: inherit;
  color: var(--primary-colors-5-capgemini-dark-grey);
  line-height: 1.5;
  font-weight: 300;
  font-size: 1rem;
`;

export const TeamSpiritImgWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
  }
`;

export const LogosWrapper = styled.div`
  margin: 2rem 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 2rem;
  flex-wrap: wrap;
  @media (min-width: ${breakpoints.sm}px) {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
  }
`;

export const AwardLogoStyled = styled.img<ImageI>`
  width: 100%;
`;

export const TeamSpiritWrapper = styled.div`
  width: 78%;
  @media (max-width: ${breakpoints.lg}px) {
    width: 100%;
  }
`;