import styled from "styled-components";
import { ModalSectionHeaderStyledI } from "./ModalSectionHeader";

export const ModalSectionHeaderStyled = styled.h2<ModalSectionHeaderStyledI>`
  font-size: 1.5rem;
  font-weight: 300;
  color: var(--primary-colors-1-capgemini-blue);

  ${props => props.isSubHeader && `
    font-size: 1.25rem;
  `}
`;
