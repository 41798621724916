import { Dispatch } from "redux";
import { SELECT_PAGE } from "./types";

export interface selectPageI {
  type: string;
  currentPage: string;
  previousPage: string;
  currentPageIndex: number;
  previousPageIndex: number;
}
export const selectPage = (
  currentPage: string,
  previousPage: string,
  currentPageIndex: number,
  previousPageIndex: number
) => (dispatch: Dispatch<selectPageI>) => {
  dispatch({
    type: SELECT_PAGE,
    currentPage,
    previousPage,
    currentPageIndex,
    previousPageIndex,
  });
};
