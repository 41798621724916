import React from "react";
import { StyledTextDataIcon } from "./TextDataIconStyles";

export interface StyledTextDataIconI {
  iconPosition?: "left" | "right";
  textSize?: number;
}

export interface TextDataIconI extends StyledTextDataIconI {
  title: string;
  href?: string;
  Icon: any;
}

const TextDataIcon = ({
  title,
  href,
  iconPosition,
  textSize,
  Icon,
}: TextDataIconI) => 
    (title ?
    <StyledTextDataIcon
      href={href}
      target="_blank"
      iconPosition={iconPosition || "right"}
      textSize={textSize || 16}
    >
      <p>{title}</p>
      <Icon />
    </StyledTextDataIcon> :
    <span/>)


export default TextDataIcon;
