import logo1 from "./awards/01.webp";
import logo2 from "./awards/02.webp";
import logo3 from "./awards/03.webp";
import logo4 from "./awards/04.webp";
import logo5 from "./awards/05.webp";
import photo1 from "./photos/01.webp";
import photo2 from "./photos/02.webp";
import photo3 from "./photos/03.webp";
import photo4 from "./photos/04.webp";

const teamSpiritBackup = {
  id: 1,
  intro:
    "At Capgemini, we belive our success is delivered and sustained through our people and their expertise.",
  content:
    "That’s why we support our people in the development of their competencies and capabilities from the day they are hired and throughout their career, thereby ensuring that we achieve the full potential for our clients.",
  awards: [
    { logo: logo1 },
    { logo: logo2 },
    { logo: logo3 },
    { logo: logo4 },
    { logo: logo5 },
  ],
  photo_1_medium: photo1,
  description_1: "Fun, Team spirit and close cooperation",
  photo_2_medium: photo2,
  description_2: "Innovation & Design Workshop spaces",
  photo_3_medium: photo3,
  photo_4_medium: photo4,
};

export default teamSpiritBackup;
