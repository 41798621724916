import React from "react";
import CloseButton from "components/closeButton/CloseButton";
import { BuildingContent } from "components/buildingContent/buildingContent";
import ContentLoader from "components/contentLoader/ContentLoader";
import { BreakpointI } from "customHooks/useBreakpoint";
import sanitizer from "util/sanitizer";
import linkIcon from "assets/icon-link.svg";
import emailIcon from "assets/icon-envelope-regular.svg";
import phoneIcon from "assets/icon-mobile-phone.svg";
import { PoznanPopupDataI } from "assets/backup/poznan/office/popupBackupData";
import {
  DivideLine,
  PopupContainer,
  PopupCloseButtonContainer,
  TopSection,
  MiddleSection,
  BottomSection,
  BottomSectionColumn,
  HostInfoContainer,
  GradientSection,
  HostEmail,
  HostName,
  HostPhone,
  YourPersonal,
  HostImage,
  MainContentSection,
} from "./PoznanPopupStyles";

export interface PoznanPopupI extends BreakpointI {
  togglePopup?: () => void;
  poznanPopupData: PoznanPopupDataI;
}

export const PoznanPopupDesktop = ({
  poznanPopupData,
  breakpoint,
  ...props
}: PoznanPopupI) => {
  const {
    host_image,
    host_name,
    host_email,
    host_phone,
    host_description,
    building_photo,
    building_reception,
    building_city,
    building_name,
    building_street,
    parking_field,
    content1,
    content2,
    content3,
  } = poznanPopupData;

  const closePopup = () => {
    if (props.togglePopup) {
      props.togglePopup();
    }
  };

  return (
    <PopupContainer breakpoint={breakpoint}>
      {!Object.keys(poznanPopupData).length ? (
        <ContentLoader isDarkMode />
      ) : (
        <>
          <PopupCloseButtonContainer>
            <CloseButton buttonType="onDark" onClick={closePopup} />
          </PopupCloseButtonContainer>
          <HostInfoContainer breakpoint={breakpoint}>
            <HostImage src={host_image} alt="host image" />
            <HostName
              breakpoint={breakpoint}
              // secured by sanitizer
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: sanitizer(host_name),
              }}
            />
            <YourPersonal breakpoint={breakpoint}>
              <p>{` ${host_description} `}</p>
            </YourPersonal>
            <HostEmail
              breakpoint={breakpoint}
              dangerouslySetInnerHTML={{
                __html: sanitizer(
                  `<img src=${emailIcon} alt="emailIcon"/>`
                ).concat(host_email),
              }}
            />
            <HostPhone
              breakpoint={breakpoint}
              dangerouslySetInnerHTML={{
                __html: sanitizer(
                  `<img src=${phoneIcon} alt="phoneIcon" />`
                ).concat(host_phone),
              }}
            />
          </HostInfoContainer>
          <MainContentSection>
            <GradientSection>
              <TopSection>
                <BuildingContent
                  building="Building 6"
                  buildingDesk={building_reception}
                  buildingCity={building_city}
                  buildingName={building_name}
                  buildingStreet={building_street}
                  image={building_photo}
                />
              </TopSection>
              <DivideLine />
              <MiddleSection breakpoint={breakpoint}>
                <a
                  target="_blank"
                  href="https://goo.gl/maps/7dzfEL9Spv2dXwFA8"
                  id="middle_a"
                  rel="noreferrer"
                >
                  {parking_field}
                </a>
                <a
                  href="https://goo.gl/maps/7dzfEL9Spv2dXwFA8"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <img src={linkIcon} alt="link icon" />
                </a>
              </MiddleSection>
            </GradientSection>
            <BottomSection breakpoint={breakpoint}>
              <BottomSectionColumn>
                <BottomSectionColumn
                  // secured by sanitizer
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{
                    __html: sanitizer(content1),
                  }}
                />
              </BottomSectionColumn>

              <BottomSectionColumn
                // secured by sanitizer
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{
                  __html: sanitizer(content2),
                }}
              />

              <BottomSectionColumn
                // secured by sanitizer
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{
                  __html: sanitizer(content3),
                }}
              />
            </BottomSection>
          </MainContentSection>
        </>
      )}
    </PopupContainer>
  );
};
