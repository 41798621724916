import React from "react";
import ModalAccordion from "containers/modalAccordion/ModalAccordion";
import ModalNumber from "components/modalNumber/ModalNumber";
import sanitizer from "util/sanitizer";
import { ModalSectionHeader } from "components/modalSectionHeader/ModalSectionHeader";
import CitiesSection from "components/citiesSection/CitiesSection";
import { OurOfficesStyles, Paragraph, Numbers } from "./OurOfficesStyles";

const OurOffices = ({
  breakpoint,
  carouselDataYourBenefits,
  carouselDataWroclaw,
  carouselDataPoznan,
  ourOfficesData,
}: any) => (
  <OurOfficesStyles>
    <ModalSectionHeader text="Our Offices" />
    <Paragraph
      // secured by sanitizer
      // eslint-disable-next-line
      dangerouslySetInnerHTML={{
        __html: sanitizer(ourOfficesData.content),
      }}
    />
    <ModalAccordion
      data={carouselDataYourBenefits}
      height={breakpoint === "xs" ? "85%" : "77.25%"}
      title="Security standards"
    />
    <Numbers>
      <ModalNumber
        numberValue={ourOfficesData.stat_1_content}
        numberTitle={ourOfficesData.stat_1_title}
      />
      <ModalNumber
        numberValue={ourOfficesData.stat_2_content}
        numberTitle={ourOfficesData.stat_2_title}
      />
      <ModalNumber
        numberValue={ourOfficesData.stat_3_content}
        numberTitle={ourOfficesData.stat_3_title}
      />
    </Numbers>
    <CitiesSection />
    <ModalAccordion
      data={carouselDataWroclaw}
      height={breakpoint === "xs" ? "78%" : "66%"}
      title="Why Wrocław"
    />
    <ModalAccordion data={carouselDataPoznan} height={breakpoint === "xs" ? "87%" : "76%"} title="Why Poznań" />
  </OurOfficesStyles>
);

export default OurOffices;
