import React from "react";
import { useDispatch, useSelector } from "react-redux";
import toggleMute from "store/actions/muteActions";
import {
  StyledMuteButton,
  StyledMuteButtonCircle,
  StyledMuteButtonLine,
} from "./MuteButtonStyles";

interface muteStateI {
  muteReducer: {
    mute: boolean;
  };
}

export interface MuteButtonI {
  mute: boolean;
  toggleMuteFunc: () => void;
}
export const MuteButtonComponent = ({
  mute,
  toggleMuteFunc,
  ...props
}: MuteButtonI) => (
  <StyledMuteButton onClick={toggleMuteFunc} {...props}>
    <StyledMuteButtonCircle className={mute ? "muted" : ""}>
      <StyledMuteButtonLine />
      <StyledMuteButtonLine />
      <StyledMuteButtonLine />
    </StyledMuteButtonCircle>
  </StyledMuteButton>
);

const MuteButton = () => {
  const selector = (state: muteStateI) => state;
  const muteReducer = useSelector(selector);

  const dispatch = useDispatch();
  const toggleMuteFunc = () =>
    dispatch(toggleMute(muteReducer.muteReducer.mute));

  return (
    <MuteButtonComponent
      mute={muteReducer.muteReducer.mute}
      toggleMuteFunc={toggleMuteFunc}
    />
  );
};
export default MuteButton;
