import { CloseButtonStyled } from "components/closeButton/CloseButtonStyles";
import { breakpoints } from "customHooks/useBreakpoint";
import styled from "styled-components";

export const StyledListOfPointsPopup = styled.ul`
  box-sizing: border-box;
  overflow-y: overlay;
  width: calc(100% - 1rem);
  height: 90%;
  padding: 0;
  margin: 0.5rem 0 0 0;
  ::-webkit-scrollbar {
    border-radius: 4px;
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
  }
`;

export const ListPointsElement = styled.li`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

export const TextElement = styled.h3`
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const IconWrapper = styled.div`
  height: 40px;
  margin-right: 1rem;
  svg {
    justify-self: center;
    padding: 0;
    height: 100%;
  }
`;

export const ListOfPointsPopupWrapper = styled.div`
  @media (max-width: ${breakpoints.md}px) {
    z-index: 101;
    position: absolute;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    width: 85vw;
    height: 85vh;
    background: linear-gradient(
      90deg,
      var(--primary-colors-1-capgemini-blue) 0%,
      var(--primary-colors-2-capgemini-vibrant-blue) 100%
    );
    // padding: 3rem 1.2rem 0 1.2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--primary-colors-7-capgemini-white);
    border-radius: 4px;
    padding: 0.5rem;

    ${CloseButtonStyled} {
      align-self: flex-end;
    }
  }
`;
