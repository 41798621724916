import { isVisibleI } from "containers/agendaTokenPopup/AgendaTokenPopup";
import { breakpoints } from "customHooks/useBreakpoint";
import styled from "styled-components";

export const AgendaContainer = styled.div<isVisibleI>`
  z-index: 12;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    90deg,
    var(--primary-colors-1-capgemini-blue) 0%,
    var(--primary-colors-2-capgemini-vibrant-blue) 100%
  );
  align-items: center;

  ${props => !props.isVisible && `
    background: var(--primary-colors-7-capgemini-white);
  `}
`;

export const AgendaTokenPopupStyled = styled.div<isVisibleI>`  
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: var(--primary-colors-7-capgemini-white);
  border-radius: 4px;
  box-shadow: 0 2px 6px 2px var(--primary-colors-1-capgemini-blue);
  @media (max-width: ${breakpoints.xs}px) {
      width: 90%;
    }
  ${props => !props.isVisible ? `display: none;` : `display: flex;`}
`;
