import React, { useContext } from "react";
import ContentLoader from "components/contentLoader/ContentLoader";
import Popup from "components/popup/Popup";
import TextDataIcon from "components/textDataIcon/TextDataIcon";
import { BreakpointI } from "customHooks/useBreakpoint";
import { ReactComponent as IconLink } from "assets/icon-link.svg";
import { RestaurantPopupApiI } from "containers/RestaurantPopup/desktop/RestaurantPopup";
import { WroclawContext } from "pages/Wroclaw/Wroclaw";
import {
  PopupWrapper,
  UpperSection,
  InformationSection,
  SubtitleContent,
  Contact,
  LinkSection,
  DivideLine,
  RestaurantImage,
  RestaurantTitle,
  RestaurantSubtitle,
} from "./RestaurantPopupStyles";

export interface RestaurantPopupI extends BreakpointI {
  restaurantsData: Array<RestaurantPopupApiI>;
}

export const RestaurantPopupMobile = ({
  restaurantsData,
  breakpoint,
}: RestaurantPopupI) => {
  const activeRestaurants = restaurantsData.filter(
    (restaurant) => !!restaurant.is_visible
  );

  const { restaurantRef } = useContext(WroclawContext);

  return (
    <PopupWrapper ref={restaurantRef}>
      {activeRestaurants.length &&
        activeRestaurants.map(
          ({ image, name, address, website_address, gmaps_link }) => (
            <Popup key={name} breakpoint={breakpoint}>
              {!activeRestaurants.length ? (
                <ContentLoader isDarkMode />
              ) : (
                <div>
                  <UpperSection>
                    <RestaurantImage src={image} />
                    <InformationSection>
                      <RestaurantTitle>{name}</RestaurantTitle>
                      <RestaurantSubtitle>
                        Address and contact
                      </RestaurantSubtitle>
                      <SubtitleContent>
                        <p>{address}</p>
                        <Contact>
                          {website_address && (
                            <TextDataIcon
                              title="website"
                              href={website_address}
                              textSize={0}
                              Icon={IconLink}
                            />
                          )}
                        </Contact>
                      </SubtitleContent>
                    </InformationSection>
                  </UpperSection>
                  <DivideLine />
                  <LinkSection>
                    <TextDataIcon
                      title="How to get there"
                      href={gmaps_link}
                      textSize={0}
                      Icon={IconLink}
                    />
                  </LinkSection>
                </div>
              )}
            </Popup>
          )
        )}
    </PopupWrapper>
  );
};
