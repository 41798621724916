import styled, { css } from "styled-components";

export const ModalNavigationWrapper = styled.div`
  overflow: hidden;
`;

export const HorizontalLine = styled.div`
  height: 2px;
  background-color: var(--primary-colors-3-capgemini-light-gray);
  width: 1.563rem;
  border: 0;
  align-self: center;
  margin-right: 3.437rem;
`;

export const LinksWrapper = styled.div<{ isActive: boolean | undefined }>`
  display: flex;
  margin-bottom: 1.5rem;
  padding-right: 0.5rem;

  & p {
    text-decoration: none;
    color: var(--primary-colors-1-capgemini-blue);
    font-size: 1rem;
  }
  ${(props) => props.isActive && css`
    & p {
      color: var(--primary-colors-2-capgemini-vibrant-blue);
    }
    & div:first-of-type {
      width: 3.25rem;
      margin-right: 1.75rem;
      background-color: var(--primary-colors-2-capgemini-vibrant-blue);
    }`
  }}
`;

export const NavigationItem = styled.p`
  cursor: pointer;
`;