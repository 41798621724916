import React from "react";
import sanitizer from "util/sanitizer";
import { ListElementStyled, ListPoint } from "./ListElementStyles";

const ListElement = (props: any) => (
  <ListElementStyled>
    <ListPoint />
    <p
      // secured by sanitizer
      // eslint-disable-next-line
      dangerouslySetInnerHTML={{
        __html: sanitizer(props.content),
      }}
    />
  </ListElementStyled>
);

export default ListElement;
