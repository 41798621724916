import React from "react";
import { CalendarDayWrapper } from "./CalendarDayStyles";

interface CalendarDayI extends CalendarDayStylesI {
  date: string;
  weekday: string;
  receivedFunc: () => void;
}

export interface CalendarDayStylesI {
  isPast: boolean;
  isActive: boolean;
}

export const CalendarDay = ({
  date,
  weekday,
  isPast,
  isActive,
  receivedFunc,
  ...props
}: CalendarDayI) => (
  <CalendarDayWrapper
    isPast={isPast}
    isActive={isActive}
    onClick={() => receivedFunc()}
    {...props}
  >
    <p>{weekday}</p>
    <p>{date}</p>
  </CalendarDayWrapper>
);
