import React from "react";
import { ReactComponent as IconPlus } from "assets/icon-plus-normal.svg";
import { PulsatingCircle } from "components/animatedPoint/AnimatedPointStyle";
import Button from "components/button/Button";
import { InsideNumber, MapPointContainer } from "./MapPointStyled";

export interface MapPointI {
  label?: string;
  leftMargin: string;
  topMargin: string;
  buttonAtBottom?: boolean;
  hidePlusInButton?: boolean;
  pointColor?:
    | "white"
    | "blue"
    | "vibrantBlue"
    | "darkGrey"
    | "lightGrey"
    | "peacock"
    | "green"
    | "teal"
    | "sapphire"
    | "deepPurple";
  onClick?: () => void;
  size?: "small" | "medium" | "large" | "cluster";
  opacity?: string;
  insideNumber?: number;
}

export const MapPoint = ({
  leftMargin,
  topMargin,
  label,
  buttonAtBottom,
  size,
  hidePlusInButton,
  pointColor,
  opacity,
  insideNumber,
  ...props
}: MapPointI) => (
  <MapPointContainer
    leftMargin={leftMargin}
    topMargin={topMargin}
    buttonAtBottom={buttonAtBottom}
    pointColor={pointColor}
    {...props}
  >
    <PulsatingCircle size={size || "small"} pointColor={pointColor || "blue"} opacity={opacity} insideNumber={insideNumber}/>
    {label && <Button
      label={label}
      size="small"
      buttonType="primaryPurple"
      icon={!hidePlusInButton && <IconPlus />}
    />}
    <InsideNumber>{insideNumber}</InsideNumber>
  </MapPointContainer>
);
