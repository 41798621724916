import React, { useState } from "react";
import Xarrow from "react-xarrows";
import Draggable from "react-draggable";
import {
  StartingPoint,
  EndingPoint,
  AnimatedLinkingLineStyles,
} from "./AnimatedLinkingLineStyle";

export interface AnimatedLinkingLineI {
  startingTop?: string;
  startingLeft?: string;

  endingTop?: string;
  endingLeft?: string;
  startingPoint?: any;

  endingPoint?: any;
  color?: string;
  displayPopup?: boolean;
  endLineHeightAdjustment?: number;
  endLineWidthAdjustment?: number;
  isDragable?: boolean;
  isLinkingLineVisible?: boolean;
}

const AnimatedLinkingLine = ({
  endLineHeightAdjustment = 0,
  endLineWidthAdjustment = 0,
  startingTop = "0",
  startingLeft = "0",

  endingTop = "0",
  endingLeft = "0",
  startingPoint,

  endingPoint,
  color,
  displayPopup,
  isDragable = true,
  isLinkingLineVisible = false
}: AnimatedLinkingLineI) => {
  const [, setRender] = useState({});
  const forceRerender = () => setRender({});
  return (
    <AnimatedLinkingLineStyles isLinkingLineVisible={isLinkingLineVisible}>
      <StartingPoint
        id="startingElement"
        topMargin={startingTop}
        leftMargin={startingLeft}
      >
        {startingPoint}
      </StartingPoint>

      {isDragable ? (
        <Draggable
          onStop={forceRerender}
          onDrag={forceRerender}
          bounds="parent"
          disabled={displayPopup}
        >
          <EndingPoint
            id="endingElement"
            topMargin={endingTop}
            leftMargin={endingLeft}
          >
            {endingPoint}
          </EndingPoint>
        </Draggable>
      ) : (
        <EndingPoint
          id="endingElement"
          topMargin={endingTop}
          leftMargin={endingLeft}
        >
          {endingPoint}
        </EndingPoint>
      )}

      <Xarrow
        startAnchor={{
          position: "middle",
          offset: {},
        }}
        endAnchor={{
          position: "left",
          offset: {
            bottomness: endLineHeightAdjustment,
            rightness: endLineWidthAdjustment,
          },
        }}
        start="startingElement"
        end="endingElement"
        strokeWidth={2}
        showHead={false}
        curveness={0}
        color={color}
      />
    </AnimatedLinkingLineStyles>
  );
};

export default AnimatedLinkingLine;
