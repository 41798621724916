import React, { useEffect, useState } from "react";
import YouGain from "containers/youGain/YouGain";
import { BreakpointI } from "customHooks/useBreakpoint";
import { TileI } from "containers/youGain/tile/Tile";
import { ModalPage } from "./ModalPagesStyles";

interface YouGainContentI extends BreakpointI {
  youGainTiles: TileI[];
}

const YouGainContent = ({ youGainTiles, breakpoint }: YouGainContentI) => {
  const [tiles, setTiles] = useState([...youGainTiles]);
  const colors = [
    "var(--secondary-colors-cool-color-palette-9-capgemini-teal)",
    "var(--secondary-colors-cool-color-palette-8-capgemini-green)",
    "var(--secondary-colors-cool-color-palette-10-capgemini-peacock)",
    "var(--secondary-colors-cool-color-palette-11-capgemini-sapphire)",
  ];

  useEffect(() => {
    const tilesWithColor = youGainTiles.map((element, index) => ({
      ...element,
      color: colors[index],
    }));
    setTiles(tilesWithColor);
  }, []);

  return (
    <ModalPage id="YouGain" breakpoint={breakpoint}>
      <YouGain youGainTiles={tiles} />
    </ModalPage>
  );
};

export default YouGainContent;
