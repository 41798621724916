import React from "react";
import { AfterVideoContentStyled } from "./AfterVideoContentStyle";

export interface AfterVideoContentI {
  showContent: boolean;
  children?: React.ReactNode;
}
export const AfterVideoContent = ({
  children,
  showContent,
}: AfterVideoContentI) => (
  <AfterVideoContentStyled showContent={showContent}>
    {children}
  </AfterVideoContentStyled>
);
