import React from "react";
import sanitizer from "util/sanitizer";
import { TelephoneWrapper } from "containers/agendaEvent/AgendaEventStyles";
import { useImgUrl } from "customHooks/useImgUrl";
import {
  EmailWrapper,
  AgendaSpeakerWrapper,
  SpeakerInfoWrapper,
} from "./AgendaSpeakerStyles";

export interface SpeakerI {
  name: string;
  surname: string;
  email: string;
  phone: string;
  description: string;
  photo: string;
}
interface AgendaSpeakerI {
  data: SpeakerI;
}
export const AgendaSpeaker = ({ data }: AgendaSpeakerI) => (
  <AgendaSpeakerWrapper>
    <img src={useImgUrl(data.photo)} alt="speaker_photo" />
    <SpeakerInfoWrapper>
      <h2>
        {data.name} {data.surname}
      </h2>
      <div
        // secured by sanitizer
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{
          __html: sanitizer(data.description as string),
        }}
      />
      <div>
        <TelephoneWrapper>{data.phone}</TelephoneWrapper>
        <EmailWrapper href={`mailto:${data.email}`}>{data.email}</EmailWrapper>
      </div>
    </SpeakerInfoWrapper>
  </AgendaSpeakerWrapper>
);
