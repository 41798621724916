import useFetch from "customHooks/useFetch";
import React, {
  createContext,
  FC,
  RefObject,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { AppContext } from "App";
import Video from "containers/video/Video";
import { MobileBackground } from "containers/mobileBackground/MobileBackground";
import { PoznanPopupDesktop as OfficePopupDesktop } from "containers/poznanOfficePopup/desktop/PoznanPopup";
// import { PoznanPopupMobile as OfficePopupMobile } from "containers/poznanOfficePopup/mobile/PoznanPopup";
import { HeadingPageText } from "components/headingPageText/HeadingPageText";
import { MapPoint } from "components/mapPoint/MapPoint";
import Button from "components/button/Button";
import { isMobile } from "customHooks/useBreakpoint";
import { navigationSetup } from "util/navigationSetup/navigationSetup";
import handleResize from "util/handleResize";
import { officePopupBackupData } from "assets/backup/poznan/office/popupBackupData";
import {
  desktopPositionOffice,
  mobilePositionOffice,
} from "assets/consts/mapLocations/poznan/office";
import Popup from "components/popup/Popup";
import { PoznanPopupMobile } from "containers/poznanOfficePopup/mobile/PoznanPopup";
import { autoScroll } from "util/autoScroll/autoScroll";

export const PoznanContext = createContext({
  scrolledDivRef: React.createRef() as RefObject<HTMLDivElement>,
  setScrolledDivRef: Function as any,
  officeRef: React.createRef() as RefObject<HTMLDivElement>,
});

const Poznan: FC = () => {
  const [displayPopup, setDisplayPopup] = useState(false);
  const [desiredPointWidth, setDesiredPointWidth] = useState("0%");
  const [desiredPointHeight, setDesiredPointHeight] = useState("0%");
  const [displayPreloader, setDisplayPreloader] = useState(true);

  const poznanApiData: any = useFetch(
    "/poznan/screen",
    [],
    officePopupBackupData
  ).response;

  const togglePopup = () => {
    setDisplayPopup(!displayPopup);
  };

  const mapButtonClickHandler = () => {
    mainPane.current.scroll({ top: window.innerHeight * 2, behavior: "smooth" });
  };
  const calculatePosition = handleResize([
    {
      mobilePosition: mobilePositionOffice,
      desktopPosition: desktopPositionOffice,
      setDesiredPointWidth,
      setDesiredPointHeight,
    },
  ]);

  useEffect(() => {
    const tout = { func: setTimeout(() => {}, 0) };
    const wrap = () => {
      autoScroll(mainPane, tout);
    };
    const mp = mainPane.current;
    mp.addEventListener("scroll", wrap, true);

    return () => {
      mainPane.current.removeEventListener("scroll", wrap, true);
    };
  }, []);

  useEffect(() => {
    navigationSetup();
    calculatePosition();
    window.addEventListener("resize", calculatePosition);
    setTimeout(() => setDisplayPreloader(false), 2000);
    return () => {
      window.removeEventListener("resize", calculatePosition);
    };
  }, [desiredPointHeight, desiredPointWidth]);

  const { breakpoint, mainPane } = useContext(AppContext);

  const [scrolledDivRef, setScrolledDivRef] = useState(
    useRef<HTMLDivElement>(null)
  );
  const officeRef = useRef<HTMLDivElement>(null);

  const desktopView = (
    <Video>
      {displayPopup && (
        <OfficePopupDesktop
          poznanPopupData={poznanApiData}
          togglePopup={togglePopup}
          breakpoint={breakpoint}
        />
      )}
      {!displayPopup && (
        <MapPoint
          label="Our offices"
          leftMargin={desiredPointWidth}
          topMargin={desiredPointHeight}
          onClick={togglePopup}
          size="large"
        />
      )}

      <Button
        buttonType="secondaryLight"
        label="CLICK ON THE POINT"
        borderRadius={2}
        borderColor="var(--primary-colors-7-capgemini-white)"
        isClickPoint
        size="small"
      />
    </Video>
  );
  const mobileView = (
    <PoznanContext.Provider
      value={{ scrolledDivRef, setScrolledDivRef, officeRef }}
    >
      <MobileBackground isPreloaderVisible={displayPreloader}>
        <HeadingPageText text="POZNAŃ" />
        <MapPoint
          label="Our offices"
          leftMargin={desiredPointWidth}
          topMargin={desiredPointHeight}
          onClick={togglePopup}
          size="large"
        />
        <Button
          buttonType="secondaryLight"
          label="Read more about Our Office"
          isClickPoint
          size="small"
          isMapButton
          onClick={() => {
            mapButtonClickHandler();
          }}
        />
      </MobileBackground>
      <Popup
        breakpoint={breakpoint}
        displayHeaders
        isMultiPopup
        mainPane={mainPane}
        setScrolledDivRef={setScrolledDivRef}
      >
        <PoznanPopupMobile
          poznanPopupData={poznanApiData}
          togglePopup={togglePopup}
          breakpoint={breakpoint}
        />
      </Popup>
    </PoznanContext.Provider>
  );
  return isMobile(breakpoint) ? mobileView : desktopView;
};

export default Poznan;
