import React from "react";
import ButtonStyles from "./ButtonStyles";

export interface ButtonI {
  buttonType:
    | "primaryLight"
    | "primaryDark"
    | "secondaryLight"
    | "secondaryDark"
    | "primaryPurple"
    | "mapButton";
  disabled?: boolean;
  borderRadius?: number;
  borderColor?: string;
  size?: "small" | "medium" | "large";
  label?: string;
  onClick?: () => void;
  isClickPoint?: boolean;
  type?: "button" | "submit";
  icon?: React.ReactNode | null;
  isMapButton?: boolean;
}

export const Button = ({
  buttonType = "primaryLight",
  disabled = false,
  size = "medium",
  borderRadius = 0,
  label = "Button",
  borderColor,
  isClickPoint,
  type = "button",
  icon = null,
  isMapButton,
  ...props
}: ButtonI) => (
  <ButtonStyles
    type={type}
    disabled={disabled}
    buttonType={buttonType}
    size={size}
    borderRadius={borderRadius}
    borderColor={borderColor}
    isClickPoint={isClickPoint}
    icon={icon}
    isMapButton ={isMapButton}
    {...props}
  >
    {label}
    {icon}
  </ButtonStyles>
);

export default Button;
