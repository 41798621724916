import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Button from "components/button/Button";
import { CloseButton } from "components/closeButton/CloseButton";
import sanitizer from "util/sanitizer";
import { BreakpointI, isMobile } from "customHooks/useBreakpoint";
import useFetch from "customHooks/useFetch";
import { useImgUrl } from "customHooks/useImgUrl";
import welcomeBackup from "assets/backup/welcomeBackup";
import backupProfileImage from "assets/backup/grzegorz-wolodko.webp";
import PageHeader from "components/pageHeader/PageHeader";
import ContentLoader from "components/contentLoader/ContentLoader";
import { BackToTopButton } from "containers/backToTopButtton/BackToTopButton";
import {
  WelcomePopupContainer,
  WelcomePopupProfileImage,
  WelcomePopupCloseButtonContainer,
  WelcomePopupButtonContainer,
  WelcomePopupHeader,
  WelcomePopupText,
  WelcomeHeadSignature,
  WelcomeHeadPosition,
  WelcomeImgTextContainer,
  InnerContainer,
  WelcomeContainer,
} from "./WelcomePopupStyles";

export interface WelcomePopupI extends BreakpointI {
  receivedFunc?: () => void;
}

export interface WelcomePopupDataI {
  message_title: string;
  message_text: string;
  head_photo: string;
  head_signature: string;
  head_position: string;
}

const WelcomePopup = ({ breakpoint, receivedFunc }: WelcomePopupI) => {
  const ContainerRef = useRef<HTMLDivElement>(null);
  const [containerRefReady, setContainerRefReady] = useState(false);

  const { isDataLoading, response } = useFetch(
    "/welcome-screen",
    {},
    welcomeBackup
  );
  const {
    message_title: title,
    message_text: text,
    head_photo: headImg,
    head_signature: signature,
    head_position: position,
  } = response as WelcomePopupDataI;

  const closePopup = () => {
    if (receivedFunc) receivedFunc();
  };

  useEffect(() => {
    if (!isDataLoading) setContainerRefReady(true);
  }, [isDataLoading]);

  return (
    <WelcomePopupContainer isDataLoading={isDataLoading}>
      {isDataLoading ? (
        <ContentLoader isDarkMode />
      ) : (
        <>
          {isMobile(breakpoint) ? (
            <PageHeader breakpoint={breakpoint} isDarkMode />
          ) : (
            <WelcomePopupCloseButtonContainer>
              <CloseButton buttonType="onDark" onClick={closePopup} />
            </WelcomePopupCloseButtonContainer>
          )}
          <WelcomeContainer ref={ContainerRef}>
            <WelcomePopupHeader>{title}</WelcomePopupHeader>
            <WelcomePopupText
              // secured by sanitizer
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: sanitizer(text),
              }}
            />
            {isMobile(breakpoint) ? (
              <WelcomeImgTextContainer>
                <WelcomePopupProfileImage
                  src={
                    headImg === null ? backupProfileImage : useImgUrl(headImg)
                  }
                  alt="profile_image"
                />
                <InnerContainer>
                  <WelcomeHeadSignature>-{signature}</WelcomeHeadSignature>
                  <WelcomeHeadPosition>{position}</WelcomeHeadPosition>
                </InnerContainer>
                <div />
              </WelcomeImgTextContainer>
            ) : (
              <>
                <WelcomeHeadSignature>-{signature}</WelcomeHeadSignature>
                <WelcomeHeadPosition>{position}</WelcomeHeadPosition>
                <div />
                <WelcomePopupProfileImage
                  src={
                    headImg === null ? backupProfileImage : useImgUrl(headImg)
                  }
                  alt="profile_image"
                />
              </>
            )}
            <WelcomePopupButtonContainer>
              <Link to="/aboutssc">
                <Button
                  buttonType="secondaryDark"
                  label="About Software Center, Capgemini Poland"
                />
              </Link>
            </WelcomePopupButtonContainer>
          </WelcomeContainer>
          {containerRefReady && isMobile(breakpoint) && (
            <BackToTopButton isDarkMode elementRef={ContainerRef.current} />
          )}
        </>
      )}
    </WelcomePopupContainer>
  );
};

export default WelcomePopup;
