import React from "react";

import {
  ServiceShapeIcon,
  ServiceShapeContainer,
  ServiceShapeContent,
  ServiceShapeHeaderText,
  ServiceShapeText,
} from "./ServiceShapeStyles";

export interface ServiceShapeI extends ServiceShapeContainerI {
  serviceIcon: string;
  contentHeader: string;
  contentText: string;
  onClick?: () => void;
}

export interface ServiceShapeContainerI {
  isFocused: boolean;
}

export const ServiceShape = ({
  serviceIcon,
  isFocused,
  contentHeader,
  contentText,
  ...props
}: ServiceShapeI) => (
  <ServiceShapeContainer isFocused={isFocused} {...props}>
    <ServiceShapeIcon>
      <img src={serviceIcon} alt="service_icon" />
    </ServiceShapeIcon>
    <ServiceShapeContent>
      <ServiceShapeHeaderText>{contentHeader}</ServiceShapeHeaderText>
      <ServiceShapeText>{contentText}</ServiceShapeText>
    </ServiceShapeContent>
  </ServiceShapeContainer>
);
