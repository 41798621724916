import { updateObject } from "store/utility";

const initialState = {
  mute: false,
};

const muteReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "TOGGLE_MUTE":
      return updateObject(state, { mute: action.mute });
    default:
      return state;
  }
};
export default muteReducer;
