import { breakpoints } from "customHooks/useBreakpoint";
import styled from "styled-components";
import { AgendaIconI } from "./AgendaIcon";

export const AgendaIconWrapper = styled("div")<AgendaIconI>`
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  margin-bottom: 0;
  margin-top: 0;
  background-color: var(--primary-colors-1-capgemini-blue);
  ${(props) =>
    props.isDark &&
    `
    background-color: var(--primary-colors-6-capgemini-deep-purple);
  }
  `}
  ${(props) =>
    props.isActive &&
    `
    background-color: var(--primary-colors-2-capgemini-vibrant-blue);
  }
  `}
  
  @media (min-width: ${breakpoints.xs}px) {
    margin-bottom: 1.125rem;
    margin-top: 1.125rem;
  }
`;
