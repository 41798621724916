import React from "react";
import { HamburgerIconStyles, HamburgerLine } from "./HamburgerIconStyles";

export interface HamburgerIconI {
  background: "onLight" | "onDark";
  onClick?: () => void | undefined;
}

export const HamburgerIcon = ({
  background = "onLight",
  ...props
}: HamburgerIconI) => (
  <HamburgerIconStyles
    background={background}
    onClick={props.onClick}
    {...props}
  >
    <HamburgerLine />
    <HamburgerLine />
    <HamburgerLine />
  </HamburgerIconStyles>
);
