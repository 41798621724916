import { breakpoints } from "customHooks/useBreakpoint";
import styled from "styled-components";

export const AboutStatisticsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-around;
  width: 85vw;
  margin-left: auto;
  margin-right: auto;
  & div {
    text-align: center;
    margin-top: 1rem;
    flex: 1;
    width: 20%;

    @media (min-width: ${breakpoints.xs}px) and (max-width: ${breakpoints.lg}px) {
      width: 33%;
      flex: unset;
    }
    @media (max-width: ${breakpoints.xs}px) {
      width: 100%;
      flex: unset;
    }
`;
