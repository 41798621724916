import React, { useState } from "react";
import Draggable from "react-draggable";
import Xarrow from "react-xarrows";
import {
  DoubleAnimatedLinkingLineStyles,
  EndingPoint,
  StartingPoint,
} from "./DoubleAnimatedLinkingLineStyles";

export interface AnimatedLinkingLineI {
  startingTop?: string;
  startingLeft?: string;
  endingTop?: string;
  endingLeft?: string;
  startingPoint?: any;
  endingPoint?: any;
  color?: string;
  secondStartingTop?: string;
  secondStartingLeft?: string;
  displayPopup?: boolean;
  endLineHeightAdjustment?: number;
  endLineWidthAdjustment?: number;
  secondLineColor?: string;
  secondStartingPoint?: any;
  isDragable?: boolean;
}

const DoubleAnimatedLinkingLine = ({
  endLineHeightAdjustment = 0,
  endLineWidthAdjustment = 0,
  startingTop = "0",
  startingLeft = "0",
  secondStartingTop = "0",
  secondStartingLeft = "0",
  endingTop = "0",
  endingLeft = "0",
  startingPoint,
  secondStartingPoint,
  endingPoint,
  color,
  secondLineColor,
  displayPopup,
  isDragable = true,
}: AnimatedLinkingLineI) => {
  const [, setRender] = useState({});
  const forceRerender = () => setRender({});
  return (
    <DoubleAnimatedLinkingLineStyles>
      <StartingPoint
        id="startingElement"
        topMargin={startingTop}
        leftMargin={startingLeft}
      >
        {startingPoint}
      </StartingPoint>
      <StartingPoint
        id="secondStartingElement"
        topMargin={secondStartingTop}
        leftMargin={secondStartingLeft}
      >
        {secondStartingPoint}
      </StartingPoint>
      {isDragable ? (
        <Draggable
          onStop={forceRerender}
          onDrag={forceRerender}
          bounds="parent"
          disabled={displayPopup}
        >
          <EndingPoint
            id="endingElement"
            topMargin={endingTop}
            leftMargin={endingLeft}
          >
            {endingPoint}
          </EndingPoint>
        </Draggable>
      ) : (
        <EndingPoint
          id="endingElement"
          topMargin={endingTop}
          leftMargin={endingLeft}
        >
          {endingPoint}
        </EndingPoint>
      )}

      <Xarrow
        startAnchor={{
          position: "middle",
          offset: {},
        }}
        endAnchor={{
          position: "left",
          offset: {
            bottomness: endLineHeightAdjustment,
            rightness: endLineWidthAdjustment,
          },
        }}
        start="startingElement"
        end="endingElement"
        strokeWidth={2}
        showHead={false}
        curveness={0}
        color={color}
      />
      <Xarrow
        startAnchor={{
          position: "middle",
          offset: {},
        }}
        endAnchor={{
          position: "left",
          offset: {
            bottomness: endLineHeightAdjustment,
            rightness: endLineWidthAdjustment,
          },
        }}
        start="secondStartingElement"
        end="endingElement"
        strokeWidth={2}
        showHead={false}
        curveness={0}
        color={secondLineColor}
      />
    </DoubleAnimatedLinkingLineStyles>
  );
};

export default DoubleAnimatedLinkingLine;
