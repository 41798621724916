import applicationMap from "./applicationMap.svg";
import businessMap from "./businessMap.svg";
import cloudMap from "./cloudMap.svg";
import softwareMap from "./softwareMap.svg";
import polandEmptyMap from "./polandEmptyMap.svg";
import applicationIcon from "./icon-capgemini-services-application-services-normal.svg";
import businessIcon from "./icon-capgemini-services-business-services-normal.svg";
import cloudIcon from "./icon-capgemini-services-cloud-infrastructure-services-normal.svg";
import softwareIcon from "./icon-capgemini-services-software-solutions-center-normal.svg";

export const serviceAssetIcons = {
  softwareIcon,
  businessIcon,
  cloudIcon,
  applicationIcon,
};

export const serviceAssetMaps = [
  polandEmptyMap,
  softwareMap,
  businessMap,
  cloudMap,
  applicationMap,
];
