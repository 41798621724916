import logo1 from "./01.webp";
import logo2 from "./02.webp";
import logo3 from "./03.webp";
import logo4 from "./04.webp";
import logo5 from "./05.webp";
import logo6 from "./06.webp";
import logo7 from "./07.webp";
import logo8 from "./08.webp";
import logo9 from "./09.webp";
import logo10 from "./10.webp";
import logo11 from "./11.webp";
import logo12 from "./12.webp";
import logo13 from "./13.webp";
import logo14 from "./14.webp";
import logo15 from "./15.webp";
import logo16 from "./16.webp";
import logo17 from "./17.webp";
import logo18 from "./18.webp";
import logo19 from "./19.webp";
import logo20 from "./20.webp";
import logo21 from "./21.webp";
import logo22 from "./22.webp";
import logo23 from "./23.webp";
import logo24 from "./24.webp";
import logo25 from "./25.webp";
import logo26 from "./26.webp";
import logo27 from "./27.webp";
import logo28 from "./28.webp";

export const ourCompetencesLogosBackup = {
  logos: [
    { logo: logo1 },
    { logo: logo2 },
    { logo: logo3 },
    { logo: logo4 },
    { logo: logo5 },
    { logo: logo6 },
    { logo: logo7 },
    { logo: logo8 },
    { logo: logo9 },
    { logo: logo10 },
    { logo: logo11 },
    { logo: logo12 },
    { logo: logo13 },
    { logo: logo14 },
    { logo: logo15 },
    { logo: logo16 },
    { logo: logo17 },
    { logo: logo18 },
    { logo: logo19 },
    { logo: logo20 },
    { logo: logo21 },
    { logo: logo22 },
    { logo: logo23 },
    { logo: logo24 },
    { logo: logo25 },
    { logo: logo26 },
    { logo: logo27 },
    { logo: logo28 },
  ],
};
