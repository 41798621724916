import React from "react";
import { isTheTimePast } from "util/timeChecks/timeChecks";
import { TimelineWrapper, Line } from "./TimelineStyles";

interface TimelineI {
  eventStart: string;
  eventEnd: string;
  period: string;
}

export const Timeline = ({ eventStart, eventEnd, period }: TimelineI) => {
  const isPast = isTheTimePast(period, eventStart);
  return (
    <>
      <Line isPast={isPast} />
      <TimelineWrapper isPast={isPast}>
        <div>
          <p>{eventStart}</p>
          <p>{eventEnd}</p>
        </div>
      </TimelineWrapper>
    </>
  );
};
