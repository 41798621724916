const welcomeBackup = {
  id: 1,
  message_title: "Welcome John!",
  message_text:
    "<p style='line-height: 1.6'>On behalf of Capgemini, I would like to welcome you in Poland and the Software Center, Capgemini Poland. We have prepared this short guide to help you get acquainted with possibilities our Center provides and benefits you will gain from collaboration with us. \n" +
    "Poland is home to a rich culture, historical cities, and a thriving economy. It is ranked #3rd in Europe for foreign investment. Many global businesses have established their operations in Poland to leverage its highly educated and enthusiastic workforce. \n" +
    "There are over 280 000 IT professionals in Poland and over 15 000 ICT graduates enter the labour market every year. \n" +
    "For more than 20 years, the country has experienced steady economic growth, and its outlook is positive and stable. \n" +
    "We will be thrilled if you consider visiting us and experience these exciting and changing times in Poland by yourself. \n" +
    "</p>\r\n\r\n<p>Sincerely</p>",
  head_signature: "Grzegorz Wołodko",
  head_position: "Head of Software Center, Capgemini Poland, Head of Practices",
  head_photo: null,
};

export default welcomeBackup;
