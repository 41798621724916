import React from "react";
import { GetTheFutureLinkWrapper, ModalPage } from "./ModalPagesStyles";

interface GetTheFutureLinkI {
  text: string;
  linkto: string;
}

const GetTheFutureLink = ({ text, linkto }: GetTheFutureLinkI) => (
  <ModalPage id="GetTheFuture" breakpoint="">
    <GetTheFutureLinkWrapper>
      {text}
      <a href={"https://".concat(linkto)} target="_blank" rel="noreferrer">
        {linkto}
      </a>
    </GetTheFutureLinkWrapper>
  </ModalPage>
);

export default GetTheFutureLink;
