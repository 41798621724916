import React from "react";
import { CompanyLogo } from "components/companyLogo/CompanyLogo";
import { BreakpointI } from "customHooks/useBreakpoint";
import { AgendaTopTextWrapper } from "./AgendaTopTextStyles";

interface AgendaTopTextI extends BreakpointI {
  logo: string;
  companyName: string;
  period: string;
}

export const AgendaTopText = ({
  logo = "",
  companyName,
  period,
  breakpoint,
}: AgendaTopTextI) => (
  <AgendaTopTextWrapper>
    <h1>Your Visit</h1>
    <p>{companyName}</p>
    <p>{period}</p>
    {breakpoint === "xs" && <CompanyLogo logo={logo} />}
  </AgendaTopTextWrapper>
);
