import React from "react";
import Wroclaw from "assets/business-garden-wroclaw.webp";
import Poznan from "assets/business-garden-poznan.webp";
import { ImagesContainer, CitiesText } from "./CitiesSectionStyles";
import Image from "./image/Image";

const CitiesSection = () => (
  <>
    <CitiesText>Which city do you choose?</CitiesText>
    <ImagesContainer>
      <Image
        src={Wroclaw}
        city="Wrocław"
        place="Business Garden"
        destination="/wroclaw"
        active
      />
      <Image
        src={Poznan}
        city="Poznań"
        place="Business Garden"
        destination="/poznan"
        active
      />
    </ImagesContainer>
  </>
);

export default CitiesSection;
