import React, { useEffect } from "react";
 
export const baseUrl = 'https://joinus.capgeminisoftware.pl';
const token = process.env.REACT_APP_AUTH_KEY;
 
export interface apiErrorI {
  message: string;
  stack: string;
}
 
const useFetch = (
  endPoint: string,
  options?: object,
  backup?: any,
  returnJsonResponse = true
) => {
  const combinedOptions = {
    ...options,
    headers: {
      Authorization: token || "",
      "Content-Type": "application/json",
    },
  };
  const url = `${baseUrl}${endPoint}`;
  const [response, setResponse] = React.useState({});
  const [isDataLoading, setIsDataLoading] = React.useState(true);
  const [error, setError] = React.useState<apiErrorI>({
    message: "initErrorMessage",
    stack: "no error",
  });
  const fetchData = async () => {
    try {
      await fetch(url, combinedOptions)
        .then((res) => {
          if (res.ok) {
            setError({ message: "", stack: "no error" });
            return returnJsonResponse ? res.json() : res;
          }
          throw new Error(`${res.status}: ${res.statusText}`);
        })
        .then((res) => {
          setResponse(res);
          setIsDataLoading(false);
        })
        .finally(() => setIsDataLoading(false));
    } catch (err) {
      console.error(`Error during fetching ${url} : ${err}`);
      setError(err as apiErrorI);
      if (backup) {
        setResponse(backup);
      }
      setIsDataLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [endPoint]);
 
  return { response, isDataLoading, error, fetchData };
};
 
export const useSimpleFetch = (endPoint: string, options: object) => {
  const combinedOptions = {
    ...options,
    headers: {
      Authorization: token || "",
      "Content-Type": "application/json",
    },
  };
  const url = `${baseUrl}${endPoint}`;

  return fetch(url, combinedOptions);
};
 
export default useFetch;
