import { breakpoints } from "customHooks/useBreakpoint";
import styled from "styled-components";

export const Number = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-left: 0.25rem;
  border-right: 0;
  border-style: solid;
  border-image: linear-gradient(
      to bottom,
      var(--primary-colors-2-capgemini-vibrant-blue),
      var(--primary-colors-1-capgemini-blue)
    )
    1 100%;
  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;
    width: 100%;
    margin-top: 1rem;
  }
`;

export const NumberTitle = styled.h3`
  color: var(--primary-colors-6-capgemini-deep-purple);
  font-size: 3.75rem;
  margin: 0;

  @media (min-width: ${breakpoints.xs}px) and (max-width: ${breakpoints.md}px) {
    font-size: 2.75rem;
  }
`;

export const NumberValue = styled.p`
  font-size: 0.875rem;
  margin-top: 0.3rem;
  color: var(--primary-colors-2-capgemini-vibrant-blue);
`;
