import React from "react";
import sanitizer from "util/sanitizer";
import {
  CarouselBoxStyled,
  CarouselBoxTitle,
  CarouselBoxContent,
  ParagraphBox,
} from "./CarouselBoxStyles";
import ListElement from "./listElement/ListeElement";

export interface CarouselBoxI {
  content?: any;
  title?: string;
  height?: "string";
}

const CarouselBox = (props: CarouselBoxI) => {
  const listContent =
    props.content.split(/<ul>|<\/ul>/)[1] &&
    props.content.split(/<ul>|<\/ul>/)[1].split(/<li>|<\/li>/);
  const paragraphContent = !listContent && props.content.split(/<p>|<\/p>/);

  return (
    <CarouselBoxStyled height={props.height}>
      <CarouselBoxTitle>{props.title}</CarouselBoxTitle>
      <CarouselBoxContent>
        {listContent &&
          listContent.map(
            (element: any) =>
              !element.includes("\r\n") && (
                <ListElement content={element} key={element.title} />
              )
          )}
        {paragraphContent && (
          <ParagraphBox
            dangerouslySetInnerHTML={{
              __html: sanitizer(paragraphContent[1]),
            }}
          />
        )}
      </CarouselBoxContent>
    </CarouselBoxStyled>
  );
};

export default CarouselBox;
