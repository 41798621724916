import styled from "styled-components";
import { StyledTextDataIconI } from "./TextDataIcon";

export const StyledTextDataIcon = styled.a<StyledTextDataIconI>`
  text-decoration: none;
  color: var(--primary-colors-7-capgemini-white);
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin: 0px;
  }

  margin: 1rem 1rem;
  padding: 0;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.75rem;
  }

  ${({ href }) =>
    href &&
    `
    line-height: 1.6;
    :hover {
      text-decoration: underline;
    }
  `}

  ${({ iconPosition }) =>
    iconPosition === "left" &&
    `
        flex-direction: row-reverse;
        svg {
          margin-left: 0;
        }
        p {
          margin-left: 0.75rem;
        }
    `}
`;
