import React from "react";
import { ReactComponent as IconPlus } from "assets/icon-close.svg"
import { CloseButtonStyled } from "./CloseButtonStyles";

export interface CloseButtonI {
  buttonType: "onLight" | "onDark";
  onClick: any;
}
export const CloseButton = ({ ...props }: any) => (
  <CloseButtonStyled {...props}>
    <IconPlus />
  </CloseButtonStyled>
);

export default CloseButton;
