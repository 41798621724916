import styled from "styled-components";
import { HamburgerIconI } from "./HamburgerIcon";

export const HamburgerLine = styled.div`
  width: 100%;
  height: 2px;
  border-radius: 100px;

  margin: 1.5px 0;
`;

export const HamburgerIconStyles = styled("button")<HamburgerIconI>`
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  width: 48px;
  height: 48px;
  padding: 15px 12px;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.background === "onLight" &&
    `
    & ${HamburgerLine} {
      background-color: var(--primary-colors-1-capgemini-blue);
    }
    &:hover ${HamburgerLine} {
      background-color: var(--primary-colors-2-capgemini-vibrant-blue);
    }

  `}

  ${(props) =>
    props.background === "onDark" &&
    `
    & ${HamburgerLine} {
      background-color: var(--primary-colors-3-capgemini-light-gray);
    }
    &:hover ${HamburgerLine} {
      background-color: var(--primary-colors-7-capgemini-white);
    }

  `}
`;
