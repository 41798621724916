import styled from "styled-components";
import { BackToTopButtonI } from "./BackToTopButton";

export const BackToTopButtonStyled = styled("div")<BackToTopButtonI>`
  width: 100%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  visibility: hidden;
  opacity: 0;
  transition: 0.5s;
  position: sticky;
  z-index: 100;

  ${(props) =>
    props.isDarkMode &&
    `
    background: var(--primary-colors-1-capgemini-blue);
  `}

  ${(props) =>
    props.isVisible &&
    `
      visibility: visible;
      opacity: 1;
  `}
 
 ${({ type }) =>
    type === "modal" &&
    `position: fixed;
     bottom: 0;`}

${({ type }) =>
    type === "popup" &&
    `position: sticky;
     bottom: 0;`}
`;
