import { AppContext } from "App";
import { BreakpointI, isMobile } from "customHooks/useBreakpoint";
import React, { Dispatch, SetStateAction, useContext } from "react";
import TextDataIcon from "components/textDataIcon/TextDataIcon";
import ContentLoader from "components/contentLoader/ContentLoader";
import Popup from "components/popup/Popup";
import { ReactComponent as IconLink } from "assets/icon-link.svg";
import { ReactComponent as IconPhone } from "assets/icon-mobile-phone.svg";
import { WroclawContext } from "pages/Wroclaw/Wroclaw";
import {
  AirportImage,
  AirportSubtitle,
  AirportTitle,
  Contact,
  InformationSection,
  LinksSection,
  TaxiDataWrapper,
  UpperSection,
  DivideLine,
  SubtitleContent,
  PopupWrapper,
} from "./AirportPopupStyles";

export interface TaxiI {
  name: string;
  phone_number: string;
}

export interface AirportPopupApi {
  name: string;
  address: string;
  phone_number: string;
  website_address: string;
  image: string;
  taxis: Array<TaxiI>;
}
export interface AirportPopupI extends BreakpointI {
  setPopupState?: Dispatch<SetStateAction<boolean>>;
  popupData: AirportPopupApi;
}

const AirportPopup = ({ setPopupState, popupData }: AirportPopupI) => {
  const {
    name,
    address,
    phone_number,
    website_address,
    image,
    taxis,
  } = popupData;

  const { breakpoint } = useContext(AppContext);
  const { airportRef} = useContext(WroclawContext);

  const PopupDefault = (
    <PopupWrapper >
      <Popup breakpoint={breakpoint} setPopupState={setPopupState}>
        {!Object.keys(popupData).length ? (
          <>
            <ContentLoader isDarkMode />
          </>
        ) : (
          <>
            <UpperSection>
              <AirportImage src={image} />
              <InformationSection>
                <AirportTitle>{name}</AirportTitle>
                <AirportSubtitle>Address and contact</AirportSubtitle>
                <SubtitleContent>
                  <p>{address}</p>
                  <Contact>
                    <TextDataIcon
                      title={phone_number}
                      textSize={0}
                      Icon={IconPhone}
                      iconPosition="left"
                    />
                    {website_address && (
                      <TextDataIcon
                        title="website"
                        href={website_address}
                        textSize={0}
                        Icon={IconLink}
                      />
                    )}
                  </Contact>
                </SubtitleContent>
                <AirportSubtitle>Taxi Corporations</AirportSubtitle>
                <SubtitleContent>
                  {taxis.map(
                    ({ name: taxi_name, phone_number: taxi_phone }: TaxiI) => (
                      <TaxiDataWrapper key={taxi_name}>
                        <p>{taxi_name}</p>
                        <TextDataIcon
                          title={taxi_phone}
                          Icon={IconPhone}
                          iconPosition="left"
                        />
                      </TaxiDataWrapper>
                    )
                  )}
                </SubtitleContent>
              </InformationSection>
            </UpperSection>
            <DivideLine />
            <LinksSection>
              <TextDataIcon
                title="How to get to the airport"
                href="https://goo.gl/maps/76GrKgrGhkkSoTKt6"
                Icon={IconLink}
              />
              <TextDataIcon
                title="Public transport in Wroclaw"
                href="https://visitwroclaw.eu/en/information"
                Icon={IconLink}
              />
            </LinksSection>
          </>
        )}
      </Popup>
    </PopupWrapper>
  );

  const PopupMobile = (
    <PopupWrapper ref={airportRef}>
      <Popup breakpoint={breakpoint} setPopupState={setPopupState}>
        {!Object.keys(popupData).length ? (
          <ContentLoader isDarkMode />
        ) : (
          <>
            <UpperSection>
              <AirportImage src={image} />
            </UpperSection>
            <InformationSection>
              <AirportTitle>{name}</AirportTitle>
              <AirportSubtitle>Address and contact</AirportSubtitle>
              <SubtitleContent>
                <p>{address}</p>
                <Contact>
                  <TextDataIcon
                    title={phone_number}
                    Icon={IconPhone}
                    iconPosition="left"
                  />
                  {website_address && (
                    <TextDataIcon
                      title="website"
                      href={website_address}
                      textSize={0}
                      Icon={IconLink}
                    />
                  )}
                </Contact>
              </SubtitleContent>
              <AirportSubtitle>Taxi Corporations</AirportSubtitle>
              <SubtitleContent>
                {taxis.map(
                  ({ name: taxi_name, phone_number: taxi_phone }: TaxiI) => (
                    <TaxiDataWrapper key={taxi_name}>
                      <p>{taxi_name}</p>
                      <TextDataIcon
                        title={taxi_phone}
                        Icon={IconPhone}
                        iconPosition="left"
                      />
                    </TaxiDataWrapper>
                  )
                )}
              </SubtitleContent>
              <DivideLine />
              <LinksSection>
                <TextDataIcon
                  title="How to get to the airport"
                  href="https://goo.gl/maps/76GrKgrGhkkSoTKt6"
                  textSize={0}
                  Icon={IconLink}
                />
                <TextDataIcon
                  title="Public transport in Wroclaw"
                  href="https://visitwroclaw.eu/en/information"
                  textSize={0}
                  Icon={IconLink}
                />
              </LinksSection>
            </InformationSection>
          </>
        )}
      </Popup>
    </PopupWrapper>
  );

  return !isMobile(breakpoint) ? PopupDefault : PopupMobile;
};

export default AirportPopup;
