import React from "react";
import { CompanyLogoWrapper } from "./CompanyLogoStyles";

interface CompanyLogoI {
  logo: string;
}

export const CompanyLogo = ({ logo = "" }: CompanyLogoI) => (
  <CompanyLogoWrapper>
    <p>for</p>
    <img src={logo} alt="company_logo" />
  </CompanyLogoWrapper>
);
