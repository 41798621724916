import React, { FC, useRef } from "react";
import { Router } from "react-router-dom";
import MainPane from "components/mainPane/MainPane";
import { Routes } from "routes/Routes";
import { history } from "util/history";
import { useBreakpoint } from "customHooks/useBreakpoint";

export interface AppContextI {
  breakpoint: string;
  mainPane: any;
}
export const AppContext = React.createContext<AppContextI>({
  breakpoint: "lg",
  mainPane: <div />
});

export const App: FC = () => {
  const breakpoint: string = useBreakpoint();
  const mainPaneRef = useRef<HTMLElement>();
  return (
    <AppContext.Provider value={{ breakpoint, mainPane: mainPaneRef }}>
      <Router history={history}>
        <MainPane refDrill={mainPaneRef}>
          <Routes />
        </MainPane>
      </Router>
    </AppContext.Provider>
  );
};
