import React, { useState, useEffect, useContext, useRef } from "react";
import { AgendaContent } from "containers/agendaContent/AgendaContent";
import {
  AgendaTokenPopup,
  AgendaI,
} from "containers/agendaTokenPopup/AgendaTokenPopup";
import { BackToTopButton } from "containers/backToTopButtton/BackToTopButton";
import { navigationSetup } from "util/navigationSetup/navigationSetup";
import { AppContext } from "App";
import { useParams } from "react-router-dom";
import { AgendaContainer, AgendaTokenPopupStyled } from "./AgendaStyles";

const Agenda = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerRefReady, setContainerRefReady] = useState(false);
  const [agendaData, setAgendaData] = useState<AgendaI>({} as AgendaI);

  const { token } = useParams() as any;

  useEffect(() => {
    navigationSetup();
    setContainerRefReady(true);
  }, []);

  const { breakpoint } = useContext(AppContext);
  return (
    <>
      <AgendaContainer ref={containerRef} isVisible={!agendaData.company_logo}>
        <AgendaTokenPopupStyled isVisible={!agendaData.company_logo}>
          <AgendaTokenPopup
            token={token}
            setAgendaData={setAgendaData}
            isVisible={!agendaData.company_logo}
          />
        </AgendaTokenPopupStyled>
        {agendaData.company_logo && (
          <AgendaContent breakpoint={breakpoint} agendaData={agendaData} />
        )}
        {containerRefReady && containerRef && (
          <BackToTopButton
            elementRef={containerRef.current}
            isDarkMode={false}
            type="popup"
          />
        )}
      </AgendaContainer>
    </>
  );
};

export default Agenda;
