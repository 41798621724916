import styled from "styled-components";

import { AfterVideoContentI } from "./AfterVideoContent";

export const AfterVideoContentStyled = styled.div<AfterVideoContentI>`
  height: 100%;
  visibility: hidden;
  ${(props) =>
    props.showContent &&
    `
      visibility: visible;
  `}
`;
