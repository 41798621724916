import { breakpoints } from "customHooks/useBreakpoint";
import styled from "styled-components";

export const MainPaneStyles = styled.div`
  display: grid;
  width: 100vw;
  height: 100vh;
  height: -webkit-fill-available;
  grid-template-columns: 15vw 75vw 10vw;
  grid-template-rows: 15vh 75vh 10vh;
  background-color: var(--tertiary-colors-21-capgemini-dark-white);
  position: relative;
  @media (max-width: ${breakpoints.md}px) {
    overflow-y: overlay;
    overflow-x: hidden;
    grid-template-rows: 10vh 80vh 10vh;

    ::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
  } ;
`;

export const MainPaneChild = styled.div`
  background-color: var(--tertiary-colors-22-capgemini-light-white);
  grid-row: 1 / 4;
  grid-column: 1 / 4;
  position: relative;
`;
