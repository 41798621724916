import styled from "styled-components";

export const ListElementStyled = styled.li`
  font-size: 0.9rem;
  color: var(--primary-colors-5-capgemini-dark-grey);
  display: flex;
  align-items: center;
  text-align: left;
  margin-bottom: 0.5rem;

  p {
    margin: 0;
  }
`;

export const ListPoint = styled.div`
  min-height: 6px;
  min-width: 6px;
  border-radius: 5rem;
  margin-right: 1rem;
  background-color: var(--primary-colors-2-capgemini-vibrant-blue);
`;
