import React, { useRef } from "react";
import Modal, { ModalI } from "components/modal/Modal";
import ContentLoader from "components/contentLoader/ContentLoader";
import { MobileModalNavigation } from "components/mobileModalNavigation/MobileModalNavigation";
import WhatMakesUsStandOutContent from "modalPages/WhatMakesUsStandOutContent";
import OurOfficesContent from "modalPages/OurOfficesContent";
import { WhySSCModalFetchData } from "containers/whySSCModal/WhySSCModalFetchData";
import { MobileTitleText } from "./WhySSCMobileModalStyles";

export const WhySSCMobileModal = ({
  setModalState,
  breakpoint,
  navigationElements,
}: ModalI) => {
  const whatMakesUsStandOutRef = useRef<HTMLDivElement>(null);
  const ourOfficesRef = useRef<HTMLDivElement>(null);
  const sectionRefs = [whatMakesUsStandOutRef, ourOfficesRef];
  const modalRef = useRef<HTMLDivElement>(null);

  const {
    dataLoaded,
    whatMakesUsStandOutData,
    ourOfficesData,
    carouselData,
  } = WhySSCModalFetchData();

  const scrollToSection = (refNumber: number) => {
    const selectedRef = sectionRefs[refNumber];
    modalRef.current!.scroll({
      behavior: "smooth",
      top: selectedRef.current?.offsetTop! - 100,
    });
  };

  return (
    <Modal
      modalRef={modalRef}
      breakpoint={breakpoint}
      setModalState={setModalState}
      navigationBar={
        <MobileModalNavigation
          navigateToSection={scrollToSection}
          navigationElements={navigationElements}
        />
      }
    >
      <MobileTitleText>WHY SOFTWARE CENTER</MobileTitleText>

      {!dataLoaded ? (
        <ContentLoader />
      ) : (
        <>
          <section ref={whatMakesUsStandOutRef}>
            <WhatMakesUsStandOutContent
              breakpoint={breakpoint}
              whatMakesUsStandOut={whatMakesUsStandOutData}
            />
          </section>
          <section ref={ourOfficesRef}>
            <OurOfficesContent
              ourOfficesData={ourOfficesData}
              breakpoint={breakpoint}
              carouselDataYourBenefits={
                carouselData?.length &&
                carouselData.filter(
                  (element: any) => element.carousel_type === "Our Offices"
                )
              }
              carouselDataWroclaw={
                carouselData?.length &&
                carouselData.filter(
                  (element: any) => element.carousel_type === "Wrocław"
                )
              }
              carouselDataPoznan={
                carouselData?.length &&
                carouselData.filter(
                  (element: any) => element.carousel_type === "Poznań"
                )
              }
            />
          </section>
        </>
      )}
    </Modal>
  );
};
