import React, { Dispatch, SetStateAction } from "react";
import { CloseButton } from "components/closeButton/CloseButton";
import { ModalNavigationI } from "components/modalNavigation/ModalNavigation";
import { BackToTopButton } from "containers/backToTopButtton/BackToTopButton";
import PageHeader from "components/pageHeader/PageHeader";
import { BreakpointI, isMobile } from "customHooks/useBreakpoint";
import { iOS } from "util/detectIphone/detectIphone";
import {
  ModalStyled,
  ModalContainer,
  CloseButtonContainer,
  ModalChildrenWrapper,
  HeadWrapper,
} from "./ModalStyles";

export interface ModalI extends BreakpointI {
  children?: React.ReactNode;
  setModalState?: Dispatch<SetStateAction<boolean>>;
  navigationBar?: React.ReactNode;
  navigationElements?: ModalNavigationI[];
  modalRef?: any;
  isiOS?: boolean;
}

const Modal = ({
  children,
  setModalState,
  navigationBar,
  breakpoint,
  modalRef,
}: ModalI) =>  (
    <ModalStyled
      breakpoint={breakpoint}
      onClick={() => setModalState && setModalState(false)}
      isiOS={iOS()}
    >
      <ModalContainer
        breakpoint={breakpoint}
        onClick={(event) => event.stopPropagation()}
      >
        {isMobile(breakpoint) ? (
          <HeadWrapper>
            <PageHeader breakpoint={breakpoint} isDarkMode={false} />
            {navigationBar}
          </HeadWrapper>
        ) : (
          <CloseButtonContainer>
            <CloseButton
              buttonType="onLight"
              onClick={() => setModalState && setModalState(false)}
            />
          </CloseButtonContainer>
        )}
        <ModalChildrenWrapper ref={modalRef} >
          {children}
        </ModalChildrenWrapper>

        {isMobile(breakpoint) && (
          <BackToTopButton isDarkMode={false} elementRef={modalRef.current} type="modal"/>
        )}
      </ModalContainer>
    </ModalStyled>
  );

export default Modal;
