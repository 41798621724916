import React from "react";
import sanitizer from "util/sanitizer";
import {
  TextContainer,
  AboutCapgeminiFirstHeading,
  AboutCapgeminiSecondHeading,
  ContentTextContainer,
  SubtitleText,
} from "./AboutCapgeminiTopTextStyles";

export interface AboutCapgeminiTopTextI {
  contentText?: string;
  subtitleText?: string;
}

export const AboutCapgeminiTopText = ({
  contentText = "",
  subtitleText = "",
}: AboutCapgeminiTopTextI) => (
  <TextContainer>
    <AboutCapgeminiFirstHeading>Capgemini Poland</AboutCapgeminiFirstHeading>
    <AboutCapgeminiSecondHeading>About Capgemini</AboutCapgeminiSecondHeading>
    <ContentTextContainer
      // secured by sanitizer
      // eslint-disable-next-line
      dangerouslySetInnerHTML={{
        __html: sanitizer(contentText),
      }}
    />
    <SubtitleText>{subtitleText}</SubtitleText>
  </TextContainer>
);
