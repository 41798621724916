import styled from "styled-components";

export const HeadingPageTextStyled = styled.h1`
  text-align: center;
  color: var(--primary-colors-6-capgemini-deep-purple);
  font-size: 2.25rem;
  z-index: 4;
  position: absolute;
  top: 6%;
  left: 50%;
  transform: translate(-50%, 0);
  width: 80%;
`;
