import React from "react";
import { BreakpointI } from "customHooks/useBreakpoint";
import {
  ImageWithDescriptionWrapper,
  Image,
  Description
} from "./ImageWithDesctriptionStyle";

export interface ImageWithDescriptionI extends BreakpointI{
  src: string,
  description?: string,
  alt: string,
}

export const ImageWithDescription = ({ src, description, alt, breakpoint }: ImageWithDescriptionI) => (
  <ImageWithDescriptionWrapper>
    <Image src={src} alt={alt} />
    { description && <Description breakpoint={breakpoint}>{description}</Description> }
  </ImageWithDescriptionWrapper>
);
