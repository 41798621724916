import React from "react";
import {
  ImagePhoto,
  ImageContainer,
  ImageContent,
  CompanyName,
  CityName,
  PlaceName,
  ImageButton,
} from "./ImageStyles";

export interface ImageI {
  src?: any;
  city?: string;
  place?: string;
  destination?: string;
  active?: boolean;
}

const Image = ({ src, city, place, destination = "/", active }: ImageI) => (
  <ImageContainer to={destination}>
    <ImagePhoto src={src} />
    <ImageContent active={active}>
      <CompanyName active={active}>Software Center, Capgemini Poland</CompanyName>
      <CityName active={active}>{city}</CityName>
      <PlaceName active={active}>{place}</PlaceName>
      {active && <ImageButton>Go to {city}</ImageButton>}
    </ImageContent>
  </ImageContainer>
);

export default Image;
