import styled from "styled-components";
import { BreakpointI, breakpoints, isMobile } from "customHooks/useBreakpoint";

export const Minus = styled.div`
  border-radius: 999px;
  border: .15rem solid var(--primary-colors-1-capgemini-blue);
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem; 
  color: var(--primary-colors-1-capgemini-blue);
  text-align: center;
  transition: background 0.2s linear;
  transition: color 0.2s linear;
  font-size: 0.9375rem;
  line-height: 1.5rem;
  transition: 0.5s ease-in-out;
  &:focus,
  &:hover,
  &:active {
    color: var(--primary-colors-2-capgemini-vibrant-blue);
    border: .15rem solid var(--primary-colors-2-capgemini-vibrant-blue);
  }
}
`;

export const Plus = styled.div`
  border-radius: 999px;
  border: .15rem solid var(--primary-colors-1-capgemini-blue);
  width: 1.5rem;
  height: 1.5rem;
  padding: 0px;
  cursor: pointer;
  color: var(--primary-colors-1-capgemini-blue);
  text-align: center;
  transition: background 0.2s linear;
  transition: color 0.2s linear;
  font: 1.5rem Arial, sans-serif;
  line-height: 1.5rem;
  transition: all 0.5s ease-in-out;
  &:focus,
  &:hover,
  &:active {
    color: var(--primary-colors-2-capgemini-vibrant-blue);
    border: .15rem solid var(--primary-colors-2-capgemini-vibrant-blue);
  }
}
`;

export const Dot = styled.span`
  position: absolute;
  border-radius: 50%;
  background-color: var(--primary-colors-3-capgemini-light-gray);
  height: 0.625rem;
  width: 0.625rem;
  z-index: 11;
  margin-left: 4px;
  &:focus,
  &:active {
    background-color: var(--primary-colors-2-capgemini-vibrant-blue);
  }
`;
export const Line = styled.span`
  position: absolute;
  border-left: 2px solid var(--primary-colors-3-capgemini-light-gray);
  height: 100%;
  bottom: 50%;
  left: 0.5rem;
`;
export const SidebarItemShape = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
`;
export const SidebarItemContent = styled.div`
  margin-left: 1rem;
  & p {
    margin: 0;
    color: var(--primary-colors-1-capgemini-blue);
    font-weight: 500;
    width: 10rem;
  }
  & p:hover,
  p:focus,
  p:active {
    color: var(--primary-colors-2-capgemini-vibrant-blue);
  }
`;

export const SidebarItemContainer = styled.div`
  &:hover ${SidebarItemShape} ${Dot} {
    height: 0.875rem;
    width: 0.875rem;
    left: -0.2rem;
    background-color: var(--primary-colors-2-capgemini-vibrant-blue);
  }
  &:hover ${SidebarItemContent} p {
    color: var(--primary-colors-2-capgemini-vibrant-blue);
  }
  &:hover {
    cursor: pointer;
  }
`;
export const SidebarItemStyled = styled.div`
  display: inline-grid;
  grid-template-columns: 1fr 4fr;
  align-items: center;
  height: 14vh;
  position: relative;
  & ${SidebarItemContainer} {
    display: inline-grid;
    grid-template-columns: 1fr 4fr;
    align-items: center;
  }

  @media(min-width: ${breakpoints.md}px) {
    height: 15vh;
  }
`;
export const SidebarContainer = styled("div")<BreakpointI>`
  margin-top: 4rem;
  grid-row: 1 / 4;
  grid-column: 1 / 2;
  z-index: 11;
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  max-width: 10vw;
  & .activated ${SidebarItemShape} ${Line} {
    border-left: 2px solid var(--primary-colors-2-capgemini-vibrant-blue);
  }
  & .activated ${SidebarItemShape} ${Dot} {
    background-color: var(--primary-colors-2-capgemini-vibrant-blue);
  }
  & .current ${SidebarItemContent} p {
    color: var(--primary-colors-2-capgemini-vibrant-blue);
  }
  & ${SidebarItemStyled}:nth-child(2) ${SidebarItemShape} ${Line} {
    visibility: hidden;
  }
  ${(props) =>
    isMobile(props.breakpoint) &&
    `
    margin-top: unset;
   & ${SidebarItemStyled}:nth-child(2) ${SidebarItemShape} ${Line} {
    visibility: unset;
  }
   & ${SidebarItemStyled}:nth-child(1) ${SidebarItemShape} ${Line} {
    visibility: hidden;
  }
  `}
  & .current ${SidebarItemShape} ${Line} {
    transition: 2s ease-out;
  }
  & .current ${SidebarItemShape} ${Dot} {
    height: 0.875rem;
    width: 0.875rem;
    left: -0.2rem;
  }
`;
