import styled from "styled-components";
import { BreakpointI } from "customHooks/useBreakpoint";

export const ArrowContainer = styled.div<BreakpointI>`
  position: absolute;
  transform: translateY(90%);

  ${(props) =>
    props.breakpoint === "xs" &&
    `
   transform: translateY(30%);
  `}
  ${(props) =>
    props.breakpoint === "sm" &&
    `
   transform: translateY(35%);
  `}
  ${(props) =>
    props.breakpoint === "md" &&
    `
   transform: translateY(40%);
  `}
`;

export const PktArrowContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  left: 0;
`;
