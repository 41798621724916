import React from "react";
import { Link } from "react-router-dom";
import { ReactComponent as IconLeft } from "assets/icon-arrow-left-on-light-normal.svg";
import { ReactComponent as IconCapgemini } from "assets/logo/Capgemini_Logo_color_rgb.svg";
import { ReactComponent as IconCapgeminiMobile } from "assets/logo/capgemini-logo-mobile.svg";
import { store } from "index";
import { CompanyLogo } from "components/companyLogo/CompanyLogo";
import { BreakpointI } from "customHooks/useBreakpoint";
import {
  HeaderContainer,
  GoBackContainer,
  ImageContainer,
} from "./SidepageTopNavigationStyles";

interface SidepageTopNavigationI extends BreakpointI {
  logo?: string;
}

export const SidepageTopNavigation = ({
  breakpoint = "",
  logo,
}: SidepageTopNavigationI) => (
  <HeaderContainer breakpoint={breakpoint}>
    <Link to={store.getState().navigationReducer.previousPage}>
      <GoBackContainer>
        <IconLeft />
        <p>Back to the map</p>
      </GoBackContainer>
    </Link>
    <Link to="/">
      <ImageContainer breakpoint={breakpoint}>
        {breakpoint === "xs" ? <IconCapgeminiMobile /> : <IconCapgemini />}
      </ImageContainer>
    </Link>
    {logo && !(breakpoint === "xs") && <CompanyLogo logo={logo} />}
  </HeaderContainer>
);
