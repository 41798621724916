import React, { Dispatch, SetStateAction, useContext } from "react";
import { AppContext } from "App";
import ContentLoader from "components/contentLoader/ContentLoader";
import Popup from "components/popup/Popup";
import TextDataIcon from "components/textDataIcon/TextDataIcon";
import { BreakpointI } from "customHooks/useBreakpoint";
import { ReactComponent as IconLink } from "assets/icon-link.svg";
import {
  PopupWrapper,
  UpperSection,
  InformationSection,
  SubtitleContent,
  Contact,
  LinkSection,
  DivideLine,
  RestaurantImage,
  RestaurantTitle,
  RestaurantSubtitle,
} from "./RestaurantPopupStyles";

export interface RestaurantPopupApiI {
  id_name: string;
  type: string;
  city: string;
  name: string;
  address: string;
  image: string;
  website_address: string;
  gmaps_link: string;
  is_visible: boolean;
}

export interface RestaurantPopupI extends BreakpointI {
  setPopupState?: Dispatch<SetStateAction<boolean>>;
  popupData: RestaurantPopupApiI;
}

export const RestaurantPopupDesktop = ({
  setPopupState,
  popupData,
}: RestaurantPopupI) => {
  const { breakpoint } = useContext(AppContext);
  const { name, address, image, website_address, gmaps_link } = popupData;

  return (
    <PopupWrapper>
      <Popup breakpoint={breakpoint} setPopupState={setPopupState}>
        {!Object.keys(popupData).length ? (
          <ContentLoader isDarkMode />
        ) : (
          <>
            <UpperSection>
              <RestaurantImage src={image} />
              <InformationSection>
                <RestaurantTitle>{name}</RestaurantTitle>
                <RestaurantSubtitle>Address and contact</RestaurantSubtitle>
                <SubtitleContent>
                  <p>{address}</p>
                  <Contact>
                    {website_address && (
                      <TextDataIcon
                        title="website"
                        href={website_address}
                        textSize={0}
                        Icon={IconLink}
                      />
                    )}
                  </Contact>
                </SubtitleContent>
              </InformationSection>
            </UpperSection>
            <DivideLine />
            <LinkSection>
              <TextDataIcon
                title="How to get there"
                href={gmaps_link}
                textSize={0}
                Icon={IconLink}
              />
            </LinkSection>
          </>
        )}
      </Popup>
    </PopupWrapper>
  );
};
