import React from "react";
import { Link } from "react-router-dom";
import leftArrow from "assets/icon-arrow-left-on-dark-normal.svg";
import rightArrow from "assets/icon-arrow-right-on-dark-normal.svg";
import {
  LeftArrowComponent,
  ModalButtonStyled,
  NavButtonsContainer,
  RightArrowComponent,
  Text,
} from "./ModalButtonsStyles";

export interface ModalButtonI {
  leftButtonUrl?: string;
  rightButtonUrl?: string;
  leftButtonText?: string;
  rightButtonText?: string;
  type?: "left" | "right";
}

const ModalButtons = ({
  leftButtonText,
  rightButtonText,
  leftButtonUrl = "/",
  rightButtonUrl = "/",
}: ModalButtonI) => (
  <NavButtonsContainer>
    <Link to={leftButtonUrl}>
      <ModalButtonStyled type="left">
        <LeftArrowComponent src={leftArrow} alt="left arrow" />
        <Text>{leftButtonText}</Text>
      </ModalButtonStyled>
    </Link>

    <Link to={rightButtonUrl}>
      <ModalButtonStyled type="right">
        <Text>{rightButtonText}</Text>
        <RightArrowComponent src={rightArrow} alt="right arrow" />
      </ModalButtonStyled>
    </Link>
  </NavButtonsContainer>
);

export default ModalButtons;
