import { breakpoints } from "customHooks/useBreakpoint";
import styled from "styled-components";
import mail from "assets/icon-envelope-solid.svg";

export const EmailWrapper = styled.a`
  display: flex;
  align-items: center;
  color: var(--primary-colors-5-capgemini-dark-grey);
  line-height: 1.71;
  font-size: 0.875rem;

  &::before {
    content: url(${mail});
    max-height: 1.625rem;
  }

  &:hover {
    color: var(--primary-colors-2-capgemini-vibrant-blue);
    &::before {
      filter: invert(57%) sepia(49%) saturate(3647%) hue-rotate(158deg)
        brightness(97%) contrast(86%);
    }
  }
`;

export const AgendaSpeakerWrapper = styled.section`
  padding-top: 29px;
  padding-bottom: 29px;
  display: flex;
  flex-direction: row;
  align-items: center;
  & img {
    border-radius: 100%;
    max-width: 190px;
    max-height: 190px;
    margin-right: 20px;
    @media (max-width: ${breakpoints.xs}px) {
      margin-right: unset;
    }
  }

  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
  }
`;

export const SpeakerInfoWrapper = styled.div`
  & :nth-last-child(1) {
    display: flex;
    flex-wrap: wrap;
  }

  & h2 {
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--primary-colors-6-capgemini-deep-purple);
  }

  & div p {
    font-size: 0.9375rem;
    line-height: 1.6;
    color: var(--primary-colors-5-capgemini-dark-grey);
  }

  @media (max-width: ${breakpoints.sm}px) {
    & h2 {
      text-align: center;
    }
  }
`;
