export const autoScroll = (mainPane: any, tout: any) => {
          const mp = mainPane.current;
          clearTimeout(tout.function);
          if (mp.scrollTop > 0 && mp.scrollTop < window.innerHeight / 2) {
            tout.function = setTimeout(() => {
              if (mp.scrollTop !== 0 && mp.scrollTop < window.innerHeight)
                mp.scroll({ top: 0, behavior: "smooth" });
            }, 500);
          } else if (
            mp.scrollTop > window.innerHeight / 2 &&
            mp.scrollTop < window.innerHeight
          ) {
            tout.function = setTimeout(() => {
              if (mp.scrollTop !== 0 && mp.scrollTop < window.innerHeight)
                mp.scroll({ top: window.innerHeight * 2, behavior: "smooth" });
            }, 500);
          }
}