import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "containers/sidebar/Sidebar";
import Button from "components/button/Button";
import CloseButton from "components/closeButton/CloseButton";
import PrivacyPolicy from "components/privacyPolicy/PrivacyPolicy";
import Logo from "assets/logo/Capgemini_Logo_color_rgb.svg";
import {
  SidebarMobileModalStyled,
  SidebarMobileModalContainer,
  BottomWrapper,
} from "./SidebarMobileModalStyles";

export interface SidebarMobileModalI {
  receivedFunc?: () => void;
  isVisible: boolean;
}

export const SidebarMobileModal = ({
  receivedFunc = () => {},
  isVisible,
  ...props
}: SidebarMobileModalI) => {
  const closeSidebar = () => {
    if (receivedFunc) {
      receivedFunc();
    }
  };
  return (
    <SidebarMobileModalStyled
      {...props}
      className={isVisible ? "visible" : "hidden"}
      onClick={closeSidebar}
    >
      <SidebarMobileModalContainer onClick={(event) => event.stopPropagation()}>
        <Link to="/">
          <img src={Logo} alt="Capgemini Logo" />
        </Link>
        <Sidebar breakpoint="sm" />
        <BottomWrapper>
          <Link to="/capgemini">
            <Button
              buttonType="secondaryLight"
              label="About Capgemini"
              size="small"
            />
          </Link>
          <PrivacyPolicy isMobile />
        </BottomWrapper>
      </SidebarMobileModalContainer>
      <CloseButton buttonType="onDark" onClick={closeSidebar} />
    </SidebarMobileModalStyled>
  );
};
