import styled from "styled-components";

export const AboutCapgeminiContainer = styled.div`
  z-index: 12;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background-color: var(--primary-colors-7-capgemini-white);

  & section:nth-child(1) {
    margin-bottom: 2rem;
  }

  & section:nth-child(3) {
    margin-top: 2.25rem;
    & h3 {
      margin-top: 0rem;
      margin-bottom: 0rem;
    }
    margin-bottom: 1.5rem;
  }
`;
