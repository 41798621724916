import React from 'react'
import { StyledPrivacyPolicy } from './PrivacyPolicyStyles'

const PrivacyPolicy = ({isMobile}: {isMobile?: boolean}) => {
    const link = "https://www.capgemini.com/pl-pl/polityka-prywatnosci/";
    return (
        <StyledPrivacyPolicy href={link} target="blank" isMobile={isMobile}>Privacy Policy</StyledPrivacyPolicy>
    )
}

export default PrivacyPolicy
