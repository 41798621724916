import React from "react";
import { Link } from "react-router-dom";
import agendaMobile from "assets/icon-agenda-mobile.svg";
import aboutMobile from "assets/icon-about-mobile.svg";
import { IconsContainer } from "./AboutUsAgendaMobileIconStyle";

export const AboutUsAgendaMobileIcon = () => (
  <IconsContainer>
    <Link to="/aboutssc">
      <span>
        <img src={aboutMobile} alt="about" />
        <p>About us</p>
      </span>
    </Link>
    <Link to="/agenda">
      <span>
        <img src={agendaMobile} alt="agenda" />
        <p>Your Visit</p>
      </span>
    </Link>
  </IconsContainer>
);
