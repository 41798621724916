import styled from "styled-components";
import { PlusButtonTextI } from "./PlusButtonText";

export const StyledPlusButtonTextStyled = styled.h1<PlusButtonTextI>`
  text-transform: uppercase;
  font-size: 2.25rem;
  cursor: grab;
  text-wrap: none;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--primary-colors-6-capgemini-deep-purple);
  ${(props) =>
    props.isTextVisible &&
    `
    visibility: unset;
`};
  ${(props) =>
    !props.isTextVisible &&
    `
    visibility: hidden;
`};
  ${(props) =>
    props.margin &&
    `
    margin: ${props.margin};
`};
  ${(props) =>
    props.fontSize &&
    `
    font-size: ${props.fontSize};
`};
`;
