import styled from "styled-components";
import { customUnique } from "customHooks/useBreakpoint";
import { CloseButtonI } from "./CloseButton";

export const CloseButtonStyled = styled.button<CloseButtonI>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
  &:hover {
    cursor: pointer;
  }
  ${(props) =>
    props.buttonType === "onLight" &&
    `
   background-color: var(--primary-colors-7-capgemini-white);
   g {
     fill: var(--primary-colors-1-capgemini-blue); 
   }
   &: hover {
   g {
     fill:  var(--primary-colors-2-capgemini-vibrant-blue);
   }
  `}
  ${(props) =>
    props.buttonType === "onDark" &&
    `
   width: 2rem;
   height: 2rem;
   background-color: var(--primary-colors-6-capgemini-deep-purple);
   g {
     fill: var(--primary-colors-3-capgemini-light-gray);
   }
   &: hover {
    g {
     fill: var(--primary-colors-7-capgemini-white);
    }
   }
  `}

  ${() => customUnique({customWidth: 290}) && ` // Dla szczegolnego przypadku waskich width np Galaxy Fold, "X" nachodzi na navbar
    object-fit: cover;
    width: 1.2rem;
    height: 1.2rem;
  `}
`;
