import { GoBackContainer } from "components/sidepageTopNavigation/SidepageTopNavigationStyles";
import ButtonStyles from "components/button/ButtonStyles";
import { breakpoints } from "customHooks/useBreakpoint";
import styled from "styled-components";
import { isVisibleI } from "./AgendaTokenPopup";

export const AgendaTokenPopupWrapper = styled("div")<isVisibleI>`
  display: none;
  padding: 1.25rem;

  input {
    margin: 0.5rem 0 1.75rem 0;
    width: 28.75rem;
    height: 3rem;
    border: solid 1px var(--primary-colors-4-capgemini-medium-grey);
    font-size: 1rem;
    box-shadow: 0 0 0 30px var(--primary-colors-7-capgemini-white) inset;
    padding-left: 0.5rem;
    :focus {
      outline: solid 1px var(--primary-colors-1-capgemini-blue);
    }
  }

  ${(props) =>
    props.isVisible &&
    `
     display: unset;
     @media (max-width: ${breakpoints.xs}px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      input {
        width: 96%;
      }
    }
  `}
`;

export const YourVisitTitlePopup = styled.h1`
  text-align: center;
  margin-bottom: 1rem;
  font-size: 2rem;
  color: var(--primary-colors-6-capgemini-deep-purple);
`;

export const YourVisitSubtitlePopup = styled.h3`
  text-align: center;
  font-size: 0.875rem;
  color: var(--primary-colors-5-capgemini-dark-grey);
  line-height: 1.43;
  margin-bottom: 0;
  font-weight: normal;
`;

export const InfoText = styled.p`
  text-align: center;
  margin-top: 0;
  font-size: 0.75rem;
  color: var(--secondary-colors-warm-color-palette-15-capgemini-red);
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;

  ${ButtonStyles} {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    height: 3rem;
    padding: 10px 40px;
  }

  @media (max-width: ${breakpoints.xs}px) {
    width: 80%;
    flex-direction: column-reverse;
    justify-content: center;
    margin: auto;
    button {
      width: 100%;
      margin-bottom: 1rem;
    }
    ${GoBackContainer} {
      justify-content: center;
    }
  }
`;
