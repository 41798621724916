import styled from "styled-components";

export const NavigationContainer = styled.div<{inModal?: boolean}>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1rem;

  ${({inModal}) => inModal && `position: fixed;
  top: 50px;
  width: 100vw;
  background: var(--primary-colors-7-capgemini-white);
  z-index: 201;`}
`;

export const NavigationItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 20%;
  height: 100%;
  cursor: pointer;
  border-bottom: solid 2px transparent;
  & p {
    font-size: 0.625rem;
    color: var(--primary-colors-4-capgemini-medium-grey);
  }
  &:hover {
    border-bottom: solid 2px var(--primary-colors-2-capgemini-vibrant-blue);
    & p {
      color: var(--primary-colors-2-capgemini-vibrant-blue);
    }
  }
`;
