import officeH from "assets/backup/wroclaw/office/office-h.webp";

const hotelsPoiBackup = [
  {
    id: 1349,
    id_name: "monopol",
    type: "hotel",
    city: "Wroclaw",
    name: "Hotel Monopol",
    address: "Heleny Modrzejewskiej 2, 50-071 Wrocław",
    image: officeH,
    website_address: "https://monopolwroclaw.hotel.com.pl",
    phone_number: "+48 71 772 37 77",
    e_mail: "monopol.wroclaw@hotel.com.pl",
    is_visible: true,
  },
  {
    id: 169,
    id_name: "radisson",
    type: "hotel",
    city: "Wroclaw",
    name: "Hotel Radisson Blu",
    address: "10 Purkyniego St, 50-156 Wrocław",
    image: officeH,
    website_address: "https://radissonhotels.com",
    phone_number: "+48 71 375 00 00",
    e_mail: "info.wroclaw@radissonblu.com",
    is_visible: false,
  },
  {
    id: 14,
    id_name: "art",
    type: "hotel",
    city: "Wroclaw",
    name: "Hotel Art",
    address: "Kiełbaśnicza 20, 50-110 Wrocław",
    image: officeH,
    website_address: "https://arthotel.pl",
    phone_number: "+48 661 120 300",
    e_mail: "rezerwacja@arthotel.pl",
    is_visible: true,
  },
  {
    id: 12,
    id_name: "puro",
    type: "hotel",
    city: "Wroclaw",
    name: "Hotel Puro",
    address: "Pawła Włodkowica 6, 50-072 Wrocław",
    image: officeH,
    website_address: "https://purohotel.pl/en/wroclaw",
    phone_number: "+48 71 772 51 00",
    e_mail: "wroclaw@purohotel.pl",
    is_visible: true,
  },
  {
    id: 19,
    id_name: "mercure",
    type: "hotel",
    city: "Wroclaw",
    name: "Hotel Mercure",
    address: "plac Dominikański 1, 50-159 Wrocław",
    image: officeH,
    website_address: "https://all.accor.com",
    phone_number: "+48 71 323 27 00",
    e_mail: "H3374-RE@ACCOR.COM",
    is_visible: false,
  },
  {
    id: 13,
    id_name: "mariott",
    type: "hotel",
    city: "Wroclaw",
    name: "AC Hotel by Marriott",
    address: "plac Wolności 10, 50-071 Wrocław",
    image: officeH,
    website_address: "https://marriott.com",
    phone_number: "+48 71 661 40 00",
    e_mail: "",
    is_visible: false,
  },
];

export default hotelsPoiBackup;
