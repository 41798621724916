import { breakpoints } from "customHooks/useBreakpoint";
import styled from "styled-components";

export const YouGainWrapper = styled.div`
  width: 78%;

  @media (max-width: ${breakpoints.lg}px) {
    width: 100%;
  }
`;
