import React, { useState, useEffect } from "react";
import { AgendaIcon, AgendaIconsShape } from "components/agendaIcon/AgendaIcon";
import { PlusInCircleButton } from "components/plusInCircleButton/PlusInCircleButton";
import {
  AgendaSpeaker,
  SpeakerI,
} from "components/agendaSpeaker/AgendaSpeaker";
import { BreakpointI } from "customHooks/useBreakpoint";
import sanitizer from "util/sanitizer";
import {
  AgendaEventTitleWrapper,
  AgendaEventMainPaneWrapper,
  AgendaEventDetailsWrapper,
  AgendaEventInfoWrapper,
  RoomTextWrapper,
  EventHeaderWrapper,
  AgendaEventWrapper,
  EventDetailContentWrapper,
  EventTopicWrapper,
  EventPlaceWrapper,
  TelephoneWrapper,
  MapWrapper,
  AddressWrapper,
  ContactDataWrapper,
  ContactHeaderWrapper,
  RoomWrapper,
} from "./AgendaEventStyles";

export interface AgendaEventDataI {
  title: string;
  event_category: string;
  start_time: string;
  finish_time: string;
  room?: string;
  topic?: string;
  reception_phone?: string;
  address?: string;
  gmap?: string | null;
  speakers?: SpeakerI[] | [];
}

interface AgendaEventI extends BreakpointI {
  data: AgendaEventDataI;
}

export const AgendaEvent = ({ data, breakpoint }: AgendaEventI) => {
  const [eventOpen, setEventOpen] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [mapIframe, setMapIframe] = useState("");

  const isMapIframeCorrect = (iframe: string) => {
    const iframeWidth =
      iframe.match(/width="(?<width>[0-9]*)"/)?.groups?.width || 0;
    const iframeHeight =
      iframe.match(/height="(?<height>[0-9]*)"/)?.groups?.height || 0;
    const isIframeSmall =
      !!iframeHeight &&
      !!iframeWidth &&
      +iframeHeight <= 400 &&
      +iframeWidth <= 400;
    const isElIframe = iframe.indexOf("iframe") >= 0;
    return !!isElIframe && !!isIframeSmall;
  };

  const replaceMapIframeSize = (iframe: string) => {
    if (isMapIframeCorrect(iframe)) return iframe;
    const widthReplaced = iframe.replace(/width="([0-9]*)"/, `width="400"`);
    const widthAndHeightReplaced = widthReplaced.replace(
      /height="([0-9]*)"/,
      `height="300"`
    );
    return widthAndHeightReplaced;
  };

  useEffect(() => {
    const preparedIframe = replaceMapIframeSize(data?.gmap || "");
    setMapIframe(preparedIframe);
    setShowMap(isMapIframeCorrect(preparedIframe));
  }, []);

  return (
    <AgendaEventWrapper>
      <AgendaEventMainPaneWrapper>
        {breakpoint !== "xs" && (
          <AgendaIcon
            shape={data.event_category as AgendaIconsShape}
            isActive={eventOpen}
            isDark={false}
          />
        )}
        <AgendaEventInfoWrapper>
          <EventHeaderWrapper>
            {breakpoint === "xs" && (
              <AgendaIcon
                shape={data.event_category as AgendaIconsShape}
                isActive={eventOpen}
                isDark={false}
              />
            )}
            <AgendaEventTitleWrapper>{data.title}</AgendaEventTitleWrapper>
          </EventHeaderWrapper>
          <AgendaEventDetailsWrapper>
            <div>
              {data.speakers &&
                data.speakers.length > 0 &&
                data.speakers.map((speaker: SpeakerI) => (
                  <p key={`${data.title}-${data.start_time}-${speaker.name}`}>
                    {speaker.name} {speaker.surname}
                  </p>
                ))}
              {data.room !== "" && (
                <RoomTextWrapper>{data.room}</RoomTextWrapper>
              )}
            </div>
            {data.topic !== "" && breakpoint === "xs" && (
              <PlusInCircleButton
                isDisabled={false}
                isOpen={eventOpen}
                onClick={() => {
                  setEventOpen(!eventOpen);
                }}
              />
            )}
          </AgendaEventDetailsWrapper>
        </AgendaEventInfoWrapper>
        {data.topic !== "" && breakpoint !== "xs" && (
          <PlusInCircleButton
            isDisabled={false}
            isOpen={eventOpen}
            onClick={() => {
              setEventOpen(!eventOpen);
            }}
          />
        )}
      </AgendaEventMainPaneWrapper>
      {eventOpen && (
        <EventDetailContentWrapper>
          <EventTopicWrapper>
            <h1>What will we talk about?</h1>
            <div
              // secured by sanitizer
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{
                __html: sanitizer(data.topic as string),
              }}
            />
          </EventTopicWrapper>
          {data.speakers &&
            data.speakers.length > 0 &&
            data.speakers.map((speaker: SpeakerI) => (
              <AgendaSpeaker
                key={`${data.title}-${data.start_time}-${speaker.surname}`}
                data={speaker}
              />
            ))}
          <EventPlaceWrapper>
            <ContactDataWrapper>
              <ContactHeaderWrapper>How to get there?</ContactHeaderWrapper>
              <RoomWrapper>{data.room}</RoomWrapper>
              <AddressWrapper>{data.address}</AddressWrapper>
              <TelephoneWrapper>{data.reception_phone}</TelephoneWrapper>
            </ContactDataWrapper>
            {showMap && (
              <MapWrapper
                // we are passing here the google map iframe, sanitazation is turned off because this was removing the iframe itself
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{
                  __html: mapIframe,
                }}
              />
            )}
          </EventPlaceWrapper>
        </EventDetailContentWrapper>
      )}
    </AgendaEventWrapper>
  );
};
