import { breakpoints } from "customHooks/useBreakpoint";
import styled from "styled-components";
import { CalendarDayStylesI } from "./CalendarDay";

export const CalendarDayWrapper = styled("button") <CalendarDayStylesI>`
  background-color: transparent;
  border: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.625rem 1.1875rem 0.75rem 1.1875rem;
  cursor: pointer;
  color: var(--primary-colors-4-capgemini-medium-grey);
  &:hover {
    color: var(--primary-colors-2-capgemini-vibrant-blue);
    cursor: pointer;
  }

  & p:nth-child(2) {
    margin-left: 0.4rem;
  }

  ${(props) =>
    props.isPast &&
    `
    opacity: 0.5;
  `}

  ${(props) =>
    props.isActive &&
    `
    color: var(--primary-colors-2-capgemini-vibrant-blue);
    cursor: pointer;
    }
  `}


  @media (max-width: ${breakpoints.xs}px) {
    padding: unset;
  }

  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
    align-items: center;
    padding-bottom: 0;

    & p:nth-child(1) {
      margin-bottom: 0.2rem;
    }

    & p:nth-child(2) {
      margin-top: 0.2rem;
      margin-left: unset;
    }
  }
`;
