import { updateObject } from "store/utility";
import { exampleCounterI } from "store/actions/exampleActions";

const initialState = {
  exampleCounter: 0,
};

const exampleReducer = (state = initialState, action: exampleCounterI) => {
  switch (action.type) {
    case "INCREMENT_EXAMPLE_COUNTER":
      return updateObject(state, { exampleCounter: action.exampleCounter });
    case "DECREMENT_EXAMPLE_COUNTER":
      return updateObject(state, { exampleCounter: action.exampleCounter });
    default:
      return state;
  }
};

export default exampleReducer;
