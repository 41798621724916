import useFetch from "customHooks/useFetch";
import { ourOfficesBackup } from "assets/backup/whySSCModal/ourOfficesBackup";
import { whatMakesUsStandOutBackup } from "assets/backup/whySSCModal/whatMakesUsStandOut/whatMakesUsStandOutBackup";
import { accordionsBackup } from "assets/backup/accordionsBackup";

export const WhySSCModalFetchData = () => {
  const carouselData: any = useFetch("/carousel", {}, accordionsBackup)
    .response;

  const whatMakesUsStandOutData: any = useFetch(
    "/why-ssc/tiles",
    {},
    whatMakesUsStandOutBackup
  ).response;

  const ourOfficesData: any = useFetch(
    "/why-ssc/our-offices",
    {},
    ourOfficesBackup
  ).response;

  const dataLoaded = [
    carouselData,
    [whatMakesUsStandOutData],
    [ourOfficesData],
  ].every((element: any) => element.length);
  return {
    dataLoaded,
    carouselData,
    whatMakesUsStandOutData,
    ourOfficesData,
  };
};
