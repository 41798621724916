import React, { FC } from "react";
import { useSelector } from "react-redux";
import { storeStateI } from "store/reducers";
import { imageList } from "assets/backgrounds/imageList";
import {
  MobileBackgroundStyled,
  BackgroundImage,
  MobilePreloader,
} from "./MobileBackgroundStyles";
import mobileLogo from "../../assets/logo/capgemini-logo-mobile.svg";

export interface MobileBackgroundI extends MobilePreloaderI {
  children?: React.ReactNode;
}

export interface MobilePreloaderI {
  isPreloaderVisible: boolean;
}

export const MobileBackground: FC<MobileBackgroundI> = ({
  children,
  isPreloaderVisible,
}: MobileBackgroundI) => {
  const selector = (state: storeStateI) => state.navigationReducer;
  const navigationReducer = useSelector(selector);
  const { currentPageIndex } = navigationReducer;
  return (
    <>
      <MobilePreloader isPreloaderVisible={isPreloaderVisible}>
        <img src={mobileLogo} alt="mobileLogo" />
      </MobilePreloader>
      <MobileBackgroundStyled>{children}</MobileBackgroundStyled>
      <BackgroundImage src={imageList[currentPageIndex]} alt="europe" />
    </>
  );
};
