import React from "react";

import { StyledPlusButtonTextStyled } from "./PlusButtonTextStyles";

export interface PlusButtonTextI {
  isTextVisible: boolean;
  text?: string;
  margin?: string;
  fontSize?: string;
}

export const PlusButtonText = ({
  isTextVisible,
  text = "",
  margin = "",
  fontSize = "",
}: PlusButtonTextI) => (
  <StyledPlusButtonTextStyled
    isTextVisible={isTextVisible}
    margin={margin}
    fontSize={fontSize}
  >
    {text}
  </StyledPlusButtonTextStyled>
);
