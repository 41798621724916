import styled from "styled-components";

export const AboutSSCModalHeader = styled.div`
  overflow-x: hidden;
  margin-left: 25%;
`;

export const AboutSSCModalWrapper = styled.div`
  height: 90%;
  overflow: scroll;
  display: flex;

  .activeColor a {
    color: var(--primary-colors-2-capgemini-vibrant-blue);
  }
`;

export const ModalPagesWrapper = styled.div`
  width: 75%;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const ModalHeaderHeading = styled.h2`
  font: inherit;
  font-size: 2rem;
  color: var(--primary-colors-6-capgemini-deep-purple);
  font-weight: 500;
`;

export const AboutSSCNavigationWrapper = styled.div`
  width: 25%;
  position: sticky;
`;
