import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import PlusIcon from "assets/icon-navi-plus-normal.svg";
import PlusIconDisabled from "assets/icon-navi-plus-normal-disabled.svg";
import MinusIcon from "assets/icon-navi-minus-normal.svg";
import {
  AccordionContent,
  ModalAccordionStyled,
  IconContainer,
  AccordionHeaderContainer,
  indicatorStyles,
} from "./ModalAccordionStyles";
import CarouselBox from "./carouselBox/CarouselBox";

export interface ModalAccordionDisableI {
  disable: boolean;
}

const ModalAccordion = ({
  data,
  state = false,
  height,
  title,
  disable,
}: any) => {
  const [openAccordion, setOpenAccordion] = useState(state);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setIsDisabled(disable || !data);
  }, []);

  return (
    <ModalAccordionStyled disable={isDisabled}>
      <AccordionHeaderContainer
        onClick={() => !isDisabled && setOpenAccordion(!openAccordion)}
        onKeyDown={() => !isDisabled && setOpenAccordion(!openAccordion)}
        disable={isDisabled}
      >
        {title}
        {isDisabled && (
          <IconContainer
            src={PlusIconDisabled}
            role="presentation"
            alt="plus icon"
            className="disabled"
          />
        )}
        {openAccordion && !isDisabled ? (
          <IconContainer src={MinusIcon} role="presentation" alt="minus icon" />
        ) : (
          !isDisabled && (
            <IconContainer src={PlusIcon} role="presentation" alt="plus icon" />
          )
        )}
      </AccordionHeaderContainer>
      {openAccordion && (
        <AccordionContent>
          <Carousel
            showThumbs={false}
            autoPlay={false}
            emulateTouch
            infiniteLoop
            centerMode
            centerSlidePercentage={100}
            showStatus={false}
            renderIndicator={(onClickHandler, isSelected, index) => {
              if (isSelected) {
                return (
                  <li
                    style={{
                      ...indicatorStyles,
                      background:
                        "var(--primary-colors-2-capgemini-vibrant-blue)",
                    }}
                  />
                );
              }
              return (
                <li
                  aria-label="li"
                  style={indicatorStyles}
                  onClick={onClickHandler}
                  onKeyDown={onClickHandler}
                  value={index}
                  key={index}
                  role="button"
                  tabIndex={0}
                />
              );
            }}
          >
            {data.map((container: { header: string; content: string }) => (
              <CarouselBox
                height={height}
                key={container.header}
                title={container.header}
                content={container.content}
              />
            ))}
          </Carousel>
        </AccordionContent>
      )}
    </ModalAccordionStyled>
  );
};

export default ModalAccordion;
