import React from "react";
import OurOffices from "containers/ourOffices/OurOffices";
import { ModalPage } from "./ModalPagesStyles";

const OurOfficesContent = ({
  breakpoint,
  carouselDataYourBenefits,
  carouselDataWroclaw,
  carouselDataPoznan,
  ourOfficesData,
}: any) => (
  <ModalPage id="OurOffices" breakpoint={breakpoint}>
    <OurOffices
      breakpoint={breakpoint}
      ourOfficesData={ourOfficesData}
      carouselDataYourBenefits={carouselDataYourBenefits}
      carouselDataWroclaw={carouselDataWroclaw}
      carouselDataPoznan={carouselDataPoznan}
    />
  </ModalPage>
);

export default OurOfficesContent;
