import styled from "styled-components";
import { breakpoints } from "customHooks/useBreakpoint";
import ButtonStyles from "components/button/ButtonStyles";
import { PopupContainer } from "components/popup/PopupStyles";

export const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  padding-bottom: 3rem;
  width: 100%;
  align-items: center;

  @media (min-width: ${breakpoints.md}px) {
    align-items: end;
    padding-bottom: 0rem;
  }

  & ${ButtonStyles} {
    width: 70%;
    max-width: 300px;
  }
`;

export const TitleStyled = styled.h3`
  font-size: 2rem;
  margin-top: 0;
`;

export const ContentStyled = styled.p`
  font-size: 0.9375rem;
  line-height: 1.6;
  margin: 0;
`;

export const PopupWrapper = styled.div`
  z-index: 101;
  position: absolute;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--primary-colors-1-capgemini-blue);

  ${PopupContainer} {
    padding: 0.8rem;
  }

  @media (min-width: ${breakpoints.md}px) {
    display: block;
    position: static;
    width: 100%;
    height: 100%;
    background: none;
  }
`;
