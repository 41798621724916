import React, { RefObject, useContext } from "react";
import { ReactComponent as IconArrow } from "assets/icon-arrow-right-on-dark-normal.svg";
import CloseButton from "components/closeButton/CloseButton";
import { AppContext } from "App";
import { WroclawContext } from "pages/Wroclaw/Wroclaw";
import { iOS } from "util/detectIphone/detectIphone";
import {
  IconWrapper,
  ListOfPointsPopupWrapper,
  ListPointsElement,
  StyledListOfPointsPopup,
  TextElement,
} from "./ListOfPointsPopupStyles";

interface Element {
  array: Array<any>;
  isFiltered?: boolean;
  sectionRef?: RefObject<HTMLDivElement>;
}
export interface ListOfPointsPopupI {
  closeClick: Function;
  listOfPoints?: Array<Element>;
}

const ListOfPointsPopup = ({
  closeClick,
  listOfPoints,
}: ListOfPointsPopupI) => {
  const trueOrUndefined = (x: boolean) => {
    if (x === undefined) return true;
    return x;
  };

  const { ScrolledDivRef } = useContext(WroclawContext);
  const { mainPane } = useContext(AppContext);

  const clickHandler = (
    section: RefObject<HTMLDivElement>,
    index: number,
    array: Array<any>
  ) => {
    mainPane.current.scroll({ behavior: "smooth", top: mainPane.current.offsetHeight * 2});
    let sectionToScrollTo = section.current!.offsetTop;
    if(iOS()) sectionToScrollTo -= 100;
    let sum = 0;

    for (let i = 0; i < index; i += 1) {
      if (trueOrUndefined(array[i].is_visible)) {
        sum += (section.current?.children[i] as HTMLDivElement).offsetHeight!;
      }
    }

    if (ScrolledDivRef.current)
      ScrolledDivRef.current!.scroll({
        behavior: "smooth",
        top: sectionToScrollTo + sum,
      });
  };

  return (
    <ListOfPointsPopupWrapper>
      <CloseButton buttonType="onDark" onClick={closeClick} />
      <StyledListOfPointsPopup>
        {listOfPoints?.map((clusteredArray) =>
          clusteredArray.array.map(
            (element, index, array) =>
              clusteredArray.isFiltered &&
              trueOrUndefined(element.is_visible) && (
                <ListPointsElement
                  onClick={() =>
                    clickHandler(clusteredArray.sectionRef!, index, array)
                  }
                >
                  <TextElement>{element.name}</TextElement>
                  <IconWrapper>
                    <IconArrow />
                  </IconWrapper>
                </ListPointsElement>
              )
          )
        )}
      </StyledListOfPointsPopup>
    </ListOfPointsPopupWrapper>
  );
};

export default ListOfPointsPopup;
