import styled from "styled-components";
import { AnimatedLinkingLineI } from "./AnimatedLinkingLine";

interface PointsI {
  topMargin: string;
  leftMargin: string;
}

export const StartingPoint = styled.div<PointsI>`
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin-top: ${(props) => props.topMargin};
  margin-left: ${(props) => props.leftMargin};
  z-index: 5;
`;

export const EndingPoint = styled.div<PointsI>`
  position: absolute;
  top: ${(props) => props.topMargin};
  left: ${(props) => props.leftMargin};
  z-index: 12;
`;

export const AnimatedLinkingLineStyles = styled.div<AnimatedLinkingLineI>`
  z-index: 5;
  display: flow;
  justify-content: space-evenly;
  width: calc(100% - 1px);
  height: 100%;
  animation: fadein 2s;
  @keyframes fadein {
    0% {
      opacity: 0;
    }

    70% {
      opacity: 0.7;
    }

    100% {
      opacity: 1;
    }
  }
  & :nth-last-child(1) {
    z-index: 4;
  }
  opacity: ${(props) =>
    props.isLinkingLineVisible ? `1` : `0`
  };
`;
