export const accordionsBackup = [
  {
    id: 1,
    carousel_type: "See Our Competences",
    header: "Digital",
    content:
      "<ul>\r\n\t<li>User-Centric-Design - UX/UI and Expertise</li>\r\n\t<li>VR/AR, Chatbots, Mobile Applications, Modern Web</li>\r\n\t<li>Digital Manufacturing, IoT</li>\r\n\t<li>Salesforce</li>\r\n</ul>",
  },
  {
    id: 2,
    carousel_type: "See Our Competences",
    header: "Package Based Solutions",
    content:
      "<ul>\r\n\t<li>S4HANA &ndash; Hybrid Architecture, UI5</li>\r\n\t<li>Frontend and backend development</li>\r\n\t<li>SAP Application/Functional Management</li>\r\n</ul>",
  },
  {
    id: 3,
    carousel_type: "See Our Competences",
    header: "Custom Software Development",
    content:
      "<ul>\r\n\t<li>Complex and mission critical systems</li>\r\n\t<li>Market standards (frameworks, technologies)</li>\r\n\t<li>JavaScript, Java, .Net and Cloud</li>\r\n</ul>",
  },
  {
    id: 4,
    carousel_type: "See Our Competences",
    header: "Nearshore Test Center",
    content:
      "<ul>\r\n\t<li>Agile Testing</li>\r\n\t<li>Smart-Automation &amp; E2E Testing</li>\r\n\t<li>Managed Testing Services</li>\r\n\t<li>Security &amp; Performance Testing</li>\r\n</ul>",
  },
  {
    id: 5,
    carousel_type: "See Our Competences",
    header: "Insight & Data",
    content:
      "<ul>\r\n\t<li>BI Projects: Data Engineering, AI Engineering, SAP BI, AI Analytics</li>\r\n\t<li>AI Prototyping and PoCs</li>\r\n\t<li>Platform Migration, Cloud Migration</li>\r\n</ul>",
  },
  {
    id: 6,
    carousel_type: "See Our Competences",
    header: "Application Maintenance & Development",
    content:
      "<ul>\r\n\t<li>Application Management</li>\r\n\t<li>Incident &amp; Change Management</li>\r\n\t<li>Software Implementation &amp; Development</li>\r\n\t<li>ECM, Project Management and PMO</li>\r\n</ul>",
  },
  {
    id: 7,
    carousel_type: "Wrocław",
    header: "Knowledge driven economy",
    content:
      "<p>As one of the most dynamically developing Polish cities with vast and varied cutting-edge lab and technology infrastructure Wrocław is the most popular location in Poland in the area of R&amp;D centers and Knowledge Process Outsourcing</p>",
  },
  {
    id: 8,
    carousel_type: "Wrocław",
    header: "Business oriented",
    content:
      "<p>Poland has the most stable economy in Central Europe, with stable banking sector and a controlled currency. The city strongly promotes and encourages entrepreneurship, supporting foreign and Polish companies on business growth</p>",
  },
  {
    id: 9,
    carousel_type: "Wrocław",
    header: "Perfect location & connectivity",
    content:
      "<p>With its central location in Europe and direct flights to 40 destinations, Wrocław is well connected with major large cities in Europe, like Frankfurt, Munich, London.</p>",
  },
  {
    id: 10,
    carousel_type: "Our Offices",
    header: "Certification",
    content:
      "<ul>\r\n\t<li>Certified to ISO 9001</li>\r\n\t<li>Certified to ISO 27001 (Germany and Poland)</li>\r\n\t<li>Security clearance (Level Confidential: 200+, LevelSecret: 100+), certification for accessing secret data in Germany and EU</li>\r\n</ul>",
  },
  {
    id: 11,
    carousel_type: "Our Offices",
    header: "Data Center and Infrastructure",
    content:
      "<ul>\r\n\t<li>Use of data center in Germany (Frankfurt) which is ISO 9001 and ISO/ IEC 27001 certified</li>\r\n\t<li>Administration of infrastructure is essentially done by Capgemini own employees</li>\r\n\t<li>Central interface to the Internet through data center in Frankfurt, German IP addresses</li>\r\n\t<li>Network infrastructure is subject to general requirements of Capgemini Infrastructure Services</li>\r\n</ul>",
  },
  {
    id: 12,
    carousel_type: "Our Offices",
    header: "Buildings and surroundings",
    content:
      "<ul>\r\n\t<li>24/7 Security and monitoring. Entrance manned 24/7</li>\r\n\t<li>Video surveillance of neuralgic points (roads, surroundings)</li>\r\n\t<li>Destruction of confidential / sensitive documents by a certified partner (safe collection container on each floor)</li>\r\n</ul>",
  },
  {
    id: 13,
    carousel_type: "Our Offices",
    header: "Employees",
    content:
      "<ul>\r\n\t<li>On-boarding of each employee with focus on data protection and information security</li>\r\n\t<li>Regular trainings and information campaigns</li>\r\n\t<li>Privacy and confidentiality declaration as part of the labor contract</li>\r\n\t<li>Dedicated Data Protection Officer</li>\r\n</ul>",
  },
  {
    id: 14,
    carousel_type: "Wrocław",
    header: "Innovation & industrial hub",
    content:
      "<p>Wrocław is a thriving inustrial hub with excellent business and R&amp;D infrastructure. Innovation and industry 4.0 flourish&sup1;, driven by automation and multi-stage data processing as well as a state-of-the-art technology</p>",
  },
  {
    id: 15,
    carousel_type: "Wrocław",
    header: "Extensive Talentpool & strong academic roots",
    content:
      "<p>Strong academic center with 125k students and the leading technical univeristy in Poland Wrocław attract multilingual tier-one talents, especially from IT sector&sup2;.</p>",
  },
  {
    id: 16,
    carousel_type: "Wrocław",
    header: "High skilled & qualified experts",
    content:
      "<p>Poland has the 1st most skilled programmers in Europe. Around 36,000&sup3; persons work in the entire IT industry in Wroclaw, and more than half of companies employ specialists with complex competences.</p>",
  },
  {
    id: 17,
    carousel_type: "Wrocław",
    header: "Fantastic place to live in",
    content:
      "<p>Wrocław was ranked as a one of the most attractive cities to live in thanks to its cultural, commercial and education offer. It also provides high quality health infrastructure and most dynamic growth of housing supply in Poland.</p>",
  },
  {
    id: 18,
    carousel_type: "Wrocław",
    header: "Innovation & industrial hub",
    content:
      "<p>Wrocław is a thriving inustrial hub with excellent business and R&amp;D infrastructure. Innovation and industry 4.0 flourish&sup1;, driven by automation and multi-stage data processing as well as a state-of-the-art technology</p>",
  },
  {
    id: 19,
    carousel_type: "Poznań",
    header: "Perfect Location & World-Class Business",
    content:
      "<ul>\r\n\t<li><strong>Poznań is located halfway between Warsaw and Berlin&nbsp;</strong>and connected with them by the highway and railway.</li>\r\n\t<li>It takes only <strong>1h 25 min to fly from Poznan to Frankfurt and Munich</strong>.</li>\r\n\t<li>The proximity and the availability of qualified specialists provide opportunities for development &ndash; especially for companies from the modern business services and IT sectors.</li>\r\n\t<li>Modern office spaces are waiting for new investors in virtually every part of the city.</li>\r\n</ul>",
  },
  {
    id: 20,
    carousel_type: "Poznań",
    header: "Strong & Enthusiastic IT Community in Capgemini SSC",
    content:
      "<p>Agile Community, Digital, Internet of Things, Artificial intelligence, FinTech, Guidewire, Software Watch, Cloud, Engagement Management, Craftsmanship</p>",
  },
  {
    id: 21,
    carousel_type: "Poznań",
    header: "Innovation & Business Oriented",
    content:
      "<p>According to the report &quot;Investment potential of the IT sector in Poznan&quot; the capital of Wielkopolska is assessed as <strong>a place to carry out a business activity in the IT industry a very good (32%) or good (48%).</strong></p>\r\n\r\n<p>84% of the surveyed companies admitted that Poznan is a good place for IT activities and 80% are positive about high qualifications effectiveness of IT employees</p>",
  },
  {
    id: 22,
    carousel_type: "Poznań",
    header: "Energy of Students",
    content:
      "<p>Every fifth inhabitant of Poznań is a student! The city has 25 universities that educate over 113,000 young people. The offer of Poznań universities is distinguished by economic and philological studies at a very high level, as well as technical and IT studies, which attract future engineers to Poznań.</p>",
  },
];
