import React from "react";
import sanitizer from "util/sanitizer";
import {
  ModalBoxBreakLine,
  ModalBoxContent,
  ModalBoxStyled,
  ModalBoxTitle,
  ModalBoxImg,
  ContentRectangle,
  ModalText
} from "./ModalBoxStyles";

export interface ModalBoxI {
  title?: string;
  content?: string;
  image?: string;
}

const ModalBox = ({ title, content, image }: ModalBoxI) => (
  <ModalBoxStyled >
    <ContentRectangle>
      <ModalBoxImg src={image} />
      <ModalText>
        <ModalBoxTitle>{title}</ModalBoxTitle>
        <ModalBoxBreakLine />
        <ModalBoxContent
          // secured by sanitizer
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{
            __html: sanitizer(content || ""),
          }}
        />
      </ModalText>
    </ContentRectangle>
  </ModalBoxStyled>
);

export default ModalBox;
