import styled from "styled-components";

export const AgendaCalendarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  box-sizing: border-box;
  border-bottom: 4px solid;
  border-image: linear-gradient(
      90deg,
      var(--primary-colors-1-capgemini-blue),
      var(--primary-colors-2-capgemini-vibrant-blue)
    )
    1;
  margin-bottom: 1.875rem;
  margin-top: 2.5rem;
`;
