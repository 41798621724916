import React from "react";
import ModalBox from "components/modalBox/ModalBox";
import { BreakpointI } from "customHooks/useBreakpoint";
import { ModalPage, SectionTitle, WhatMakesUsStandOutWrapper, BoxesWrapper } from "./ModalPagesStyles";

interface WhySSCTiles {
  id: string,
  location: number,
  image: string,
  header: string,
  content: string
}

interface WhatMakesUsStandOutContentI extends BreakpointI {
  whatMakesUsStandOut: WhySSCTiles[];
}

const WhatMakesUsStandOutContent = ({ whatMakesUsStandOut, breakpoint }: WhatMakesUsStandOutContentI) => (
  <ModalPage id="WhatMakesUsStandOut" breakpoint={breakpoint}>
    <WhatMakesUsStandOutWrapper>
      <SectionTitle>What Makes Us Stand Out</SectionTitle>
      <BoxesWrapper>
        {whatMakesUsStandOut?.length && (
          whatMakesUsStandOut.map((box) => (
            <ModalBox
              key={box.header}
              title={box.header}
              content={box.content}
              image={box.image}
            />
          ))
        )}
      </BoxesWrapper>
    </WhatMakesUsStandOutWrapper>
  </ModalPage>
);

export default WhatMakesUsStandOutContent;
