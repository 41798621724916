import styled from "styled-components";

import { ArrowInCircleI } from "./ArrowInCircle";

export const ArrowIconStyled = styled("img")<ArrowInCircleI>`
  filter: invert(23%) sepia(92%) saturate(2003%) hue-rotate(181deg)
    brightness(96%) contrast(103%);
  &:hover {
    cursor: pointer;
    filter: invert(71%) sepia(91%) saturate(3105%) hue-rotate(156deg)
      brightness(87%) contrast(96%);
  }
  ${(props) =>
    props.isDarkMode &&
    `
   filter: invert(100%) sepia(2%) saturate(1078%) hue-rotate(213deg) brightness(116%) contrast(85%);
   &:hover {
   cursor: pointer;
   filter: invert(89%) sepia(95%) saturate(20%) hue-rotate(278deg) brightness(105%) contrast(104%);
   }
  `}
  ${(props) =>
    props.direction === "down" &&
    `
    transform: rotate(180deg);
   }
  `}
    ${(props) =>
    props.direction === "right" &&
    `
    transform: rotate(90deg);
   }
  `}
    ${(props) =>
    props.direction === "left" &&
    `
    transform: rotate(270deg);
   }
  `}
`;
