import React from "react";
import { ModalNavigationI } from "components/modalNavigation/ModalNavigation";
import {
  NavigationContainer,
  NavigationItem,
} from "./MobileModalNavigationStyle";

interface MobileModalNavigationPropsI {
  navigateToSection: Function;
  navigationElements: ModalNavigationI[] | undefined;
}

export const MobileModalNavigation = ({
  navigateToSection, navigationElements
}: MobileModalNavigationPropsI) => (
  <NavigationContainer>
    { navigationElements && navigationElements.map((element: ModalNavigationI, index: number) => 
      <NavigationItem onClick={() => navigateToSection(index)} key={element.title}>
        <p>{element.title}</p>
      </NavigationItem>
    )}
  </NavigationContainer>
);
