import React from "react";
import { ModalSectionHeaderStyled } from "./ModalSectionHeaderStyles";

interface ModalSectionHeaderI {
  text: string;
  isSubHeader?: boolean;
}

export interface ModalSectionHeaderStyledI {
  isSubHeader?: boolean;
}

export const ModalSectionHeader = ({ text, isSubHeader = false }: ModalSectionHeaderI) => (
  <ModalSectionHeaderStyled isSubHeader={isSubHeader || false}>{text}</ModalSectionHeaderStyled>
);