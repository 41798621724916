import React from "react";
import ArrowIcon from "assets/icon-arrow.svg";
import { ArrowIconStyled } from "./ArrowInCircleStyles";

export interface ArrowInCircleI {
  isDarkMode: boolean;
  direction: "up" | "down" | "left" | "right";
}

export const ArrowInCircle = ({
  isDarkMode,
  direction,
  ...props
}: ArrowInCircleI) => (
  <ArrowIconStyled
    src={ArrowIcon}
    alt="Arrow_Up_Icon"
    isDarkMode={isDarkMode}
    direction={direction}
    {...props}
  />
);
