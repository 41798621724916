import React from "react";
import { ModalSectionHeader } from "components/modalSectionHeader/ModalSectionHeader";
import clientsLogo from "assets/clients-logos.webp";
import ModalAccordion from "containers/modalAccordion/ModalAccordion";
import { useImgUrl } from "customHooks/useImgUrl";
import sanitizer from "util/sanitizer";
import {
  OurCompetencesStyled,
  OurCompetencesContainer,
  CompetencesLogosContainerStyled,
  BoldParagraphStyled,
  ParagraphStyled,
} from "./OurCompetencesStyles";

export const OurCompetences = ({
  breakpoint,
  carouselData,
  ourCompetencesTiles,
  ourCompetencesLogos,
}: any) => (
  <OurCompetencesStyled>
    <ModalSectionHeader text="See Our Competences" />
    <OurCompetencesContainer>
      {ourCompetencesTiles.length &&
        ourCompetencesTiles.map((tile: { position: number; content: string }) =>
          tile.position ? (
            <ParagraphStyled
              dangerouslySetInnerHTML={{
                __html: sanitizer(tile.content),
              }}
            />
          ) : (
            <BoldParagraphStyled
              dangerouslySetInnerHTML={{
                __html: sanitizer(tile.content),
              }}
            />
          )
        )}
    </OurCompetencesContainer>
    <ModalAccordion
      data={carouselData}
      height={breakpoint === "xs" ? "80%" : "70%"}
      title="Offering"
    />
    <ModalSectionHeader text="Our Customers" isSubHeader />
    {ourCompetencesLogos?.logos?.length ? (
      <CompetencesLogosContainerStyled>
        {[...ourCompetencesLogos?.logos]?.map((el, i) => (
          // eslint-disable-next-line
          <img src={useImgUrl(el.logo)} key={i} alt="client logo" />
        ))}
      </CompetencesLogosContainerStyled>
    ) : (
      <img src={clientsLogo} alt="clients logos" />
    )}
  </OurCompetencesStyled>
);
