import React, { FC, useState } from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import { AfterVideoContent } from "components/afterVideoContent/AfterVideoContent";
import { storeStateI } from "store/reducers";
import { videoList } from "assets/backgrounds/videoList";

export interface VideoI {
  children?: React.ReactNode;
}

const autoPlay = true;
const Video: FC<VideoI> = ({ children }: VideoI) => {
  const [isVideoContentVisible, setIsVideoContentVisible] = useState(false);
  const selector = (state: storeStateI) => state.navigationReducer;
  const navigationReducer = useSelector(selector);
  const { previousPageIndex, currentPageIndex } = navigationReducer;
  const videoName = `${previousPageIndex}:${currentPageIndex}`;
  const videoBackupName = `0:${currentPageIndex}`;
  const handleVideoEnd = () => {
    setIsVideoContentVisible(true);
  };

  return (
    <>
      <AfterVideoContent showContent={isVideoContentVisible}>
        {children}
      </AfterVideoContent>
      <ReactPlayer
        muted={autoPlay}
        url={
          videoList[videoName]
            ? videoList[videoName]
            : videoList[videoBackupName]
        }
        playing={autoPlay}
        height="100%"
        width="100%"
        onEnded={handleVideoEnd}
        id="reactPlayer"
      />
    </>
  );
};

export default Video;
