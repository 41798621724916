import React from "react";
import { ModalSectionHeader } from "components/modalSectionHeader/ModalSectionHeader";
import { ImageWithDescription } from "components/imageWithDescription/ImageWithDescription";
import sanitizer from "util/sanitizer";
import { BreakpointI } from "customHooks/useBreakpoint";
import {
  Paragraph,
  TeamSpiritImgWrapper,
  BoldParagraph,
  LogosWrapper,
  AwardLogoStyled,
  TeamSpiritWrapper,
} from "./TeamSpiritStyles";

export interface TeamSpiritPropsI extends BreakpointI {
  teamSpiritData: TeamSpiritData;
}

export interface TeamSpiritData {
  id: number;
  intro: string;
  content: string;
  awards: [{ logo: string }];
  photo_1_medium: string;
  description_1: string;
  photo_2_medium: string;
  description_2: string;
  photo_3_medium: string;
  photo_4_medium: string;
}

export const TeamSpirit = ({
  teamSpiritData,
  breakpoint,
}: TeamSpiritPropsI) => (
  <TeamSpiritWrapper>
    <ModalSectionHeader text="Team Spirit" />
    <BoldParagraph>{teamSpiritData.intro}</BoldParagraph>
    <Paragraph
      // secured by sanitizer
      // eslint-disable-next-line
      dangerouslySetInnerHTML={{
        __html: sanitizer(teamSpiritData.content),
      }}
    />
    <LogosWrapper>
      {teamSpiritData?.awards?.map((el, index) => (
        <AwardLogoStyled src={el.logo} alt={`award logo ${index + 1}`} />
      ))}
    </LogosWrapper>
    <TeamSpiritImgWrapper>
      <ImageWithDescription
        src={teamSpiritData.photo_1_medium}
        alt="team spirit 1"
        description={teamSpiritData.description_1}
        breakpoint={breakpoint}
      />
      <ImageWithDescription
        src={teamSpiritData.photo_2_medium}
        alt="team spirit 2"
        description={teamSpiritData.description_2}
        breakpoint={breakpoint}
      />
    </TeamSpiritImgWrapper>
    <TeamSpiritImgWrapper>
      <ImageWithDescription
        src={teamSpiritData.photo_3_medium}
        alt="team spirit 3"
        breakpoint={breakpoint}
      />
      <ImageWithDescription
        src={teamSpiritData.photo_4_medium}
        alt="team spirit 4"
        breakpoint={breakpoint}
      />
    </TeamSpiritImgWrapper>
  </TeamSpiritWrapper>
);
