import styled from "styled-components";

export const ArrowButtonStyled = styled.button`
  padding: 0;
  background-color: var(--primary-colors-7-capgemini-white);
  border: none;
  margin: 0 1px 1px 0;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
`;
