import styled from "styled-components";

export const SidebarMobileModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--primary-colors-7-capgemini-white);
  box-shadow: 0 0.125rem 0.375rem 0.125rem
    var(--primary-colors-1-capgemini-blue);
  box-sizing: border-box;
  width: 15.625rem;
  height: 100%;
  padding-top: 2rem;
  position: fixed;
  top: 0;
  left: -15.625rem;

  transition: 0.5s transform;

  & a > img {
    margin-left: 0.75rem;
  }
`;
export const SidebarMobileModalStyled = styled.div`
  top: 0;
  position: fixed;
  z-index: 1001;
  backdrop-filter: blur(1px);
  background: linear-gradient(
    90deg,
    var(--primary-colors-1-capgemini-blue) 0%,
    #12abdbe6 100%
  );
  box-sizing: border-box;
  display: flex;
  height: 100%;
  min-height: -webkit-fill-available;
  width: 100vw;
  overflow: hidden;

  & > button {
    position: absolute;
    right: 2.5%;
    top: 2.5%;
  }
  &.visible {
    visibility: visible;
    & ${SidebarMobileModalContainer} {
      transform: translateX(15.625rem);
    }
  }
  &.hidden {
    visibility: hidden;
    & ${SidebarMobileModalContainer} {
      transform: translateX(0);
    }
  } 
`;

export const BottomWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
  margin-left: 1.5rem;
`;