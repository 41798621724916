import styled from "styled-components";

export const AgendaTopTextWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & h1 {
    color: var(--primary-colors-6-capgemini-deep-purple);
    font-weight: 500;
    font-size: 2.25rem;
    margin-bottom: 0.1875rem;
  }
  & p {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }

  & div:nth-last-child(1) {
    margin-top: 0.375rem;
  }
`;
