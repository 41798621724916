export const ourOfficesBackup = {
  content:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam felis massa, lobortis feugiat sem id, gravida pulvinar arcu. Mauris nunc ipsum, dictum vitae congue ac, egestas vitae turpis. Suspendisse eget finibus dui. Read more about our Security Standards.",
  stat_1_title: "2 000",
  stat_1_content: "IT Experts",
  stat_2_title: "1 600",
  stat_2_content: "Software Engineers",
  stat_3_title: "15 000",
  stat_3_content: "ICT Graduates annually",
};
