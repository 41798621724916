import styled from "styled-components";

export const StyledPrivacyPolicy = styled.a<{isMobile?: boolean}>`
position: absolute;
bottom: 36px;
left: 50%;
transform: translate(-50%, 0);
z-index: 101;
font-family: Ubuntu;
font-weight: normal;
font-size: 0.9375rem;
color: var(--primary-colors-1-capgemini-blue);

:hover {
    color: var(--primary-colors-2-capgemini-vibrant-blue);
}

${({isMobile}) => isMobile && `position: static; display: block; transform: translate(0, 0);`}
`;