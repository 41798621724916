import { breakpoints } from "customHooks/useBreakpoint";
import styled from "styled-components";

export const OurCompetencesStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 78%;
  @media (max-width: ${breakpoints.xs}px) {
    width: 95%;
    padding-right: 0;
  }
  @media (min-width: ${breakpoints.xs}px) and (max-width: ${breakpoints.lg}px) {
    padding: 0 1rem;
    width: 96.5%;
  }
`;

export const CompetencesLogosContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem 1rem;
  img {
    filter: grayscale(100%);
    transition: all 0.02s ease-in-out;
    max-height: 80px;
    object-fit: contain;
    padding: 0.5rem;
    max-width: 160px;
    &:hover {
      filter: grayscale(0%);
    }
    @media (min-width: ${breakpoints.sm}px) {
      width: 25%;
    }
  }
`;

export const OurCompetencesContainer = styled.div`
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  color: var(--primary-colors-5-capgemini-dark-grey);
  margin: 0;
  & p {
    margin: 0 0 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const ParagraphStyled = styled.div`
  font-size: 16px;
  line-height: 1.5;
`;

export const BoldParagraphStyled = styled.div`
  font-size: 18px;
  line-height: 1.33;
  & strong {
    font-weight: 500;
  }
`;