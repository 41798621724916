import styled from "styled-components";
import { ModalButtonI } from "./ModalButtons";

export const NavButtonsContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
`;

export const ModalButtonStyled = styled.div<ModalButtonI>`
  background: var(--primary-colors-6-capgemini-deep-purple);
  color: var(--primary-colors-7-capgemini-white);
  cursor: pointer;
  position: absolute;
  height: 4.5rem;
  width: 18.75rem;
  bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -0.625rem;
  &:hover {
    background: var(--primary-colors-1-capgemini-blue);
  }
  ${(props) =>
    props.type === "left" &&
    `
  border-radius: 0 2.25rem 2.25rem 0;
  left: -5vw;
  `}
  ${(props) =>
    props.type === "right" &&
    `
  border-radius: 2.25rem 0 0 2.25rem;
  right: -5vw;
  `}
`;

export const LeftArrowComponent = styled.img`
  position: absolute;
  left: 1vw;
`;

export const RightArrowComponent = styled.img`
  position: absolute;
  right: 1vw;
`;

export const Text = styled.div`
  max-width: 10rem;
  text-align: center;
`;
