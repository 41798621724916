/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React from "react";
import ReactDOM from "react-dom";

import { Provider } from "react-redux";
import { compose, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "store/reducers";
import { App } from "App";
import reportWebVitals from "reportWebVitals";
import { createGlobalStyle } from "styled-components";
import { breakpoints } from "customHooks/useBreakpoint";

export const globalStyles = `{
  --primary-colors-1-capgemini-blue: #0070ad;
  --primary-colors-2-capgemini-vibrant-blue: #12abdb;
  --primary-colors-3-capgemini-light-gray: #ececec;
  --primary-colors-4-capgemini-medium-grey: #777777;
  --primary-colors-5-capgemini-dark-grey: #272936;
  --primary-colors-6-capgemini-deep-purple: #2b0a3d;
  --primary-colors-7-capgemini-white: #ffffff;
  --secondary-colors-cool-color-palette-8-capgemini-green: #2ea657;
  --secondary-colors-cool-color-palette-9-capgemini-teal: #00bfbf;
  --secondary-colors-cool-color-palette-10-capgemini-peacock: #0f878a;
  --secondary-colors-cool-color-palette-11-capgemini-sapphire: #14596b;
  --secondary-colors-warm-color-palette-12-capgemini-violet: #ba2980;
  --secondary-colors-warm-color-palette-14-capgemini-velvet: #750d5c;
  --secondary-colors-warm-color-palette-13-capgemini-yellow: #ffb24a;
  --secondary-colors-warm-color-palette-15-capgemini-red: #ff304d;
  --tertiary-colors-20-capgemini-blue-grey: #797a82;
  --tertiary-colors-21-capgemini-dark-white: #fbfbfb;
  --tertiary-colors-22-capgemini-light-white: #fcfbfd;
  --tertiary-colors-23-capgemini-light-grey: #d3d3d3;
}`;

const GlobalStyle = createGlobalStyle`

:root ${globalStyles}
html {
  font-size: 16px;
  height: -webkit-fill-available;
}
body {
  color: var(--primary-colors-1-capgemini-blue);
  margin: 0;
   font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   text-rendering: optimizeSpeed;
   overflow: hidden;
   min-height: 100vh;
   min-height: -webkit-fill-available;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--primary-colors-7-capgemini-white);

}

*::-webkit-scrollbar {
  background-color: transparent;
  width: 0.3125rem;

  @media (min-width: ${breakpoints.md}px){
    width: 1rem;
  }
}

*::-webkit-scrollbar-thumb {
  background: var(--primary-colors-4-capgemini-medium-grey);
  background-clip: padding-box;
  border-right: none;

  @media (min-width: ${breakpoints.md}px){
    border-right: 10px white solid;
  }
}

* ::-webkit-scrollbar-track
{
  background-color: var(--primary-colors-7-capgemini-white);
}

code {
  font-family: 'Ubuntu', sans-serif;
}

a {
  text-decoration: none;
}

#reactPlayer {
  position:absolute;
  left:50%;
  top:50%;
  transform: translate(-50%, -50%);
  z-index:0;
}

#reactPlayer video {
  // It needs to be important because of settings given in react-player library which cannot be settled
  object-fit: cover !important;
  object-position: center; 
}
`;

// Redux router configuration

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Redux configuration

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <GlobalStyle />
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root") || document.createElement("div") // for testing purposes
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
