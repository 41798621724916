import { breakpoints } from "customHooks/useBreakpoint";
import { CSSProperties } from "react";
import styled from "styled-components";
import { ModalAccordionDisableI } from "./ModalAccordion";

export const ModalAccordionStyled = styled("div") <ModalAccordionDisableI>`
  width: 120%;
  border-bottom: 2px solid var(--primary-colors-1-capgemini-blue);
  border-radius: 1px;
  margin-bottom: 0.5rem;
  ${(props) =>
    props.disable &&
    `
    border-bottom: 2px solid var(--primary-colors-3-capgemini-light-gray);
  `};
  @media (max-width: ${breakpoints.lg}px) {
    width: 100%;
  }
`;

export const AccordionHeaderContainer = styled("div") <ModalAccordionDisableI>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: -2rem;
  align-items: center;
  cursor: pointer;
  ${(props) =>
    props.disable &&
    `
    color: var(--primary-colors-3-capgemini-light-gray);
    cursor: default;
  `}
`;

export const AccordionContent = styled.div`
  background-color: var(--primary-colors-3-capgemini-light-gray);
  width: 83%;
  .thumbs-wrapper {
    margin: 0;
    overflow: unset;
  }
  @media (max-width: ${breakpoints.lg}px) {
    width: 100%;
  }
`;

export const IconContainer = styled.img`
  width: 3rem;
  height: 3rem;
`;

export const indicatorStyles: CSSProperties = {
  background: "var(--primary-colors-4-capgemini-medium-grey)",
  width: 8,
  height: 8,
  borderRadius: "3rem",
  display: "inline-block",
  margin: "0 8px",
};
