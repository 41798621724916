import styled from "styled-components";

export const StyledMuteButtonLine = styled.div`
  display: block;
  width: 2px;
  height: 11px;
  background-color: var(--primary-colors-1-capgemini-blue);
  margin: 0 1px;
  margin-top: 2px;
  transition: transform cubic-bezier(0.165, 0.84, 0.44, 1) 0.3s;
  transform: translateY(0px) scaleY(1);
`;

export const StyledMuteButtonCircle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: solid 2px var(--primary-colors-3-capgemini-light-gray);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.8s;

  & ${StyledMuteButtonLine}:nth-child(2) {
    margin-top: 0px;
    height: 19px;
  }
  & ${StyledMuteButtonLine}:nth-child(3) {
    height: 14px;
    margin-top: 1px;
  }
`;

export const StyledMuteButton = styled.div`
  width: 50px;
  height: 50px;
  position: fixed;
  right: 40px;
  bottom: 30px;
  cursor: pointer;
  z-index: 1;
  margin-right: -1px;
  display: block;
  &: hover ${StyledMuteButtonCircle} {
    border: solid 2px var(--primary-colors-2-capgemini-vibrant-blue);
  }
  &: hover ${StyledMuteButtonCircle} ${StyledMuteButtonLine} {
    transform: scaleY(0.27273);
  }
  &: hover ${StyledMuteButtonCircle} ${StyledMuteButtonLine}:nth-child (2) {
    transform: translateY(1px) scaleY(0.31579);
  }
  &: hover ${StyledMuteButtonCircle} ${StyledMuteButtonLine}:nth-child (3) {
    transform: translateY(1px) scaleY(0.21429);
  }
  & .muted ${StyledMuteButtonLine} {
    transform: translateY(-1px) scaleY(0.18182);
  }
  & .muted ${StyledMuteButtonLine}:nth-child(2) {
    transform: translateY(0px) scaleY(0.10526);
  }
  & .muted ${StyledMuteButtonLine}:nth-child(3) {
    transform: translateY(-0.5px) scaleY(0.14286);
  }
  &: hover .muted ${StyledMuteButtonLine} {
    transform: translateY(-1px) scaleY(0.18182);
  }
  &: hover .muted ${StyledMuteButtonLine}:nth-child (2) {
    transform: translateY(0px) scaleY(0.10526);
  }
  &: hover .muted ${StyledMuteButtonLine}:nth-child (3) {
    transform: translateY(-0.5px) scaleY(0.14286);
  }
`;
