import React from "react";
import sanitizer from "util/sanitizer";
import { textTruncate } from "util/text";
import {
  ContentBoxContainer,
  ContentBoxTitle,
  ContentBoxText,
  ContentBoxImgStyled,
  ContentBoxTextWrapper,
} from "./TileStyles";

export interface TileI {
  header: string;
  content: string;
  image?: string;
  color: string;
}

const Tile = ({ header, content, image, color }: TileI) => {
  const divideHeader = (text: string) => {
    const wordArr = text.split(" ");
    const firstEl = `<h2>${wordArr
      .slice(0, wordArr.length / 2)
      .join(" ")}</h2>`;
    const secondEl = `<h2>${wordArr.slice(wordArr.length / 2).join(" ")}</h2>`;
    return firstEl + secondEl;
  };
  const truncatedHeaderText = textTruncate({ text: header, length: 60 });
  const dividedHeaderText = divideHeader(truncatedHeaderText);
  return (
    <ContentBoxContainer color={color}>
      <ContentBoxImgStyled src={image} />
      <ContentBoxTextWrapper>
        <ContentBoxTitle
          // secured by sanitizer
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{
            __html: sanitizer(dividedHeaderText),
          }}
        />
        <ContentBoxText
          // secured by sanitizer
          // eslint-disable-next-line
          dangerouslySetInnerHTML={{
            __html: sanitizer(content),
          }}
        />
      </ContentBoxTextWrapper>
    </ContentBoxContainer>
  );
};
export default Tile;
