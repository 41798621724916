import styled from "styled-components";
import {
  BreakpointI,
  breakpoints,
  customUnique,
  isMobile,
} from "customHooks/useBreakpoint";
import { PageHeaderI } from "./PageHeader";

export const PageHeaderMiddleSection = styled("div")<BreakpointI>`
  grid-area: middle;
  display: flex;
  justify-content: center;
  width: 100%;
  max-height: 150px;

  & :nth-child(1) {
    margin-top: 1rem;
    cursor: pointer;
    max-width: 180px;
    @media (max-width: ${breakpoints.md - 1}px) {
      margin-top: 0.5rem;
      cursor: pointer;
      max-width: 140px;
    }
  }
`;

export const PageHeaderRightSection = styled("div")<BreakpointI>`
  padding: 0.25rem;
  grid-area: right;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
`;

export const PageHeaderStylesComponent = styled("div")<PageHeaderI>`
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  grid-template-areas: "left middle right";
  grid-column: 1 / 4;
  grid-row: 1 / 2;
  max-height: 10vh;
  z-index: 10;

  @media only screen and (max-height: 335px) {
    max-height: 30vh;
  }

  ${({type}) => type === "popup" && `
    position: sticky;
    top: 0;
    width: 100vw;
    background: var(--primary-colors-1-capgemini-blue)!important;
    z-index: 201; 
    `}

  ${(props) =>
    props.isDarkMode &&
    `
    background-color: transparent;

    `};
  ${(props) =>
    props.breakpoint === "xs" &&
    `
    grid-template-columns: 0.75rem 1fr 2fr;
    `};
  ${(props) =>
    isMobile(props.breakpoint) &&
    `
    max-height: 15vh;
    
    `};

  ${() =>
    customUnique({ customHeight: 425 }) &&
    `
    max-height: 23vh;
    `}
`;
