import styled from "styled-components";
import { BreakpointI, isMobile } from "customHooks/useBreakpoint";
import { ModalI } from "./Modal";

export const ModalStyled = styled.div<ModalI>`
  overflow: hidden;
  z-index: 100;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    90deg,
    rgba(0, 112, 173, 0.6) 0%,
    rgba(0, 169, 214, 0.6) 100%
  );
  backdrop-filter: blur(0.063rem);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalChildrenWrapper = styled.div`
  height: 90%;
`;

export const HeadWrapper = styled.div`
  position: fixed;
  width: 100vw;
  background: var(--primary-colors-7-capgemini-white);
  z-index: 101;
`;

export const ModalContainer = styled("div") <BreakpointI>`
  position: relative;
  width: 90%;
  height: 90%;
  background: var(--primary-colors-7-capgemini-white);
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.375rem 0.125rem
    var(--primary-colors-1-capgemini-blue);

  ${(props) =>
    isMobile(props.breakpoint) &&
    `
       display:flex;
       flex-direction:column;
       width: 100%;
       height: 100%;
  `}

  ${(props) =>
    isMobile(props.breakpoint) &&
    `
      & ${ModalChildrenWrapper} {
        overflow-y: auto;
        margin-left: 1rem;
        overflow-x: hidden;
        padding: 100px 1rem;
      }
  `}
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  right: 0.8rem;
  top: 0.8rem;
`;
