import React, { Dispatch, SetStateAction, useContext } from "react";
import { AppContext } from "App";
import ContentLoader from "components/contentLoader/ContentLoader";
import Popup from "components/popup/Popup";
import TextDataIcon from "components/textDataIcon/TextDataIcon";
import { BreakpointI } from "customHooks/useBreakpoint";
import { ReactComponent as IconPhone } from "assets/icon-mobile-phone.svg";
import { ReactComponent as IconLink } from "assets/icon-link.svg";
import { ReactComponent as IconEnvelope } from "assets/icon-envelope-regular.svg";
import {
  Contact,
  DivideLine,
  InformationSection,
  LinkSection,
  PoiImage,
  PoiSubtitle,
  PoiTitle,
  PopupWrapper,
  SubtitleContent,
  UpperSection,
} from "./PoiPopupStyles";

export interface PoisPopupApiI {
  id_name: string;
  type: string;
  city: string;
  name: string;
  address: string;
  image: string;
  website_address: string;
  gmaps_link: string;
  phone_number: string;
  e_mail: string;
}

export interface PoiPopupI extends BreakpointI {
  setPopupState: Dispatch<SetStateAction<boolean>>;
  popupData: PoisPopupApiI;
}

export const PoiPopupDesktop = ({ setPopupState, popupData }: PoiPopupI) => {
  const { breakpoint } = useContext(AppContext);
  const {
    name,
    address,
    image,
    website_address,
    gmaps_link,
    phone_number,
    e_mail,
  } = popupData;

  return (
    <PopupWrapper>
      <Popup breakpoint={breakpoint} setPopupState={setPopupState}>
        {!Object.keys(popupData).length ? (
          <ContentLoader isDarkMode />
        ) : (
          <>
            <UpperSection>
              <PoiImage src={image} />
              <InformationSection>
                <PoiTitle>{name}</PoiTitle>
                <PoiSubtitle>
                  Address {phone_number && " and contact"}
                </PoiSubtitle>
                <SubtitleContent>
                  <p>{address}</p>
                  <Contact>
                    <TextDataIcon
                      title={phone_number}
                      textSize={0}
                      Icon={IconPhone}
                      iconPosition="left"
                    />
                    {website_address && (
                      <TextDataIcon
                        title="website"
                        href={website_address}
                        textSize={0}
                        Icon={IconLink}
                      />
                    )}
                  </Contact>
                  {e_mail && (
                    <TextDataIcon
                      title={e_mail}
                      textSize={0}
                      Icon={IconEnvelope}
                      iconPosition="left"
                    />
                  )}
                </SubtitleContent>
              </InformationSection>
            </UpperSection>
            <DivideLine />
            <LinkSection>
              <TextDataIcon
                title="How to get there"
                href={gmaps_link}
                textSize={0}
                Icon={IconLink}
              />
            </LinkSection>
          </>
        )}
      </Popup>
    </PopupWrapper>
  );
};
