import styled from "styled-components";

export const StyledPageNotFound = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  min-width: 100%;
  min-height: 100%;
  background: var(--tertiary-colors-22-capgemini-light-white);
  z-index: 101;
  overflow-y: overlay;
`;
