import styled from "styled-components";
import { BuildingContentContainer } from "components/buildingContent/buildingContentStyles";
import { BreakpointI, breakpoints } from "customHooks/useBreakpoint";

export const PopupContainer = styled("div") <BreakpointI>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: var(--primary-colors-1-capgemini-blue);
  border-radius: 4px;
  max-width: 70rem;
  width: 100%;
  height: 100%;
  overflow-y: visible;
  overflow-x: hidden;

  ${BuildingContentContainer} img {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--primary-colors-7-capgemini-white);

    border-top: solid 1rem transparent;
    border-bottom: solid 1rem transparent;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
    border-right: transparent 0.5rem solid;
  }
  &::-webkit-scrollbar {
    width: 0.25rem;
  }
`;

export const GradientSection = styled.div`
  background: linear-gradient(
    90deg,
    var(--primary-colors-1-capgemini-blue) 0%,
    var(--primary-colors-2-capgemini-vibrant-blue) 100%
  );
  padding: 1.2rem;
`;

export const TopSection = styled.section`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  & div:nth-child(-n + 2) {
    margin: 0;
  }
`;

export const MiddleSection = styled("section") <BreakpointI>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin: auto;
  line-height: 0;

  #middle_a {
    color: var(--primary-colors-7-capgemini-white);
    line-height: 1.6;
    text-decoration: none;
  }

  & img {
    padding-left: 0.5rem;
  }

  transition: all 0.5s ease-in-out;
  &:focus,
  &:hover,
  &:active {
    text-decoration: underline;
  }
`;

export const BottomSectionColumn = styled.div`
  color: var(--primary-colors-7-capgemini-white);
  margin-right: 1rem;
  & p {
    font-size: 0.875rem;
    line-height: 1.71;
    margin: 0;

    &:nth-child(1) {
      font-size: 0.9375rem;
      margin-top: 0;
    }
  }

  & ul {
    padding-inline-start: 1rem;
    margin-top: 0;

    & li {
      font-size: 0.875rem;
      line-height: 1.43;
    }
  }
`;

export const BottomSection = styled("section") <BreakpointI>`
  display:flex;
  flex-direction: column;
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;

  ${BottomSectionColumn} {
    :nth-child(2) {
    margin-top: 1rem;
    }
  }
`;


export const DivideLine = styled.hr`
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background-color: var(--primary-colors-3-capgemini-light-gray);
  margin: 1rem 0;
`;

export const HostName = styled.div<BreakpointI>`
  display: inline;
  font-size: 20px;
  font-weight: 500;
  color: var(--primary-colors-7-capgemini-white);
  margin: 0;
`;

export const YourPersonal = styled.div<BreakpointI>`
  p {
    color: var(--primary-colors-3-capgemini-light-gray);
    font-weight: normal;
    padding-right: 0;
  }
`;

export const HostEmail = styled.div<BreakpointI>`
  display: inline-flex;
  font-size: 15px;
  font-weight: normal;
  padding: 0;
  margin-left: 1rem;

  img {
    display: inline-block;
    border-radius: 50%;
    margin-right: 1rem;
  }

  p {
    margin-left: 0;
    color: var(--primary-colors-3-capgemini-light-gray);
  }
`;

export const HostPhone = styled.div<BreakpointI>`
  display: inline-flex;
  font-size: 15px;
  font-weight: 500;
  padding: 0;
  margin-left: 1rem;

  img {
    width: 1.3rem;
    border-radius: 50%;
    margin-right: 1rem;
  }

  p {
    margin-left: 0;
    color: var(--primary-colors-7-capgemini-white);
  }
`;

export const HostImage = styled.img`
  position: relative;
  display: inline;
  transform: translate(0, 0);
  height: 4rem;
  width: 4rem;
  border: solid var(--primary-colors-7-capgemini-white) 0.1875rem;
  border-radius: 50%;
`;

export const NameInfoBox = styled.div`
  margin-left: 1rem;
`;

export const HostBoxWrapper = styled.div`
  display: flex;
  margin-left: 1rem;
`;

export const ContactBoxWrapper = styled.div`
  @media (max-width: ${breakpoints.md}px) {
    grid-row: 2 span 1;
  }
`;

export const HostInfoContainer = styled("div") <BreakpointI>`
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  padding: 1rem 1rem 1rem 0;
  color: var(--primary-colors-7-capgemini-white);
  align-items: center;
  line-height: 1.6;

  & div p {
    line-height: 1.29;
    font-size: .875rem;
  }
`;

export const PopupMobileWrapper = styled.div`
  z-index: 100;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--primary-colors-1-capgemini-blue);
  overflow-y: visible;
`;
