import React, { Dispatch, SetStateAction } from "react";
import ContentLoader from "components/contentLoader/ContentLoader";
import TextDataIcon from "components/textDataIcon/TextDataIcon";
import { HotelsApiI } from "containers/hotels/Hotels";
import { BreakpointI } from "customHooks/useBreakpoint";
import { ReactComponent as IconPhone } from "assets/icon-mobile-phone.svg";
import { ReactComponent as IconLink } from "assets/icon-link.svg";
import Popup from "components/popup/Popup";
import {
  Contact,
  DivideLine,
  HotelImage,
  HotelSubtitle,
  HotelTitle,
  InformationSection,
  LinkSection,
  PopupWrapper,
  SubtitleContent,
  UpperSection,
} from "./HotelPopupStyles";

interface HotelPopupI extends BreakpointI {
  setPopupState?: Dispatch<SetStateAction<boolean>>;
  popupData: HotelsApiI;
}

export const HotelPopupDesktop = ({
  popupData,
  setPopupState,
  breakpoint,
}: HotelPopupI) => {
  const {
    name,
    address,
    image,
    website_address,
    phone_number,
    how_to_get_there,
  } = popupData;

  return (
    <PopupWrapper>
      <Popup breakpoint={breakpoint} setPopupState={setPopupState}>
        {!Object.keys(popupData).length ? (
          <ContentLoader isDarkMode />
        ) : (
          <>
            <UpperSection>
              <HotelImage src={image} />
              <InformationSection>
                <HotelTitle>{name}</HotelTitle>
                <HotelSubtitle>Address and contact</HotelSubtitle>
                <SubtitleContent>
                  <p>{address}</p>
                  <Contact>
                    <TextDataIcon
                      title={phone_number}
                      textSize={0}
                      Icon={IconPhone}
                      iconPosition="left"
                    />
                    {website_address && (
                      <TextDataIcon
                        title="website"
                        href={website_address}
                        textSize={0}
                        Icon={IconLink}
                      />
                    )}
                  </Contact>
                </SubtitleContent>
              </InformationSection>
            </UpperSection>
            <DivideLine />
            <LinkSection>
              <TextDataIcon
                title="How to get there"
                href={how_to_get_there}
                textSize={0}
                Icon={IconLink}
              />
            </LinkSection>
          </>
        )}
      </Popup>
    </PopupWrapper>
  );
};
