import { MapLocationI } from "assets/consts/mapLocations/interfaces";
import throttle from "lodash.throttle";
import { calculatePulsePointPosition } from "./multiplePointPositionCalculator/multiplePointPositionCalculator";

export const resizeHandlerMultiple = (
  points: Array<MapLocationI>,
  setPoints: Function
) => {
  const recalculatedPoints = points.map((point) =>
    calculatePulsePointPosition(point)
  );
  setPoints(recalculatedPoints);
};

const throttledHandleResizeMultiple = (
  points: Array<MapLocationI>,
  setPoints: Function
) => throttle(() => resizeHandlerMultiple(points, setPoints), 500);

export default throttledHandleResizeMultiple;
