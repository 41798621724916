import React, { useEffect, useRef, useState } from "react";
import Modal, { ModalI } from "components/modal/Modal";
import ModalButtons from "components/modalButtons/ModalButtons";
import ModalNavigation from "components/modalNavigation/ModalNavigation";
import OurCompetencesContent from "modalPages/OurCompetencesContent";
import YouGainContent from "modalPages/YouGainContent";
import TeamSpiritContent from "modalPages/TeamSpiritContent";
import ContentLoader from "components/contentLoader/ContentLoader";
import GetTheFutureLink from "modalPages/GetTheFutureLink";
import { AboutSSCModalFetchData } from "./AboutSSCModalFetchData";

import {
  AboutSSCModalHeader,
  AboutSSCModalWrapper,
  ModalPagesWrapper,
  ModalHeaderHeading,
  AboutSSCNavigationWrapper,
} from "./AboutSSCModalStyles";

const AboutSSCModal = ({ setModalState, navigationElements }: ModalI) => {
  const ourCompetencesRef = useRef<HTMLDivElement>(null);
  const youGainRef = useRef<HTMLDivElement>(null);
  const teamRef = useRef<HTMLDivElement>(null);
  const officesRef = useRef<HTMLDivElement>(null);
  const sectionRefs = [ourCompetencesRef, youGainRef, teamRef, officesRef];
  const [navigation, setNavigation] = useState([...(navigationElements || [])]);

  const prevScrollY = useRef(0);

  const {
    dataLoaded,
    carouselData,
    ourCompetencesTiles,
    ourCompetencesLogos,
    youGainTiles,
    teamSpiritData,
  } = AboutSSCModalFetchData();

  useEffect(() => {
    onLinkChange();
  }, [dataLoaded]);

  const onScroll = (e: any) => {
    const currentScrollY = e.target.scrollTop;
    prevScrollY.current = currentScrollY;
    onLinkChange(currentScrollY);
  };

  const scrollToSection = (refNumber: number) => {
    const selectedRef = sectionRefs[refNumber];
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onLinkChange = (position = 1) => {
    const constantBottomMargin = 20;
    removeActiveFromMenuEl();
    if (!ourCompetencesRef || !youGainRef || !teamRef) return;

    const ourCompetencesHeight = ourCompetencesRef.current?.clientHeight || 0;
    const youGainHeight = youGainRef.current?.clientHeight || 0;

    if (!position || position < ourCompetencesHeight - constantBottomMargin) {
      addActiveToMenuEl("#OurCompetences");
    } else if (
      position === ourCompetencesHeight ||
      position < ourCompetencesHeight + youGainHeight - constantBottomMargin
    ) {
      addActiveToMenuEl("#YouGain");
    } else if (
      position >=
      ourCompetencesHeight + youGainHeight - constantBottomMargin
    ) {
      addActiveToMenuEl("#TeamSpirit");
    }
  };

  const addActiveToMenuEl = (elementHref: string) =>
    setNavigation(
      navigation?.map(
        (el) => ({ ...el, isActive: el.href === elementHref } || [])
      )
    );

  const removeActiveFromMenuEl = () =>
    setNavigation(navigation?.map((el) => ({ ...el, isActive: false })) || []);

  return (
    <Modal breakpoint="xl" setModalState={setModalState}>
      <AboutSSCModalHeader>
        <ModalHeaderHeading>ABOUT SOFTWARE CENTER</ModalHeaderHeading>
      </AboutSSCModalHeader>
      <AboutSSCModalWrapper>
        {!dataLoaded ? (
          <ContentLoader />
        ) : (
          <>
            <AboutSSCNavigationWrapper>
              <ModalNavigation
                navigateToSection={scrollToSection}
                navigationElements={navigation}
              />
            </AboutSSCNavigationWrapper>
            <ModalPagesWrapper onScroll={onScroll}>
              <section ref={ourCompetencesRef}>
                <OurCompetencesContent
                  ourCompetencesLogos={ourCompetencesLogos}
                  ourCompetencesTiles={ourCompetencesTiles}
                  carouselData={
                    carouselData.length &&
                    carouselData.filter(
                      (element: any) =>
                        element.carousel_type === "See Our Competences"
                    )
                  }
                />
              </section>
              <section ref={youGainRef}>
                <YouGainContent youGainTiles={youGainTiles} breakpoint="xl" />
              </section>
              <section ref={teamRef}>
                <TeamSpiritContent
                  teamSpiritData={teamSpiritData}
                  breakpoint="xl"
                />
              </section>
              <section>
                <GetTheFutureLink
                  text="Get the Future You Want: "
                  linkto="www.capgemini.com"
                />
              </section>
            </ModalPagesWrapper>
          </>
        )}
      </AboutSSCModalWrapper>
      <ModalButtons
        leftButtonText="Welcome"
        rightButtonText="Why Software Center, Capgemini Poland"
        leftButtonUrl="/"
        rightButtonUrl="/whyssc"
      />
    </Modal>
  );
};
export default AboutSSCModal;
