import React from "react";
import ModalNumber from "components/modalNumber/ModalNumber";
import { AboutStatisticsContainer } from "./AboutCapgeminiStatisticsStyles";

export interface AboutStatisticsI {
  aboutStatisticsData: aboutStatisticsDataI;
}

interface aboutStatisticsDataI {
  stat_1_title: string;
  stat_1_content: string;
  stat_2_title: string;
  stat_2_content: string;
  stat_3_title: string;
  stat_3_content: string;
  stat_4_title: string;
  stat_4_content: string;
  stat_5_title: string;
  stat_5_content: string;
}
export const AboutCapgeminiStatistics = ({
  aboutStatisticsData,
  ...props
}: AboutStatisticsI) => (
  <AboutStatisticsContainer {...props}>
    <ModalNumber
      numberTitle={aboutStatisticsData.stat_1_title}
      numberValue={aboutStatisticsData.stat_1_content}
    />
    <ModalNumber
      numberTitle={aboutStatisticsData.stat_2_title}
      numberValue={aboutStatisticsData.stat_2_content}
    />
    <ModalNumber
      numberTitle={aboutStatisticsData.stat_3_title}
      numberValue={aboutStatisticsData.stat_3_content}
    />
    <ModalNumber
      numberTitle={aboutStatisticsData.stat_4_title}
      numberValue={aboutStatisticsData.stat_4_content}
    />
    <ModalNumber
      numberTitle={aboutStatisticsData.stat_5_title}
      numberValue={aboutStatisticsData.stat_5_content}
    />
  </AboutStatisticsContainer>
);
