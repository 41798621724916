import styled from "styled-components";
import pin from "assets/icon-pin.svg";
import phone from "assets/icon-old-phone.svg";
import { breakpoints } from "customHooks/useBreakpoint";

export const AgendaEventMainPaneWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;

  & button {
    margin-left: auto;
  }
`;

export const AgendaEventWrapper = styled.div`
  width: 100%;
  border-radius: 0.25rem;
  border: solid 1px rgba(119, 119, 119, 0.25);
  background-color: var(--primary-colors-7-capgemini-white);
  padding: 0.1875rem 1.25rem;
  box-sizing: border-box;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  @media (max-width: ${breakpoints.sm}px) {
    padding: 1.125rem 0.625rem 1.125rem 0.625rem;
    margin-left: 0.6875rem;
    margin-bottom: 0;
  }
`;

export const AgendaEventTitleWrapper = styled.h1`
  color: var(--primary-colors-6-capgemini-deep-purple);
  line-height: 1.43;
  font-size: 0.875rem;
  margin-bottom: 0.0625rem;
`;

export const AgendaEventDetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & p {
      line-height: 2;
      color: var(--primary-colors-5-capgemini-dark-grey);
      font-size: 0.875rem;
      margin-top: 0.25rem;
      margin-right: 1rem;
      @media (max-width: ${breakpoints.xs}px) {
        margin-bottom: 0.25rem;
      }
    }
  }
`;

export const AgendaEventInfoWrapper = styled.div`
  margin-left: 1rem;
  @media (max-width: ${breakpoints.xs}px) {
    margin-left: 0;
    width: 100%;
  } ;
`;

export const RoomTextWrapper = styled.p`
  display: flex;
  align-items: flex-start;
  &::before {
    content: url(${pin});
    max-height: 1.625rem;
  }
`;

export const EventHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & :nth-child(2) {
    margin-left: 1.1875rem;
  }
`;

export const EventDetailContentWrapper = styled.div`
  box-sizing: border-box;
  border-top: 1px solid var(--primary-colors-3-capgemini-light-gray);
  padding: 0 5%;
  & > * {
    border-bottom: 1px solid var(--primary-colors-3-capgemini-light-gray);
  }
  & :nth-last-child(1) {
    border-bottom: none;
  }

  @media (max-width: ${breakpoints.xs}px) {
    padding: 0;
  }
`;

export const EventTopicWrapper = styled.section`
  & h1 {
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: 500;
    color: var(--primary-colors-6-capgemini-deep-purple);
  }
  & div p {
    font-size: 0.9375rem;
    line-height: 1.6;
    color: var(--primary-colors-5-capgemini-dark-grey);
  }
`;

export const EventPlaceWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1.5rem 0;

  @media (min-width: ${breakpoints.sm}px) {
    flex-direction: row;
  }
`;

export const AddressWrapper = styled.p`
  font-size: 0.9375rem;
  line-height: 1.6;
  color: var(--primary-colors-5-capgemini-dark-grey);
`;

export const RoomWrapper = styled.p`
  font-size: 0.9375rem;
  font-weight: bold;
  line-height: 1.6;
  color: var(--primary-colors-5-capgemini-dark-grey);
`;

export const ContactHeaderWrapper = styled.h2`
  font-weight: 500;
  font-size: 1.25rem;
`;

export const ContactDataWrapper = styled.div`
  color: var(--primary-colors-6-capgemini-deep-purple);
  width: 100%;

  @media (min-width: ${breakpoints.sm}px) {
    width: 30%;
  }
`;

export const TelephoneWrapper = styled.p`
  font-size: 0.875rem;
  line-height: 1.71;
  color: var(--primary-colors-5-capgemini-dark-grey);
  display: flex;
  align-items: center;
  margin-right: 1rem;
  &::before {
    content: url(${phone});
    max-height: 1.625rem;
  }
`;

export const MapWrapper = styled.div`
  width: 100%;
  max-width: 43.76rem;
  iframe {
    width: 100%;
  }

  @media (min-width: ${breakpoints.sm}px) {
    width: 70%;
  }
`;
