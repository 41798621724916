import officeH from "assets/backup/wroclaw/office/office-h.webp";

const poisBackup = [
  {
    id: 35,
    id_name: "cathedral_island",
    type: "attraction",
    city: "Wroclaw",
    name: "The Cathedral Island",
    address: "Ostrów Tumski, Wrocław",
    image: officeH,
    website_address: null,
    gmaps_link: "https://goo.gl/maps/w5p16ACoMjPjz9Wp9",
    phone_number: null,
    e_mail: null,
  },
  {
    id: 25,
    id_name: "forum_of_music",
    type: "attraction",
    city: "Wroclaw",
    name: "The National Forum of Music -NFM",
    address: "Plac Wolności 1, 50-071 Wrocław",
    image: officeH,
    website_address: "https://www.nfm.wroclaw.pl",
    gmaps_link: "https://goo.gl/maps/nbbJGGtjnjCM8YY1A",
    phone_number: "+48717159700",
    e_mail: "rezerwacje@nfm.wroclaw.pl",
  },
  {
    id: 26,
    id_name: "four_domes",
    type: "attraction",
    city: "Wroclaw",
    name: "Four Domes Pavilion",
    address: "Wystawowa 1, 51-618 Wrocław",
    image: officeH,
    website_address: "https://pawilonczterechkopul.pl",
    gmaps_link: "https://g.page/PawilonCzterechKopul?share",
    phone_number: "+48717127181",
    e_mail: "pawilon@mnwr.pl",
  },
  {
    id: 27,
    id_name: "national_museum",
    type: "attraction",
    city: "Wroclaw",
    name: "National Museum in Wrocław",
    address: "Plac Powstańców Warszawy 5, 50-153 Wrocław",
    image: officeH,
    website_address: "https://mnwr.pl/en",
    gmaps_link: "https://goo.gl/maps/874G4pK5PeF492qv8",
    phone_number: "+48713433639",
    e_mail: "sekretariat@mnwr.pl",
  },
  {
    id: 28,
    id_name: "panorama_raclawice",
    type: "attraction",
    city: "Wroclaw",
    name: "Panorama of the Battle of Racławice",
    address: "ul. Jana Ewangelisty Purkyniego 11, 50-155 Wrocław",
    image: officeH,
    website_address:
      "https://mnwr.pl/en/category/branches/panorama-of-the-battle-of-raclawice",
    gmaps_link: "https://goo.gl/maps/LjbQrybgPi9AzMJz5",
    phone_number: "+48713433639",
    e_mail: "biuro@panoramaraclawicka.pl",
  },
  {
    id: 29,
    id_name: "openheim",
    type: "attraction",
    city: "Wroclaw",
    name: "OP ENHEIM",
    address: "Plac Solny 4, 50-060 Wrocław",
    image: officeH,
    website_address: "https://openheim.org/en/#all",
    gmaps_link: "https://g.page/openheim?share",
    phone_number: "+48713026971",
    e_mail: "info@openheim.org",
  },
  {
    id: 30,
    id_name: "vivid_gallery",
    type: "attraction",
    city: "Wroclaw",
    name: "Vivid Gallery",
    address: "Plac Kościuszki 4, 50-029 Wrocław",
    image: officeH,
    website_address: "https://www.vividgallery.pl",
    gmaps_link: "https://g.page/vividgallery?share",
    phone_number: "+48781666640",
    e_mail: "galeria@vividgallery.pl",
  },
  {
    id: 31,
    id_name: "museum_of_archeology",
    type: "attraction",
    city: "Wroclaw",
    name: "Museum of Archaeology, Wrocław City Museum",
    address: "ul. Antoniego Cieszyńskiego 9, 50-043 Wrocław",
    image: officeH,
    website_address: "https://muzeum.miejskie.wroclaw.pl",
    gmaps_link: "https://goo.gl/maps/SSPieN8j33JaQRih8",
    phone_number: "+48713471696",
    e_mail: "galeria@vividgallery.pl",
  },
  {
    id: 32,
    id_name: "dizajn_gallery",
    type: "attraction",
    city: "Wroclaw",
    name: "Dizajn Gallery",
    address: "ul. Świdnicka 2-4,8 50-067 Wrocław",
    image: officeH,
    website_address: "https://bwa.wroc.pl/dizajn/",
    gmaps_link: "https://goo.gl/maps/8p5MWY13DEFYJwdJ6",
    phone_number: "+48717901193",
    e_mail: "dizajn@bwa.wroc.pl",
  },
  {
    id: 33,
    id_name: "studio_gallery",
    type: "attraction",
    city: "Wroclaw",
    name: "Studio Gallery",
    address: "ul. Ruska 46a/301, 50-076 Wrocław",
    image: officeH,
    website_address: "https://bwa.wroc.pl/galeria/studio/",
    gmaps_link: "https://goo.gl/maps/d6F3ZLidcRyxBGhP6",
    phone_number: "+48660742564",
    e_mail: "studio@bwa.wroc.pl ",
  },
  {
    id: 34,
    id_name: "four_denomination_district",
    type: "attraction",
    city: "Wroclaw",
    name: "Four Denominations District",
    address: "Włodkowica, 50-072 Wrocław",
    image: officeH,
    website_address: null,
    gmaps_link: "https://goo.gl/maps/s5Quvqjk2cMQ4uxd7",
    phone_number: null,
    e_mail: null,
  },
];

export default poisBackup;
