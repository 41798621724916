import React, {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { CloseButton } from "components/closeButton/CloseButton";
import PageHeader from "components/pageHeader/PageHeader";
import { BackToTopButton } from "containers/backToTopButtton/BackToTopButton";
import { BreakpointI, isMobile } from "customHooks/useBreakpoint";
import {
  PopupContainer,
  CloseButtonContainer,
  PopupChildrenWrapper,
  MobilePopupContainer,
} from "./PopupStyles";

export interface PopupI extends BreakpointI {
  children?: React.ReactNode;
  setPopupState?: Dispatch<SetStateAction<boolean>>;
  displayHeaders?: boolean;
  isMultiPopup?: boolean;
  mainPane?: RefObject<HTMLDivElement>;
  setScrolledDivRef?: Function;
}

const Popup = ({
  children,
  setPopupState,
  displayHeaders,
  isMultiPopup,
  breakpoint,
  mainPane,
  setScrolledDivRef
}: PopupI) => {
  const ContainerRef = useRef<HTMLDivElement>(null);
  const [lazyLoad, setLazyLoad] = useState(false);

  useEffect(() => {
    if (isMultiPopup)
      setTimeout(() => {
        setLazyLoad(true);
        setScrolledDivRef!(ContainerRef);
      }, 1000);
  }, []);

  useEffect(() => {
    if (isMultiPopup) setScrolledDivRef!(ContainerRef);
  }, [children]);

  const PopupDefault = (
    <PopupContainer ref={ContainerRef} breakpoint={breakpoint}>
      <CloseButtonContainer>
        <CloseButton
          buttonType="onDark"
          onClick={() => setPopupState && setPopupState(false)}
        />
      </CloseButtonContainer>
      <PopupChildrenWrapper>{children}</PopupChildrenWrapper>
    </PopupContainer>
  );

  const PopupMobile = (
    <MobilePopupContainer>
      {displayHeaders && <PageHeader breakpoint={breakpoint} isDarkMode type="popup"/>}
      <PopupContainer
        breakpoint={breakpoint}
        onClick={(event) => event.stopPropagation()}
        ref={ContainerRef}
      >
        <PopupChildrenWrapper>{children}</PopupChildrenWrapper>
      </PopupContainer>
      {isMultiPopup
        ? lazyLoad &&
          displayHeaders && (
            <BackToTopButton
              type="popup"
              isDarkMode
              elementRef={ContainerRef.current}
              scrollMainPaneCallback={() => 
                mainPane!.current!.scroll({ behavior: "smooth", top: 0 })
              }
            />
          )
        : displayHeaders && (
            <BackToTopButton isDarkMode elementRef={ContainerRef.current}/>
          )}
    </MobilePopupContainer>
  );

  return isMobile(breakpoint) ? PopupMobile : PopupDefault;
};

export default Popup;
