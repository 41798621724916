import React from "react";
import { ReactComponent as Coffee } from "assets/agendaIcons/icon-capgemini-agenda-coffee-break-normal.svg";
import { ReactComponent as Discussion } from "assets/agendaIcons/icon-capgemini-agenda-discussion-normal.svg";
import { ReactComponent as Lunch } from "assets/agendaIcons/icon-capgemini-agenda-lunch-normal.svg";
import { ReactComponent as Online } from "assets/agendaIcons/icon-capgemini-agenda-online-normal.svg";
import { ReactComponent as Flight } from "assets/agendaIcons/icon-capgemini-agenda-plain-normal.svg";
import { ReactComponent as Presentation } from "assets/agendaIcons/icon-capgemini-agenda-presentation-normal.svg";
import { ReactComponent as Tour } from "assets/agendaIcons/icon-capgemini-agenda-tour-normal.svg";
import { ReactComponent as Training } from "assets/agendaIcons/icon-capgemini-agenda-training-normal.svg";
import { ReactComponent as Transfer } from "assets/agendaIcons/icon-capgemini-agenda-transfer-normal.svg";
import { AgendaIconWrapper } from "./AgendaIconStyles";

export type AgendaIconsShape =
  | "Coffee Break"
  | "Discussion"
  | "Lunch"
  | "Online"
  | "Flight"
  | "Presentation"
  | "Tour"
  | "Training"
  | "Transfer";
export interface AgendaIconI {
  shape?: AgendaIconsShape;
  isDark?: boolean;
  isActive: boolean;
}

export const AgendaIcon = ({ shape, isActive }: AgendaIconI) => {
  const isIconDark = (shapeName: AgendaIconsShape | string) =>
    !!["Training", "Presentation", "Online"].includes(shapeName);

  return (
    <AgendaIconWrapper isActive={isActive} isDark={isIconDark(shape || "")}>
      {shape === ("Coffee Break" as AgendaIconsShape) && <Coffee />}
      {shape === ("Discussion" as AgendaIconsShape) && <Discussion />}
      {shape === ("Lunch" as AgendaIconsShape) && <Lunch />}
      {shape === ("Flight" as AgendaIconsShape) && <Flight />}
      {shape === ("Tour" as AgendaIconsShape) && <Tour />}
      {shape === ("Transfer" as AgendaIconsShape) && <Transfer />}
      {shape === ("Training" as AgendaIconsShape) && <Training />}
      {shape === ("Presentation" as AgendaIconsShape) && <Presentation />}
      {shape === ("Online" as AgendaIconsShape) && <Online />}
    </AgendaIconWrapper>
  );
};
