import styled from "styled-components";

export const CompanyLogoWrapper = styled.div`
  grid-column: 3/4;
  justify-self: end;
  display: flex;
  align-items: center;
  margin: 0 1rem;
  & img {
    width: 3.4375rem;
    height: auto;
  }
  & p {
    color: var(--primary-colors-5-capgemini-dark-grey);
    margin-right: 1rem;
    font-size: 0.875rem;
    line-height: 1.43;
  }
`;
