export const isDatePast = (date: string) => {
  const today = new Date().setHours(0, 0, 0, 0);
  const checkDate = new Date(date).setHours(0, 0, 0, 0);
  return checkDate - today < 0;
};

export const checkDateTimePeriod = (date: string) => {
  const today = new Date().setHours(0, 0, 0, 0);
  const checkDate = new Date(date).setHours(0, 0, 0, 0);
  const result = today - checkDate;
  if (result < 0) {
    return "future";
  }
  if (result > 0) {
    return "past";
  }
  return "present";
};

const isHourPast = (time: string) => {
  const now = new Date().getTime();
  const checkTime = new Date().setHours(
    parseInt(time.substring(0, 2), 10),
    parseInt(time.substring(3, 5), 10),
    0,
    0
  );
  return checkTime - now < 0;
};

export const isTheTimePast = (period: string, eventEnd: string) => {
  if (period === "past") {
    return true;
  }
  if (period === "future") {
    return false;
  }
  return isHourPast(eventEnd);
};
