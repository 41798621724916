export const seeOurCompetencesBackup = [
  {
    id: 1,
    position: 0,
    content:
      "<p><strong>The mission of Software Center, Capgemini Poland is to provide end-2-end services tailored to your needs, embracing business and technical solutions. For many years we have been developing sector-specific expertise. We combine business-level strategy and design with engineering and operations management.</strong></p>",
  },
  {
    id: 2,
    position: 1,
    content:
      "<p>Objective from our perspective is to support you to become a market leader using cutting-edge technologies as a business enabler. SSC&rsquo;s strengths are cloud &amp; data, artificial intelligence, and intelligent industry. We stand at your side as a partner to leverage innovation in your digital transformation journey. Client oriented approach is at the center of our agenda, which is confirmed by our partners who trusted us and return with new projects.&nbsp;</p>",
  },
];
