import React, { FC, useEffect, useState, useContext } from "react";
import AnimatedPoint from "components/animatedPoint/AnimatedPoint";
import { PlusButtonText } from "components/plusButtonText/PlusButtonText";
import DoubleAnimatedLinkingLine from "components/doubleAnimatedLinkingLine/DoubleAnimatedLinkingLine";
import { PlusButton } from "components/plusButton/PlusButton";
import { ArrowButton } from "components/arrowButton/ArrowButton";
import { HeadingPageText } from "components/headingPageText/HeadingPageText";
import { ModalNavigationI } from "components/modalNavigation/ModalNavigation";
import Video from "containers/video/Video";
import { MobileBackground } from "containers/mobileBackground/MobileBackground";
import { AboutSSCMobileModal } from "containers/aboutSSCMobileModal/AboutSSCMobileModal";
import AboutSSCModal from "containers/aboutSSCModal/AboutSSCModal";
import { navigationSetup } from "util/navigationSetup/navigationSetup";
import handleResize from "util/handleResize";
import { isMobile } from "customHooks/useBreakpoint";
import { AppContext } from "App";
import { ArrowContainer, PktArrowContainer } from "./AboutNSCStyles";

const AboutNSC: FC = () => {
  const [modalState, setModalState] = useState(false);
  const [desiredPointWidth, setDesiredPointWidth] = useState("1238");
  const [desiredPointHeight, setDesiredPointHeight] = useState("810");
  const [secondDesiredPointWidth, setSecondDesiredPointWidth] = useState(
    "1240"
  );
  const [secondDesiredPointHeight, setSecondDesiredPointHeight] = useState(
    "800"
  );

  const [displayPreloader, setDisplayPreloader] = useState(true);
  const mobilePosition = {
    width: -25,
    height: 226,
    backgroundWidth: 320,
    backgroundHeight: 568,
  };
  const mobileSecondPointPosition = {
    width: -25,
    height: 182,
    backgroundWidth: 320,
    backgroundHeight: 568,
  };

  const secondPointPosition = { width: 270, height: 125 };
  const desktopPosition = { width: 270, height: 150 };

  const calculatePosition = handleResize([
    {
      mobilePosition,
      desktopPosition,
      setDesiredPointWidth,
      setDesiredPointHeight,
    },
    {
      mobilePosition: mobileSecondPointPosition,
      desktopPosition: secondPointPosition,
      setDesiredPointHeight: setSecondDesiredPointHeight,
      setDesiredPointWidth: setSecondDesiredPointWidth,
    },
  ]);

  const navigation: ModalNavigationI[] = [
    {
      href: "#OurCompetences",
      title: "See Our Competences",
      isActive: true,
    },
    {
      href: "#YouGain",
      title: "You Gain",
      isActive: false,
    },
    {
      href: "#TeamSpirit",
      title: "Team Spirit",
      isActive: false,
    },
  ];

  useEffect(() => {
    navigationSetup();
    calculatePosition();
    window.addEventListener("resize", calculatePosition);
    setTimeout(() => setDisplayPreloader(false), 2000);

    return () => {
      window.removeEventListener("resize", calculatePosition);
    };
  }, [desiredPointHeight, desiredPointWidth]);

  const { breakpoint } = useContext(AppContext);

  const toggleModal = () => {
    setModalState(!modalState);
  };

  const desktopView = (
    <Video>
      <div>
        {modalState && (
          <AboutSSCModal
            breakpoint="xl"
            setModalState={setModalState}
            navigationElements={navigation}
          />
        )}
      </div>
      <PktArrowContainer>
        <DoubleAnimatedLinkingLine
          endLineHeightAdjustment={28}
          endLineWidthAdjustment={25}
          displayPopup={modalState}
          startingLeft={desiredPointWidth} // Wroclaw
          startingTop={desiredPointHeight}
          secondStartingTop={secondDesiredPointHeight} // Poznan
          secondStartingLeft={secondDesiredPointWidth}
          secondStartingPoint={
            <AnimatedPoint
              pointColor="vibrantBlue"
              size="small"
              textPosition="right"
            >
              Poznań
            </AnimatedPoint>
          }
          endingTop="23%"
          endingLeft="30%"
          color="var(--primary-colors-2-capgemini-vibrant-blue)"
          secondLineColor="var(--primary-colors-2-capgemini-vibrant-blue)"
          startingPoint={
            <AnimatedPoint
              pointColor="vibrantBlue"
              size="small"
              textPosition="right"
            >
              Wrocław
            </AnimatedPoint>
          }
          endingPoint={
            <div>
              <PlusButtonText
                fontSize="2.25rem"
                margin="auto"
                text="About Software Center,"
                isTextVisible={!modalState}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <PlusButton
                  receivedFunc={toggleModal}
                  showChildComponent={modalState}
                />
                <PlusButtonText
                  fontSize="2.25rem"
                  text="Capgemini Poland"
                  isTextVisible={!modalState}
                  margin="auto auto 2rem 2rem"
                />
              </div>
            </div>
          }
        />
      </PktArrowContainer>
    </Video>
  );

  const mobileView = (
    <MobileBackground isPreloaderVisible={displayPreloader}>
      <HeadingPageText text="ABOUT SOFTWARE CENTER" />
      {modalState && (
        <AboutSSCMobileModal
          breakpoint={breakpoint}
          navigationElements={navigation}
        />
      )}
      <PktArrowContainer>
        <DoubleAnimatedLinkingLine
          isDragable={false}
          endLineHeightAdjustment={45}
          endLineWidthAdjustment={35}
          displayPopup={modalState}
          startingLeft={desiredPointWidth}
          startingTop={desiredPointHeight}
          secondStartingLeft={secondDesiredPointWidth}
          secondStartingTop={secondDesiredPointHeight}
          endingTop="55%"
          endingLeft="55%"
          color="var(--primary-colors-2-capgemini-vibrant-blue)"
          secondLineColor="var(--primary-colors-2-capgemini-vibrant-blue)"
          startingPoint={
            <AnimatedPoint
              pointColor="vibrantBlue"
              size="small"
              textPosition="left"
            >
              Wrocław
            </AnimatedPoint>
          }
          secondStartingPoint={
            <AnimatedPoint
              pointColor="vibrantBlue"
              size="small"
              textPosition="left"
            >
              Poznań
            </AnimatedPoint>
          }
          endingPoint={
            <ArrowContainer breakpoint={breakpoint}>
              <ArrowButton
                breakpoint={breakpoint}
                onClick={() => setModalState(true)}
              />
            </ArrowContainer>
          }
        />
      </PktArrowContainer>
    </MobileBackground>
  );
  return isMobile(breakpoint) ? mobileView : desktopView;
};

export default AboutNSC;
