import React from "react";
import {
  ModalNavigationWrapper,
  HorizontalLine,
  LinksWrapper,
  NavigationItem
} from "./ModalNavigationStyles";

export interface ModalNavigationI {
  href: string,
  title: string,
  isActive?: boolean,
}

export interface ModalNavigationProps {
  navigateToSection: Function;
  navigationElements?: ModalNavigationI[];
}

const ModalNavigation = ({navigateToSection, navigationElements}: ModalNavigationProps) => (
  <ModalNavigationWrapper>
    {navigationElements && navigationElements.map((element: ModalNavigationI, index: number) => 
      <LinksWrapper key={element.title} isActive={element.isActive}>
        <HorizontalLine />
        <NavigationItem onClick={() => navigateToSection(index)}>{element.title}</NavigationItem>
      </LinksWrapper>
    )}
  </ModalNavigationWrapper>
);

export default ModalNavigation;
