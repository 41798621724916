import { breakpoints } from "customHooks/useBreakpoint";
import styled, { css } from "styled-components";

interface FilterElementI {
  isActive: boolean;
  dotColor: string;
}

export const MapFilterWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.5rem;
  z-index: 11;
  display: flex;
  flex-direction: row;
  width: 15vw;
  max-width: 15rem;
  text-align: right;
  height: 40vh;
  color: var(--primary-colors-4-capgemini-medium-grey);

  @media (max-width: ${breakpoints.md - 1}px) {
    position: relative;
    transform: translateY(0%);
    max-width: none;
    width: max-content;
    height: auto;
    right: 0;
    flex-direction: column-reverse;
  }
`;

export const DescriptionLabel = styled.div`
  text-orientation: mixed;
  writing-mode: vertical-lr;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  font-size: 0.75rem;
  @media (max-width: ${breakpoints.md - 1}px) {
    writing-mode: initial;
    position: absolute;
    transform: translate(-50%, 0);
    color: var(--primary-colors-4-capgemini-medium-grey);
    left: 50%;
    top: 7px;
  }
`;

export const FilterElementsWrapper = styled.div`
  margin-right: 0.8125rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: 500;
  width: 100%;
  font-size: 0.9375rem;

  @media (max-width: ${breakpoints.md - 1}px) {
    flex-direction: row;
    margin-right: 0;
  }
`;

export const FilterName = styled.span`
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-align: end;
  width: 80%;

  @media (max-width: ${breakpoints.md - 1}px) {
    width: auto;
  }
`;

export interface DotI {
  dotColor: string;
  isActive?: boolean;
}

export const Dot = styled.span<DotI>`
  border-radius: 50%;
  background-color: var(--primary-colors-3-capgemini-light-gray);

  height: 0.625rem;
  width: 0.625rem;
  z-index: 11;
  &:focus,
  &:active,
  &:hover {
    background-color: ${({ dotColor }) => dotColor};
  }

  ${({ isActive, dotColor }) =>
    isActive && `background-color: ${dotColor}!important`}
`;

export const Line = styled.span`
  position: absolute;
  border-left: 2px solid var(--primary-colors-3-capgemini-light-gray);
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
  right: 1.875rem;
  @media (max-width: ${breakpoints.md - 1}px) {
    border-left: none;
    border-bottom: 2px solid var(--primary-colors-3-capgemini-light-gray);
    transform: translateY(0%);
    transform: translate(-50%, 0);
    width: 87.5%;
    top: unset;
    bottom: 5px;
    height: 0;
    left: 50%;
  }
`;

export const activeFilterEl = css`
  cursor: pointer;
  color: var(--primary-colors-6-capgemini-deep-purple);
  ${Dot} {
    height: 0.875rem;
    width: 0.875rem;
    margin-right: -2px;
    @media (max-width: ${breakpoints.md - 1}px) {
      margin-right: 0;
    }
  }
`;

export const FilterElement = styled.div<FilterElementI>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  :last-child {
    align-items: end;
  }
  :first-child {
    align-items: flex-start;
  }
  &:hover,
  :active {
    ${activeFilterEl} {
      ${Dot} {
        background-color: ${({ dotColor }) => dotColor};
      }
    }
    filter: brightness(1.25);
    opacity: 0.9;
  }
  ${(props) => props.isActive && activeFilterEl}

  @media (max-width: ${breakpoints.md - 1}px) {
    position: relative;
    align-items: center !important;
    flex-direction: column;
    padding: 0 10px;
    :nth-child(2) {
      height: 2.1875rem;
    }
    &:hover,
    :active {
      ${Dot} {
        background-color: var(--primary-colors-3-capgemini-light-gray);
      }
      ${(props) =>
        !props.isActive &&
        `color: var(--primary-colors-4-capgemini-medium-grey)!important;
      `}

      filter: none;
      opacity: 100%;
    }
  }
`;

export const FilterMobileWrapper = styled.div`
  margin-top: 1.875rem;
  padding-bottom: 10px;
  overflow-x: scroll;
  -moz-scrollbars-horizontal: auto;
  ::-webkit-scrollbar {
    height: 8px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb { 
    border: solid transparent 3px;
    height: 4px;
    background-color: var(--tertiary-colors-23-capgemini-light-grey);
  }
`;

export const FilterMobileContainer = styled.div`
  border-top: solid 1px var(--primary-colors-3-capgemini-light-gray);
  border-bottom: solid 1px var(--primary-colors-3-capgemini-light-gray);
  position: absolute;
  box-sizing: border-box;
  z-index: 11;
  max-width: 100vw;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 0);
`;
