import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LogoOnLight from "assets/logo/Capgemini_Logo_color_rgb.svg";
import LogoOnDark from "assets/logo/Capgemini_Logo_white.svg";
import { HamburgerIcon } from "components/hamburgerIcon/HamburgerIcon";
import { BreakpointI, isMobile } from "customHooks/useBreakpoint";
import { SidebarMobileModal } from "containers/sidebarMobileModal/SidebarMobileModal";
import { history } from "util/history";
import Button from "components/button/Button";
import {
  PageHeaderStylesComponent,
  PageHeaderMiddleSection,
  PageHeaderRightSection,
} from "./PageHeaderStyles";

export interface PageHeaderI extends BreakpointI {
  isDarkMode: boolean;
  type?: "popup";
}

export const PageHeader = ({
  isDarkMode,
  breakpoint,
  type,
  ...props
}: PageHeaderI) => {
  const [displaySidebarMobile, setDisplaySidebarMobile] = useState(false);
  const toggleSidebarMobile = () => {
    setDisplaySidebarMobile(!displaySidebarMobile);
  };
  useEffect(() => {
    const historyListener = history.listen(() => {
      if (displaySidebarMobile) {
        setDisplaySidebarMobile(false);
      }
    });
    return () => {
      historyListener();
    };
  });

  return (
    <>
      <PageHeaderStylesComponent
        breakpoint={breakpoint}
        isDarkMode={isDarkMode}
        id="PageHeader"
        type={type}
        {...props}
      >
        <PageHeaderMiddleSection breakpoint={breakpoint}>
          <Link to="/">
            <img
              src={isDarkMode ? LogoOnDark : LogoOnLight}
              alt="Capgemini Logo"
            />
          </Link>
        </PageHeaderMiddleSection>
        <PageHeaderRightSection breakpoint={breakpoint}>
          {isMobile(breakpoint) ? (
            <HamburgerIcon
              background={isDarkMode ? "onDark" : "onLight"}
              onClick={toggleSidebarMobile}
            />
          ) : (
            <>
              <Link to="/capgemini">
                <Button
                  buttonType="secondaryLight"
                  label="About Capgemini"
                  size="medium"
                />
              </Link>
              <Link to="/agenda">
                <Button
                  buttonType="primaryLight"
                  label="Your Visit"
                  size="medium"
                />
              </Link>
            </>
          )}
        </PageHeaderRightSection>
      </PageHeaderStylesComponent>
      {isMobile(breakpoint) && (
        <SidebarMobileModal
          receivedFunc={toggleSidebarMobile}
          isVisible={displaySidebarMobile}
        />
      )}
    </>
  );
};

export default PageHeader;
