import styled from "styled-components";
import { BreakpointI } from "customHooks/useBreakpoint";

export const ModalHeaderHeading = styled.h2`
  font: inherit;
  font-size: 2rem;
  color: var(--primary-colors-6-capgemini-deep-purple);
  font-weight: 500;
`;

export const ModalContent = styled("div")<BreakpointI>`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 75%;
  overflow-y: scroll;

  section {
    width: 100%;
  }
`;

export const ModalNavigationWrapper = styled.div`
  width: 25%;
  position: sticky;
`;

export const ModalHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 25%;
`;

export const WhySSCModalWrapper = styled.div`
  height: 90%;
  display: flex;
`;
