import styled from "styled-components";
import { PlusInCircleButtonI } from "./PlusInCircleButton";

export const PlusInCircleButtonWrapper = styled("button")<PlusInCircleButtonI>`
  background-color: transparent;
  border: 0;
  padding: 0;
  width: 3rem;
  height: 3rem;
  & svg {
    filter: invert(30%) sepia(87%) saturate(1654%) hue-rotate(178deg)
      brightness(83%) contrast(103%);
    &:hover {
      filter: invert(57%) sepia(85%) saturate(3016%) hue-rotate(158deg)
        brightness(98%) contrast(86%);
      cursor: pointer;
    }
  }

  ${(props) =>
    props.isDisabled &&
    `
    & svg {
      filter: invert(100%) sepia(6%) saturate(82%) hue-rotate(39deg) brightness(114%) contrast(85%);
    &:hover {
      filter: invert(100%) sepia(6%) saturate(82%) hue-rotate(39deg) brightness(114%) contrast(85%);
      cursor:default;
    }}
  `}
`;
