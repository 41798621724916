import NotFoundBox from "containers/notFoundBox/NotFoundBox";
import React from "react";
import { StyledPageNotFound } from "./PageNotFoundStyles";

const PageNotFound = () => (
    <StyledPageNotFound>
      <NotFoundBox />
    </StyledPageNotFound>
  );

export default PageNotFound;
