import React from "react";
import { Number, NumberTitle, NumberValue } from "./ModalNumberStyles";

export interface ModalNumberI {
  numberValue: string;
  numberTitle: string;
}

const ModalNumber = ({ numberValue, numberTitle }: ModalNumberI) => (
  <Number>
    <NumberTitle>{numberTitle}</NumberTitle>
    <NumberValue>{numberValue}</NumberValue>
  </Number>
);

export default ModalNumber;
