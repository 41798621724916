import React from "react";

import {
  BuildingContentContainer,
  AddressContainer,
  BuildingHeadingText,
  ReceptionDeskText,
  AddressText,
  BuldingInfoContainer,
} from "./buildingContentStyles";

export interface BuildingContentI {
  image: string;
  building: string;
  buildingDesk: string;
  buildingName: string;
  buildingStreet: string;
  buildingCity: string;
}

export const BuildingContent = ({
  image,
  building,
  buildingDesk,
  buildingName,
  buildingStreet,
  buildingCity,
}: BuildingContentI) => (
  <BuildingContentContainer>
    <img src={image} alt="building" />
    <AddressContainer>
      <BuildingHeadingText>{building}</BuildingHeadingText>
      <ReceptionDeskText>{buildingDesk}</ReceptionDeskText>
      <AddressText>Address:</AddressText>
      <BuldingInfoContainer>
        <span>{buildingName}</span>
        <span>{buildingStreet}</span>
        <span>{buildingCity}</span>
      </BuldingInfoContainer>
    </AddressContainer>
  </BuildingContentContainer>
);
