import React, { useEffect, useRef, useState, useContext } from "react";
import { AboutCapgeminiTopText } from "components/aboutCapgeminiTopText/AboutCapgeminiTopText";
import { SidepageTopNavigation } from "components/sidepageTopNavigation/SidepageTopNavigation";
import { AboutCapgeminiStatistics } from "components/aboutCapgeminiStatistics/AboutCapgeminiStatistics";
import { AboutCapgeminiSecondHeading } from "components/aboutCapgeminiTopText/AboutCapgeminiTopTextStyles";
import ContentLoader from "components/contentLoader/ContentLoader";
import { AboutCapgeminiServices } from "containers/aboutCapgeminiServices/AboutCapgeminiServices";
import { BackToTopButton } from "containers/backToTopButtton/BackToTopButton";
import { aboutCapgeminiBackup } from "assets/backup/aboutCapgeminiBackup";
import { navigationSetup } from "util/navigationSetup/navigationSetup";
import useFetch from "customHooks/useFetch";
import { AppContext } from "App";
import { AboutCapgeminiContainer } from "./AboutCapgeminiStyles";

const AboutCapgemini = () => {
  const ContainerRef = useRef<HTMLDivElement>(null);

  const [containerRefReady, setContainerRefReady] = useState(false);
  const AboutCapgeminiData: any = useFetch(
    "/about-capgemini",
    {},
    aboutCapgeminiBackup
  ).response;

  const AboutCapgeminiServicesData = {
    tile_1_title: AboutCapgeminiData.tile_1_title,
    tile_1_content: AboutCapgeminiData.tile_1_content,
    tile_2_title: AboutCapgeminiData.tile_2_title,
    tile_2_content: AboutCapgeminiData.tile_2_content,
    tile_3_title: AboutCapgeminiData.tile_3_title,
    tile_3_content: AboutCapgeminiData.tile_3_content,
    tile_4_title: AboutCapgeminiData.tile_4_title,
    tile_4_content: AboutCapgeminiData.tile_4_content,
  };

  const AboutCapgeminiStatisticsData = {
    stat_1_title: AboutCapgeminiData.stat_1_title,
    stat_1_content: AboutCapgeminiData.stat_1_content,
    stat_2_title: AboutCapgeminiData.stat_2_title,
    stat_2_content: AboutCapgeminiData.stat_2_content,
    stat_3_title: AboutCapgeminiData.stat_3_title,
    stat_3_content: AboutCapgeminiData.stat_3_content,
    stat_4_title: AboutCapgeminiData.stat_4_title,
    stat_4_content: AboutCapgeminiData.stat_4_content,
    stat_5_title: AboutCapgeminiData.stat_5_title,
    stat_5_content: AboutCapgeminiData.stat_5_content,
  };
  useEffect(() => {
    navigationSetup();
    setContainerRefReady(true);
  }, []);
  const { breakpoint } = useContext(AppContext);
  return (
    <AboutCapgeminiContainer ref={ContainerRef}>
      {Object.keys(AboutCapgeminiData).length > 0 ? (
        <>
          <section>
            <SidepageTopNavigation breakpoint={breakpoint} />
            <AboutCapgeminiTopText
              contentText={AboutCapgeminiData.content}
              subtitleText={AboutCapgeminiData.subtitle}
            />
          </section>
          <section>
            <AboutCapgeminiServices
              aboutServicesData={AboutCapgeminiServicesData}
            />
          </section>
          <section>
            <AboutCapgeminiSecondHeading>
              Poland in Numbers
            </AboutCapgeminiSecondHeading>
            <AboutCapgeminiStatistics
              aboutStatisticsData={AboutCapgeminiStatisticsData}
            />
          </section>
          {containerRefReady && ContainerRef && (
            <BackToTopButton
              elementRef={ContainerRef.current}
              isDarkMode={false}
              type="popup"
            />
          )}
        </>
      ) : (
        <>
          <SidepageTopNavigation breakpoint={breakpoint} />
          <ContentLoader />
        </>
      )}
    </AboutCapgeminiContainer>
  );
};

export default AboutCapgemini;
