import { combineReducers } from "redux";
import exampleReducer from "./exampleReducer";
import muteReducer from "./muteReducer";
import navigationReducer from "./navigationReducer";

export default combineReducers({
  exampleReducer,
  muteReducer,
  navigationReducer,
});

export interface storeStateI {
  exampleReducer: {};
  muteReducer: {
    mute: boolean;
  };
  navigationReducer: {
    currentPage: string;
    previousPage: string;
    currentPageIndex: number;
    previousPageIndex: number;
  };
}
