import { useState, useEffect } from "react";
import throttle from "lodash.throttle";

export const breakpoints = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1400
}

const getDeviceConfig = (width: number) => {
  if (width < breakpoints.xs) return "xs";
  if (width >= breakpoints.xs && width < breakpoints.sm) return "sm";
  if (width >= breakpoints.sm && width < breakpoints.md) return "md";
  if (width >= breakpoints.md && width < breakpoints.lg) return "lg";
  if (width >= breakpoints.lg && width < breakpoints.xl) return "xl";
  return "xxl";
};

export const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() =>
    getDeviceConfig(window.innerWidth)
  );

  useEffect(() => {
    const calcInnerWidth = throttle(() => {
      setBrkPnt(getDeviceConfig(window.innerWidth));
    }, 200);
    window.addEventListener("resize", calcInnerWidth);
    return () => window.removeEventListener("resize", calcInnerWidth);
  }, []);

  return brkPnt;
};

export interface BreakpointI {
  breakpoint: string;
}

export const isMobile = (breakpoint?: string) => {
  if (breakpoint) {
    return !!["xs", "sm", "md"].includes(breakpoint);
  }
  return window.innerWidth < breakpoints.md;
};


export interface customUniqueI  { // Dla unikatowych/szczegolnych przypadkow, spoza predefiniowanych resów
  customWidth?: number;
  customHeight?: number;
}
export const customUnique = ({customWidth: cW, customHeight: cH}: customUniqueI) => {
  if(cW && cH)
    return window.innerWidth < cW && window.innerHeight < cH;

  if(cH && !cW)  
    return window.innerHeight < cH;

  if(!cH && cW)
    return window.innerWidth < cW;
  
  throw new Error('While using customUnique, at least one of those: {customHeight, customWidth} should be defined');
}; 
