import { breakpoints } from "customHooks/useBreakpoint";
import styled from "styled-components";

export const StyledNotFoundBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    width: 180px;
    display: block;
    margin: auto;
  }
`;

export const TitleError = styled.h1`
  font-family: Ubuntu;
  font-size: 240px;
  font-weight: bold;
  letter-spacing: -8px;
  text-align: center;
  color: var(--primary-colors-3-capgemini-light-gray);
  margin: 8px 0 0 0;

  @media(max-width: ${breakpoints.md}px) {
      font-size: 160px;
  }
`;

export const SubtitleError = styled.h4`
  font-family: Ubuntu;
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  color: var(--primary-colors-6-capgemini-deep-purple);
  text-transform: uppercase;
  margin: 0 0 12px 0;
`;

export const ContentError = styled.h5`
  font-family: Ubuntu;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: var(--primary-colors-5-capgemini-dark-grey);
  margin: 0 0 56px 0;
  font-weight: 450;

  @media (max-width: ${breakpoints.md}px) {
    margin-bottom: 32px;
  }
`;

export const ErrorButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  white-space: nowrap;

  @media (max-width: ${breakpoints.md}px) {
    flex-direction: column;
    button {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
  }
  }
`;
