import { breakpoints } from "customHooks/useBreakpoint";
import styled from "styled-components";

export const ModalPage = styled.div<{ breakpoint: string }>`
  margin-bottom: 0.1rem;
  display: flex;
  &:first-child {
    margin-bottom: 0;
  }
  @media (max-width: ${breakpoints.md}px) {
    min-height: 90%;
  }
  width: 100%;
`;

export const SectionTitle = styled.h2`
  font-size: 1.5rem;
  margin-top: 1.25rem;
  font-weight: 300;
  color: var(--primary-colors-1-capgemini-blue);
`;

export const WhatMakesUsStandOutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  width: 95%;
  @media (min-width: ${breakpoints.xs}px) {
    width: 100%;
  }
  @media (min-width: ${breakpoints.lg}px) {
    width: 78%;
  }
`;

export const BoxesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 1.2rem;
  
  @media (min-width: ${breakpoints.xs}px) {
    display: grid;
    grid-row-gap: 1.6rem;
    grid-column-gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  @media (min-width: ${breakpoints.sm}px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
`;

export const GetTheFutureLinkWrapper = styled.div`
  width: 78%;
  text-align: center;
  font-size: 1rem;
  color: var(--primary-colors-5-capgemini-dark-grey);
  margin-top: 1rem;

  line-height: 1.5;

    & a {
      font-weight: 500;
      text-decoration: none;
      color: var(--primary-colors-2-capgemini-vibrant-blue);

      :hover {
        text-decoration: underline;
      }
    }

    @media (max-width: ${breakpoints.lg}px) {
    width: 100%;
    padding: 1rem 1rem 1rem 1rem;
  }
`;