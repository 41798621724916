import React, { FC } from "react";
import { Route, Switch } from "react-router-dom";

import Welcome from "pages/Welcome";
import AboutNSC from "pages/AboutNSC";
import WhySSC from "pages/WhySSC";
import Wroclaw from "pages/Wroclaw/Wroclaw";
import Poznan from "pages/Poznan";
import PageNotFound from "pages/PageNotFound";
import AboutCapgemini from "pages/AboutCapgemini";
import Agenda from "pages/Agenda";

export const Routes: FC = () => (
  <Switch>
    <Route exact path="/" component={Welcome} />
    <Route exact path="/aboutssc" component={AboutNSC} />
    <Route exact path="/whyssc" component={WhySSC} />
    <Route exact path="/wroclaw" component={Wroclaw} />
    <Route exact path="/poznan" component={Poznan} />
    <Route exact path="/capgemini" component={AboutCapgemini} />
    <Route exact path="/agenda" component={Agenda} />
    <Route exact path="/agenda/:token" render={() => <Agenda />} />
    <Route component={PageNotFound} />
  </Switch>
);
