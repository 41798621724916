import React from "react";

import { StyledPlusButton, StyledPlusButtonChild } from "./PlusButtonStyles";

export interface PlusButtonI {
  childComponent?: React.ReactNode;
  showChildComponent: boolean;
  receivedFunc?: () => void;
}

export const PlusButton = ({ ...props }: PlusButtonI) => {
  const { childComponent, showChildComponent } = props;
  const runReceivedFunc = () => {
    if (!showChildComponent) {
      if (props.receivedFunc) {
        props.receivedFunc();
      }
    }
  };
  return (
    <StyledPlusButton
      onClick={() => runReceivedFunc()}
      onTouchStart={() => runReceivedFunc()}
    >
      &#43;
      <StyledPlusButtonChild
        className={
          "plusButton__childContainer" && showChildComponent
            ? "visible"
            : "hidden"
        }
      >
        {childComponent}
      </StyledPlusButtonChild>
    </StyledPlusButton>
  );
};

PlusButton.defaultProps = {
  childComponent: "",
};
