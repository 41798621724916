import React, {
  FC,
  useEffect,
  useState,
  useContext,
  createContext,
  useRef,
  RefObject,
} from "react";
import { AppContext } from "App";
import { MobileBackground } from "containers/mobileBackground/MobileBackground";
import Video from "containers/video/Video";
import Pois from "containers/pois/Pois";
import Hotels from "containers/hotels/Hotels";
import { RestaurantPopupDesktop } from "containers/RestaurantPopup/desktop/RestaurantPopup";
import { RestaurantPopupMobile } from "containers/RestaurantPopup/mobile/RestaurantPopup";
import { HotelPopupDesktop } from "containers/hotelPopup/desktop/HotelPopup";
import { HotelPopupMobile } from "containers/hotelPopup/mobile/HotelPopup";
import { PoiPopupDesktop } from "containers/PoiPopup/desktop/PoiPopup";
import { PoiPopupMobile } from "containers/PoiPopup/mobile/PoiPopup";
import ListOfPointsPopup from "containers/ListOfPointsPopup/ListOfPointsPopup";
import AirportPopup from "containers/airportPopup/AirportPopup";
import { CityPopup } from "containers/cityPopup/CityPopup";
import { autoScroll } from "util/autoScroll/autoScroll";
import { WroclawPopupDesktop as OfficePopupDesktop } from "containers/wroclawOfficePopup/desktop/WroclawPopup";
import { WroclawPopupMobile as OfficePopupMobile } from "containers/wroclawOfficePopup/mobile/WroclawPopup";
import { HeadingPageText } from "components/headingPageText/HeadingPageText";
import Popup from "components/popup/Popup";
import Button from "components/button/Button";
import { MapPoint } from "components/mapPoint/MapPoint";
import { MapFilter } from "components/mapFilter/MapFilter";
import { isMobile } from "customHooks/useBreakpoint";
import { navigationSetup } from "util/navigationSetup/navigationSetup";
import { resizeHandlerMultiple } from "util/handleResizeMultiple";
import handleResize from "util/handleResize";
import {
  mobilePositionPoisCluster,
  pointsOfInterestLocation,
} from "assets/consts/mapLocations/wroclaw/pointsOfInterest";
import { visibleCounter } from "util/visibleCounter";
import {
  hotelsLocation,
  mobilePositionHotelsCluster,
} from "assets/consts/mapLocations/wroclaw/hotels";
import Restaurants from "containers/restaurants/Restaurants";
import {
  mobilePositionRestaurantsCluster,
  restaurantsLocations,
} from "assets/consts/mapLocations/wroclaw/restaurants";
import {
  desktopPositionMarket,
  mobilePositionMarket,
} from "assets/consts/mapLocations/wroclaw/market";
import {
  desktopPositionAirport,
  mobilePositionAirport,
} from "assets/consts/mapLocations/wroclaw/airport";
import {
  desktopPositionOffice,
  mobilePositionOffice,
} from "assets/consts/mapLocations/wroclaw/office";
import { mapFilters } from "assets/consts/mapLocations/mapFilters";
import { WroclawFetchData } from "./WroclawFetchData";

export const WroclawContext = createContext({
  ScrolledDivRef: React.createRef() as RefObject<HTMLDivElement>, // mala litera
  setScrolledDivRef: Function as any,
  airportRef: React.createRef() as RefObject<HTMLDivElement>,
  officeRef: React.createRef() as RefObject<HTMLDivElement>,
  hotelRef: React.createRef() as RefObject<HTMLDivElement>,
  attractionRef: React.createRef() as RefObject<HTMLDivElement>,
  restaurantRef: React.createRef() as RefObject<HTMLDivElement>,
});

const Wroclaw: FC = () => {
  const [displayOfficePopup, setDisplayOfficePopup] = useState(false);
  const [displayAirportPopup, setDisplayAirportPopup] = useState(false);
  const [displayCityPopup, setDisplayCityPopup] = useState(true);
  const [displayHotelPopup, setDisplayHotelPopup] = useState(false);
  const [displayPoiPopup, setDisplayPoiPopup] = useState(false);
  const [displayRestaurantPopup, setDisplayRestaurantPopup] = useState(false);

  const [displayListOfPointsPopup, setDisplayListOfPointsPopup] = useState(
    false
  );

  const [desiredPointWidthOffice, setDesiredPointWidthOffice] = useState("0%");
  const [desiredPointHeightOffice, setDesiredPointHeightOffice] = useState(
    "0%"
  );
  const [desiredPointWidthAirport, setDesiredPointWidthAirport] = useState(
    "0%"
  );
  const [desiredPointHeightAirport, setDesiredPointHeightAirport] = useState(
    "0%"
  );
  const [desiredPointWidthMarket, setDesiredPointWidthMarket] = useState("0%");
  const [desiredPointHeightMarket, setDesiredPointHeightMarket] = useState(
    "0%"
  );
  const [
    desiredPointWidthHotelsCluster,
    setDesiredPointWidthHotelsCluster,
  ] = useState("0%");
  const [
    desiredPointHeightHotelsCluster,
    setDesiredPointHeightHotelsCluster,
  ] = useState("0%");
  const [
    desiredPointWidthRestaurantsCluster,
    setDesiredPointWidthRestaurantsCluster,
  ] = useState("0%");
  const [
    desiredPointHeightRestaurantsCluster,
    setDesiredPointHeightRestaurantsCluster,
  ] = useState("0%");
  const [
    desiredPointWidthPoisCluster,
    setDesiredPointWidthPoisCluster,
  ] = useState("0%");
  const [
    desiredPointHeightPoisCluster,
    setDesiredPointHeightPoisCluster,
  ] = useState("0%");

  const [displayPreloader, setDisplayPreloader] = useState(true);
  const [scrollEnable, setScrollEnable] = useState(false);

  const [hotelPopupData, setHotelPopupData] = useState({} as any);
  const [poiPopupData, setPoiPopupData] = useState({} as any);
  const [restaurantPopupData, setRestaurantPopupData] = useState({} as any);

  const [hotelLocations, setHotelLocations] = useState(hotelsLocation);
  const [poiLocations, setPoiLocations] = useState(pointsOfInterestLocation);
  const [restaurantLocations, setRestaurantLocations] = useState(
    restaurantsLocations
  );

  const [filterElements, setFilterElements] = useState(mapFilters);
  const [areAnyFiltersOn, setAreAnyFiltersOn] = useState(true);

  const {
    officeData,
    hotelsData,
    cityData,
    airportData,
    attractionData,
    restaurantData,
  } = WroclawFetchData();
  const toggleOfficePopup = () => setDisplayOfficePopup(!displayOfficePopup);
  const toggleAirportPopup = () => setDisplayAirportPopup(!displayAirportPopup);
  const toggleHotelPopup = () => setDisplayHotelPopup(!displayHotelPopup);
  const togglePoiPopup = () => setDisplayPoiPopup(!displayPoiPopup);
  const toggleRestaurantPopup = () =>
    setDisplayRestaurantPopup(!displayRestaurantPopup);

  const calculatePosition = handleResize([
    {
      mobilePosition: mobilePositionOffice,
      desktopPosition: desktopPositionOffice,
      setDesiredPointWidth: setDesiredPointWidthOffice,
      setDesiredPointHeight: setDesiredPointHeightOffice,
    },

    {
      mobilePosition: mobilePositionMarket,
      desktopPosition: desktopPositionMarket,
      setDesiredPointWidth: setDesiredPointWidthMarket,
      setDesiredPointHeight: setDesiredPointHeightMarket,
    },

    {
      mobilePosition: mobilePositionAirport,
      desktopPosition: desktopPositionAirport,
      setDesiredPointWidth: setDesiredPointWidthAirport,
      setDesiredPointHeight: setDesiredPointHeightAirport,
    },

    {
      mobilePosition: mobilePositionHotelsCluster,
      desktopPosition: mobilePositionHotelsCluster,
      setDesiredPointWidth: setDesiredPointWidthHotelsCluster,
      setDesiredPointHeight: setDesiredPointHeightHotelsCluster,
    },

    {
      mobilePosition: mobilePositionRestaurantsCluster,
      desktopPosition: mobilePositionRestaurantsCluster,
      setDesiredPointWidth: setDesiredPointWidthRestaurantsCluster,
      setDesiredPointHeight: setDesiredPointHeightRestaurantsCluster,
    },

    {
      mobilePosition: mobilePositionPoisCluster,
      desktopPosition: mobilePositionPoisCluster,
      setDesiredPointWidth: setDesiredPointWidthPoisCluster,
      setDesiredPointHeight: setDesiredPointHeightPoisCluster,
    },
  ]);

  const labelChecker = () => {
    const visArray = [
      arePointsDisplayed("office"),
      arePointsDisplayed("airport"),
      arePointsDisplayed("market"),
      arePointsDisplayed("attraction"),
      arePointsDisplayed("hotel"),
      arePointsDisplayed("restaurant"),
    ];

    let sum = 0;

    for (const x of visArray) {
      if (x) sum += 1;
    }

    if (sum === 0) return "First select points";
    if (sum === 1) {
      if (arePointsDisplayed("office")) return "Read more about Our Office";
      if (arePointsDisplayed("airport")) return "Read more about Airport";
    }
    return "See the list of points";
  };

  const mapButtonClickHandler = (label: string) => {
    if (/more about/.test(label)) {
      mainPane.current.scroll({ top: window.innerHeight * 2, behavior: "smooth" });
    }
    if (/list of/.test(label)) {
      setDisplayListOfPointsPopup(true);
    }
  };

  const onFilterChange = (name: string) => {
    const res = filterElements.map((el) => {
      const elCopy = el;
      if (elCopy.name === name) elCopy.active = !elCopy.active;
      return elCopy;
    });
    setFilterElements(res);
    const isAnyElActive = res.some((el) => el.active);
    setAreAnyFiltersOn(isAnyElActive);
  };

  const activateAllFilters = () => {
    const res = filterElements.map(({ active, ...el }) => ({
      ...el,
      active: true,
    }));
    setFilterElements(res as any);
    setAreAnyFiltersOn(true);
  };

  const deactivateAllFilters = () => {
    const res = filterElements.map(({ active, ...el }) => ({
      ...el,
      active: false,
    }));
    setFilterElements(res as any);
    setAreAnyFiltersOn(false);
  };

  const arePointsDisplayed = (elName: string) => {
    const res = filterElements.find((el) => el.name === elName);
    return res?.active;
  };

  const resizeGatherHandler = () => {
    calculatePosition();
    resizeHandlerMultiple(hotelLocations, setHotelLocations);
    resizeHandlerMultiple(poiLocations, setPoiLocations);
    resizeHandlerMultiple(restaurantLocations, setRestaurantLocations);
  };

  const { mainPane, breakpoint } = useContext(AppContext);

  useEffect(() => {
    navigationSetup();
    calculatePosition();
    window.addEventListener("resize", resizeGatherHandler);
    setTimeout(() => setDisplayPreloader(false), 2000);
    return () => {
      window.removeEventListener("resize", calculatePosition);
    };
  }, [desiredPointHeightOffice, desiredPointWidthOffice]);

  useEffect(() => {
    resizeHandlerMultiple(hotelLocations, setHotelLocations);
    resizeHandlerMultiple(poiLocations, setPoiLocations);
    resizeHandlerMultiple(restaurantLocations, setRestaurantLocations);
  }, []);

  useEffect(() => {
    const tout = { func: setTimeout(() => {}, 0) };
    const wrap = () => {
      autoScroll(mainPane, tout);
    };
    if (scrollEnable) {
      const mp = mainPane.current;
      mp.addEventListener("scroll", wrap, true);

      return () => {
        mainPane.current.removeEventListener("scroll", wrap, true);
      };
    }
    return () => {};
  }, [scrollEnable]);

  const [ScrolledDivRef, setScrolledDivRef] = useState(
    useRef<HTMLDivElement>(null)
  );
  const airportRef = useRef<HTMLDivElement>(null);
  const attractionRef = useRef<HTMLDivElement>(null);
  const hotelRef = useRef<HTMLDivElement>(null);
  const restaurantRef = useRef<HTMLDivElement>(null);
  const officeRef = useRef<HTMLDivElement>(null);

  const desktopView = (
    <Video>
      {displayOfficePopup && (
        <OfficePopupDesktop
          popupData={officeData}
          togglePopup={toggleOfficePopup}
          breakpoint={breakpoint}
        />
      )}
      {displayAirportPopup && (
        <AirportPopup
          setPopupState={setDisplayAirportPopup}
          popupData={airportData[0]}
          breakpoint={breakpoint}
        />
      )}
      {displayCityPopup && (
        <CityPopup
          setPopupState={setDisplayCityPopup}
          popupData={cityData}
          breakpoint={breakpoint}
          additionalCallback={setScrollEnable}
        />
      )}
      {displayHotelPopup && (
        <HotelPopupDesktop
          setPopupState={setDisplayHotelPopup}
          popupData={hotelPopupData}
          breakpoint={breakpoint}
        />
      )}
      {displayPoiPopup && (
        <PoiPopupDesktop
          setPopupState={setDisplayPoiPopup}
          popupData={poiPopupData}
          breakpoint={breakpoint}
        />
      )}
      {displayRestaurantPopup && (
        <RestaurantPopupDesktop
          setPopupState={setDisplayRestaurantPopup}
          popupData={restaurantPopupData}
          breakpoint={breakpoint}
        />
      )}
      {arePointsDisplayed("office") && (
        <MapPoint
          label="Our offices"
          leftMargin={desiredPointWidthOffice}
          topMargin={desiredPointHeightOffice}
          onClick={toggleOfficePopup}
          size="large"
          pointColor="vibrantBlue"
        />
      )}
      {arePointsDisplayed("airport") && (
        <MapPoint
          label="Airport"
          leftMargin={desiredPointWidthAirport}
          topMargin={desiredPointHeightAirport}
          onClick={toggleAirportPopup}
          pointColor="green"
        />
      )}
      {arePointsDisplayed("market") && (
        <MapPoint
          label="Market"
          leftMargin={desiredPointWidthMarket}
          topMargin={desiredPointHeightMarket}
          hidePlusInButton
          onClick={() => {}}
          pointColor="peacock"
        />
      )}
      {attractionData.length && arePointsDisplayed("attraction") && (
        <Pois
          poi={poiLocations}
          api={attractionData}
          setPopupData={setPoiPopupData}
          onPointClick={togglePoiPopup}
          pointColor="sapphire"
        />
      )}
      {hotelsData.length && arePointsDisplayed("hotel") && (
        <Hotels
          poi={hotelLocations}
          api={hotelsData}
          setPopupData={setHotelPopupData}
          onPointClick={toggleHotelPopup}
          pointColor="teal"
        />
      )}
      {restaurantData.length && arePointsDisplayed("restaurant") && (
        <Restaurants
          poi={restaurantLocations}
          api={restaurantData}
          setPopupData={setRestaurantPopupData}
          onPointClick={toggleRestaurantPopup}
          pointColor="deepPurple"
        />
      )}
      <Button
        buttonType="secondaryLight"
        label="CLICK ON THE POINT"
        borderRadius={2}
        borderColor="var(--primary-colors-7-capgemini-white)"
        isClickPoint
        size="small"
      />
      <MapFilter
        elements={filterElements}
        onFilterChange={onFilterChange}
        onFilterAllOff={deactivateAllFilters}
        onFilterAllOn={activateAllFilters}
        verticalLabel="show on the map"
      />
    </Video>
  );

  const mobileView = (
    <WroclawContext.Provider
      value={{
        ScrolledDivRef,
        setScrolledDivRef,
        airportRef,
        attractionRef,
        hotelRef,
        officeRef,
        restaurantRef,
      }}
    >
      <MobileBackground isPreloaderVisible={displayPreloader}>
        <HeadingPageText text="WROCŁAW" />
        {displayListOfPointsPopup && (
          <ListOfPointsPopup
            closeClick={() => {
              setDisplayListOfPointsPopup(false);
            }}
            listOfPoints={[
              {
                array: airportData,
                isFiltered: arePointsDisplayed("airport"),
                sectionRef: airportRef,
              },
              {
                array: [{ ...officeData, name: "Our office" }],
                isFiltered: arePointsDisplayed("office"),
                sectionRef: officeRef,
              },
              {
                array: hotelsData,
                isFiltered: arePointsDisplayed("hotel"),
                sectionRef: hotelRef,
              },
              {
                array: attractionData,
                isFiltered: arePointsDisplayed("attraction"),
                sectionRef: attractionRef,
              },
              {
                array: restaurantData,
                isFiltered: arePointsDisplayed("restaurant"),
                sectionRef: restaurantRef,
              },
            ]}
          />
        )}
        {officeData && arePointsDisplayed("office") && (
          <MapPoint
            leftMargin={desiredPointWidthOffice}
            topMargin={desiredPointHeightOffice}
            pointColor="vibrantBlue"
            size="large"
          />
        )}
        {airportData.length && arePointsDisplayed("airport") && (
          <MapPoint // Airport
            leftMargin={desiredPointWidthAirport}
            topMargin={desiredPointHeightAirport}
            pointColor="green"
          />
        )}
        {arePointsDisplayed("market") && (
          <MapPoint // Market
            leftMargin={desiredPointWidthMarket}
            topMargin={desiredPointHeightMarket}
            pointColor="peacock"
          />
        )}
        {hotelsData.length && arePointsDisplayed("hotel") && (
          <MapPoint // Hotels
            leftMargin={desiredPointWidthHotelsCluster}
            topMargin={desiredPointHeightHotelsCluster}
            pointColor="teal"
            opacity="50%"
            size="cluster"
            insideNumber={visibleCounter(hotelsData)}
          />
        )}

        {attractionData.length && arePointsDisplayed("attraction") && (
          <MapPoint // Pois
            leftMargin={desiredPointWidthPoisCluster}
            topMargin={desiredPointHeightPoisCluster}
            pointColor="sapphire"
            opacity="50%"
            size="cluster"
            insideNumber={11}
          />
        )}
        {restaurantData.length && arePointsDisplayed("restaurant") && (
          <MapPoint // Restaurants
            leftMargin={desiredPointWidthRestaurantsCluster}
            topMargin={desiredPointHeightRestaurantsCluster}
            pointColor="deepPurple"
            opacity="50%"
            size="cluster"
            insideNumber={visibleCounter(restaurantData)}
          />
        )}

        {displayCityPopup && (
          <CityPopup
            popupData={cityData}
            setPopupState={setDisplayCityPopup}
            breakpoint={breakpoint}
            additionalCallback={setScrollEnable}
          />
        )}
        <Button
          buttonType="secondaryLight"
          label={labelChecker()}
          isClickPoint
          size="small"
          isMapButton
          onClick={() => {
            mapButtonClickHandler(labelChecker());
          }}
        />
        <MapFilter
          elements={filterElements}
          onFilterChange={onFilterChange}
          onFilterAllOff={deactivateAllFilters}
          onFilterAllOn={activateAllFilters}
          verticalLabel="show on the map"
        />
      </MobileBackground>

      {areAnyFiltersOn && !displayCityPopup && (
        <Popup
          breakpoint={breakpoint}
          displayHeaders
          isMultiPopup
          mainPane={mainPane}
          setScrolledDivRef={setScrolledDivRef}
        >
          {airportData.length && arePointsDisplayed("airport") && (
            <AirportPopup
              setPopupState={setDisplayAirportPopup}
              popupData={airportData[0]}
              breakpoint={breakpoint}
            />
          )}

          {officeData && arePointsDisplayed("office") && (
            <OfficePopupMobile
              popupData={officeData}
              togglePopup={toggleOfficePopup}
              breakpoint={breakpoint}
            />
          )}

          {hotelsData && arePointsDisplayed("hotel") && (
            <HotelPopupMobile hotelsData={hotelsData} breakpoint={breakpoint} />
          )}

          {restaurantData && arePointsDisplayed("restaurant") && (
            <RestaurantPopupMobile
              restaurantsData={restaurantData}
              breakpoint={breakpoint}
            />
          )}

          {attractionData && arePointsDisplayed("attraction") && (
            <PoiPopupMobile poiData={attractionData} breakpoint={breakpoint} />
          )}
        </Popup>
      )}
    </WroclawContext.Provider>
  );
  return isMobile(breakpoint) ? mobileView : desktopView;
};

export default Wroclaw;
