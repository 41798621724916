import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as PlusIcon } from "assets/icon-navi-plus-black.svg";
import { ReactComponent as MinusIcon } from "assets/icon-navi-minus-black.svg";
import { PlusInCircleButtonWrapper } from "./PlusInCircleButtonStyles";

export interface PlusInCircleButtonI {
  isDisabled: boolean;
  isOpen: boolean;
  onClick?: () => void;
}

export const PlusInCircleButton = ({
  isDisabled = false,
  isOpen = false,
  ...props
}) => {
  const isElementOpen = isDisabled ? false : isOpen;
  return (
    <PlusInCircleButtonWrapper
      isDisabled={isDisabled}
      isOpen={isElementOpen}
      {...props}
    >
      {isElementOpen ? <MinusIcon /> : <PlusIcon />}
    </PlusInCircleButtonWrapper>
  );
};

PlusInCircleButton.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
