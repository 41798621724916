import Loader from "react-loader-spinner";
import React from "react";
import { LoaderWrapper } from "./ContentLoaderStyles";

export interface ContentLoaderI {
  isDarkMode?: boolean;
}

const ContentLoader = ({ isDarkMode = false }: ContentLoaderI) => (
  <LoaderWrapper>
    <Loader
      type="Oval"
      color={
        isDarkMode
          ? "var(--primary-colors-7-capgemini-white)"
          : "var(--primary-colors-1-capgemini-blue)"
      }
      height={80}
      width={80}
    />
  </LoaderWrapper>
);

export default ContentLoader;
