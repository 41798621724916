const nationalForumMusic = {
  left: 110,
  top: 33,
  desiredLeft: "",
  desiredTop: "",
  id_name: "forum_of_music",
};

const fourDomesPavilion = {
  left: 227,
  top: 30,
  desiredLeft: "",
  desiredTop: "",
  id_name: "four_domes",
};

const nationalMuseum = {
  left: 170,
  top: 25,
  desiredLeft: "",
  desiredTop: "",
  id_name: "national_museum",
};

const panoramaRaclawice = {
  left: 163,
  top: 38,
  desiredLeft: "",
  desiredTop: "",
  id_name: "panorama_raclawice",
};

const opEnheim = {
  left: 117,
  top: 23,
  desiredLeft: "",
  desiredTop: "",
  id_name: "openheim",
};

const vividGallery = {
  left: 125,
  top: 42,
  desiredLeft: "",
  desiredTop: "",
  id_name: "vivid_gallery",
}

const museumArcheology = {
  left: 100,
  top: 14,
  desiredLeft: "",
  desiredTop: "",
  id_name: "museum_of_archeology",
};

const dizajnGallery = {
  left: 127,
  top: 28,
  desiredLeft: "",
  desiredTop: "",
  id_name: "dizajn_gallery",
};

const studioGallery = {
  left: 105,
  top: 20,
  desiredLeft: "",
  desiredTop: "",
  id_name: "studio_gallery",
};

const fourDenominations = {
  left: 100,
  top: 24,
  desiredLeft: "",
  desiredTop: "",
  id_name: "four_denomination_district",
};

const cathedralIsland = {
  left: 159,
  top: 9,
  desiredLeft: "",
  desiredTop: "",
  id_name: "cathedral_island",
};

export const pointsOfInterestLocation = [
  nationalForumMusic,
  fourDomesPavilion,
  nationalMuseum,
  panoramaRaclawice,
  opEnheim,
  vividGallery,
  museumArcheology,
  dizajnGallery,
  studioGallery,
  fourDenominations,
  cathedralIsland
];

export const mobilePositionPoisCluster = {
  width: 100,
  height: 240,
  backgroundWidth: 640,
  backgroundHeight: 1136,
};
