interface TextTruncateI {
  text: string;
  length?: number;
  breakWord?: boolean;
}

export const textTruncate = ({
  text,
  length = 20,
  breakWord = false,
}: TextTruncateI): string => {
  let res = "";
  if (text.length < length) {
    res = text;
  } else {
    if (breakWord) res = `${text.slice(0, length)}(...)`;
    if (!breakWord) {
      const truncatedText = text.slice(0, length);
      const lastSpaceIndex = truncatedText.lastIndexOf(" ");
      res = `${text.slice(0, lastSpaceIndex)}(...)`;
    }
  }
  return res;
};
