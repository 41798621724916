import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { storeStateI } from "store/reducers";
import { navigationSetup } from "util/navigationSetup/navigationSetup";
import { BreakpointI, isMobile } from "customHooks/useBreakpoint";
import {
  Minus,
  Plus,
  Dot,
  Line,
  SidebarItemContainer,
  SidebarItemStyled,
  SidebarItemShape,
  SidebarContainer,
  SidebarItemContent,
} from "./SidebarStyle";

const Sidebar = ({ breakpoint }: BreakpointI) => {
  const store = (state: storeStateI) => state;
  const storeState = useSelector(store);
  const { currentPageIndex } = storeState.navigationReducer;

  const addSelectedClass = (sidebarItemNumber: number) => {
    if (sidebarItemNumber === currentPageIndex) {
      return "activated current";
    }
    if (sidebarItemNumber < currentPageIndex) {
      return "activated";
    }
    return "";
  };

  interface SidebarItemI {
    text: string;
    navigationLink?: string;
    sidebarItemNumber: number;
  }
  const SidebarItem = ({
    text = "",
    navigationLink = "/",
    sidebarItemNumber = 0,
  }: SidebarItemI) => (
    <SidebarItemStyled
      className={addSelectedClass(sidebarItemNumber)}
      onClick={() =>
        currentPageIndex === sidebarItemNumber && window.location.reload()
      }
    >
      <Link to={navigationLink}>
        <SidebarItemContainer>
          <SidebarItemShape>
            <Line />
            <Dot />
          </SidebarItemShape>
          <SidebarItemContent>
            <p>{text}</p>
          </SidebarItemContent>
        </SidebarItemContainer>
      </Link>
    </SidebarItemStyled>
  );

  return (
    <SidebarContainer
      id={!isMobile(breakpoint) ? "sidebar" : ""}
      breakpoint={breakpoint}
    >
      {!isMobile(breakpoint) && (
        <Minus onClick={() => navigationSetup("back")}>&#8211;</Minus>
      )}

      <SidebarItem text="Welcome" sidebarItemNumber={0} navigationLink="/" />

      <SidebarItem
        text="About Software Center, Capgemini Poland"
        sidebarItemNumber={1}
        navigationLink="/aboutssc"
      />

      <SidebarItem
        text="Why Software Center, Capgemini Poland"
        sidebarItemNumber={2}
        navigationLink="/whyssc"
      />

      <SidebarItem
        text="Wrocław Office"
        sidebarItemNumber={3}
        navigationLink="/wroclaw"
      />

      <SidebarItem
        text="Poznań Office"
        sidebarItemNumber={4}
        navigationLink="/poznan"
      />

      {!isMobile(breakpoint) && (
        <Plus onClick={() => navigationSetup("next")}>&#43;</Plus>
      )}
    </SidebarContainer>
  );
};

export default Sidebar;
