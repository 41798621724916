import logo1 from "./01.webp";
import logo2 from "./02.webp";
import logo3 from "./03.webp";
import logo4 from "./04.webp";
import logo5 from "./05.webp";
import logo6 from "./06.webp";

export const whatMakesUsStandOutBackup = [
  {
    id: 1,
    location: 1,
    image: `${logo1}`,
    header: "Geographical & Cultural Proximity",
    content: "<p>makes the cooperation easier</p>",
  },
  {
    id: 2,
    location: 2,
    image: `${logo2}`,
    header: "German as a Project & Documentation Language",
    content:
      "<p>due to a very good German language skills of all NSC employees (if requested English)</p>​",
  },
  {
    id: 3,
    location: 3,
    image: `${logo3}`,
    header: "Proven Cooperation Models with Focus on Distributed Agile",
    content: "<p>high attention to quality and productivity</p>",
  },
  {
    id: 4,
    location: 4,
    image: `${logo4}`,
    header: "Tier 1 Talents & Excellent Education ",
    content: "<p>4000 ICT Graduates in Wrocław & Poznań</p>",
  },
  {
    id: 5,
    location: 5,
    image: `${logo5}`,
    header: "1600+ Full Stack Software Engineers",
    content:
      "<p>supported by Architects, Business Analysts, PM, UI/UX Designers</p>",
  },
  {
    id: 6,
    location: 6,
    image: `${logo6}`,
    header: "Strong Digital, Cloud & Devops Product Know-How",
    content: "<p>gained in over 15 years experience</p>",
  },
];
