import { MapPoint, MapPointI } from "components/mapPoint/MapPoint";
import React from "react";

interface HotelsI {
  api: Array<HotelsApiI>;
  poi: Array<HotelsPOII>;
  setPopupData: React.Dispatch<HotelsApiI>;
  onPointClick: Function;
  pointColor: string;
}

export interface HotelsApiI {
  id_name: string;
  is_visible: boolean;
  name: string;
  e_mail: string;
  phone_number: string;
  website_address: string;
  image: string;
  address: string;
  how_to_get_there?: string;
}

export interface HotelsPOII {
  left: number;
  top: number;
  desiredLeft: string;
  desiredTop: string;
  id_name: string;
  how_to_get_there?: string;
}

const Hotels = ({
  api,
  poi,
  setPopupData,
  onPointClick,
  pointColor,
}: HotelsI) => {
  const clickHandler = (hotelPopupData: HotelsApiI) =>
    setPopupData({ ...hotelPopupData });

  const getHotels = (
    poiH: Array<HotelsPOII>,
    apiH: Array<HotelsApiI>
  ): Array<any> => {
    const finalHotels = poiH.map(
      ({ desiredLeft, desiredTop, id_name, how_to_get_there }) => {
        for (let i = 0; i < apiH.length; i += 1) {
          if (apiH[i].id_name === id_name && apiH[i].is_visible) {
            if (how_to_get_there)
              return {
                label: apiH[i].name,
                leftMargin: desiredLeft,
                topMargin: desiredTop,
                clickEvent: () =>
                  clickHandler({ ...apiH[i], how_to_get_there }),
              };
          }
        }
        return null;
      }
    );
    return finalHotels.filter((finalHotel) => !!finalHotel);
  };

  return (
    <>
      {api.length &&
        getHotels(poi, api).map(
          ({ label, leftMargin, topMargin, clickEvent }) => (
            <MapPoint
              key={label}
              label={label}
              leftMargin={leftMargin}
              topMargin={topMargin}
              onClick={() => {
                clickEvent();
                onPointClick();
              }}
              pointColor={pointColor as MapPointI["pointColor"]}
            />
          )
        )}
    </>
  );
};

export default Hotels;
