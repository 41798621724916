import { breakpoints } from "customHooks/useBreakpoint";
import styled from "styled-components";

export const ModalBoxStyled = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 9rem;
  align-self: end;
  width: 100%;
  margin-top: 1.2rem;

  @media (min-width: ${breakpoints.xs}px) {
    width: auto;
    margin-top: auto;
  }
`;

export const ModalBoxTitle = styled.div`
  font-size: 1.125rem;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  align-items: center;
  color: var(--primary-colors-6-capgemini-deep-purple);
  height: 5.5rem;

  @media (min-width: ${breakpoints.xl}px){
    height: 3.5rem;
  }
`;

export const ModalBoxContent = styled.div`
  font-size: 0.9rem;
  text-align: center;
  color: var(--primary-colors-5-capgemini-dark-grey);
  height: 3.5rem;

  & > * {
    margin: 0;
  }
  
  @media (min-width: ${breakpoints.xs}px) {
    height: 4rem;
  }
`;

export const ModalBoxBreakLine = styled.hr`
  margin: 0.5rem auto;
  width: 1.5rem;
  height: 0.12rem;
  color: var(--primary-colors-1-capgemini-blue);
  background-color: var(--primary-colors-1-capgemini-blue);
  border-width: 0;
  opacity: 80%;
`;

export const ModalBoxImg = styled.img<{ src?: string }>`
  width: 60%;
  position: relative;
  top: -1.2rem;
  @media (min-width: ${breakpoints.xl}px) {
    width: 55%;
  }
`;

export const ContentRectangle = styled.div`
  border: solid 1px var(--primary-colors-3-capgemini-light-gray);
  background-color: var(--primary-colors-7-capgemini-white);
  padding: 0.5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--primary-colors-7-capgemini-white);
  min-height: 9rem;
  height: 100%;
`;

export const ModalText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
`;