import { updateObject } from "store/utility";
import { selectPageI } from "store/actions/navigationActions";
import { SELECT_PAGE } from "store/actions/types";

const initialState = {
  currentPage: "/",
  previousPage: "/",
  currentPageIndex: 0,
  previousPageIndex: 0,
};

const navigationReducer = (state = initialState, action: selectPageI) => {
  switch (action.type) {
    case SELECT_PAGE:
      return updateObject(state, {
        currentPage: action.currentPage,
        previousPage: action.previousPage,
        currentPageIndex: action.currentPageIndex,
        previousPageIndex: action.previousPageIndex,
      });
    default:
      return state;
  }
};

export default navigationReducer;
