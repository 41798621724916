import React from "react";
import { TeamSpirit, TeamSpiritData } from "containers/teamSpirit/TeamSpirit";
import { BreakpointI } from "customHooks/useBreakpoint";
import { ModalPage } from "./ModalPagesStyles";

interface TeamSpiritContentI extends BreakpointI {
  teamSpiritData: TeamSpiritData;
}

const TeamSpiritContent = ({ teamSpiritData, breakpoint }: TeamSpiritContentI) => (
  <ModalPage id="TeamSpirit" breakpoint={breakpoint}>
    <TeamSpirit teamSpiritData={teamSpiritData} breakpoint={breakpoint} />
  </ModalPage>
);
export default TeamSpiritContent;
