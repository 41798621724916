import styled from "styled-components";
import { Link } from "react-router-dom";
import { breakpoints } from "customHooks/useBreakpoint";
import { ImageI } from "./Image";

export const ImageContainer = styled(Link)`
  position: relative;
  width: 100%;
  margin-bottom: 0.8rem;
  :hover {
    filter: brightness(1.25);
  }
  @media (min-width: ${breakpoints.sm}px) {
    margin-bottom: 0;
    width: 49%;
  }
`;

export const ImagePhoto = styled.img<ImageI>`
  width: 100%;
  height: 100%;
  z-index: -1;
  ${(props) =>
    !props.active &&
    `
      filter: grayscale(100%);
      `};
`;

export const ImageContent = styled.div<ImageI>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${(props) =>
    props.active
      ? `background: rgba(0, 112, 173, 0.8)`
      : `
      background: rgba(256, 256, 256, 0.8);
      `};
`;

export const CompanyName = styled.p<ImageI>`
  margin: 0 0 1rem 0;
  z-index: 2;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 500;
  color: ${(props) =>
    props.active
      ? `var(--primary-colors-7-capgemini-white);`
      : `var(--primary-colors-4-capgemini-medium-grey)`};
`;
export const CityName = styled.h3<ImageI>`
  margin: 0;
  z-index: 2;
  font-weight: 300;
  font-size: 2rem;
  color: ${(props) =>
    props.active
      ? `var(--primary-colors-7-capgemini-white);`
      : `var(--primary-colors-4-capgemini-medium-grey)`};
`;
export const PlaceName = styled.p<ImageI>`
  margin: 0;
  z-index: 2;
  font-size: 1rem;
  color: ${(props) =>
    props.active
      ? `var(--primary-colors-7-capgemini-white);`
      : `var(--primary-colors-4-capgemini-medium-grey)`};
`;

export const ImageButton = styled("div")`
  text-decoration: none;
  bottom: 2rem;
  padding: 0.25rem 1rem;
  border-radius: 1.5rem;
  background: none;
  color: var(--primary-colors-7-capgemini-white);
  border: 2px solid var(--primary-colors-3-capgemini-light-gray);
  position: initial;
  margin-top: 1rem;
  @media (min-width: ${breakpoints.lg}px) {
    position: absolute;
  }
  @media (min-width: ${breakpoints.lg}px) and (max-width: ${breakpoints.xl}px) {
    bottom: 1.5rem;
  }
`;
