import styled from "styled-components";
import { LoaderWrapper } from "components/contentLoader/ContentLoaderStyles";
import { breakpoints } from "customHooks/useBreakpoint";
import { BackToTopButtonStyled } from "containers/backToTopButtton/BackToTopButtonStyles";

interface WelcomeContainerI {
  isDataLoading: boolean;
}

export const WelcomePopupContainer = styled.div<WelcomeContainerI>`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 62.5rem;
  height: 100%;
  min-height: -webkit-fill-available;
  padding: 0;
  margin: 0;
  border-radius: 0;
  visibility: inherit;
  overflow: hidden;
  background: var(--primary-colors-1-capgemini-blue);
  background: linear-gradient(
    90deg,
    var(--primary-colors-1-capgemini-blue) 0%,
    var(--primary-colors-2-capgemini-vibrant-blue) 100%
  );

  & button {
    align-self: flex-end;
  }

  @media (min-width: ${breakpoints.md}px) {
    width: 50vw;
    height: auto;
    padding: ${(props) =>
      props.isDataLoading ? `1rem` : `1rem 3rem 1rem 9rem`};
    margin: initial;
    border-radius: 4px;
    overflow: initial;
    min-height: auto;
  }

  ${BackToTopButtonStyled} {
    background: transparent;
  }
`;

export const WelcomePopupProfileImage = styled.img`
  max-height: 5rem;
  max-width: 5rem;
  position: static;
  transform: none;
  border-radius: 50%;
  border: 0.25rem solid var(--primary-colors-7-capgemini-white);
  left: 0;
  top: 50%;

  @media (min-width: ${breakpoints.md}px) {
    max-height: 12rem;
    max-width: 12rem;
    position: absolute;
    transform: translate(-50%, -50%);
  }
`;
export const WelcomePopupCloseButtonContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;
export const WelcomePopupButtonContainer = styled.div`
  margin-top: 2rem;
  text-align: center;

  @media (min-width: ${breakpoints.md}px) {
    margin-top: 0;
    align-self: flex-end;
    text-align: initial;
  }
`;

export const WelcomePopupHeader = styled.h1`
  text-align: left;
  color: var(--primary-colors-7-capgemini-white);
  font-size: 2rem;
`;

export const WelcomePopupText = styled.div`
  text-align: left;
  color: var(--primary-colors-7-capgemini-white);
  line-height: 1.6;
  margin: 0 auto;
  font-size: 0.9375rem;

  @media (min-width: ${breakpoints.md}px) {
    margin: initial;
  }
`;

export const WelcomeHeadPosition = styled.div`
  text-align: left;
  color: var(--primary-colors-7-capgemini-white);
  line-height: 1.6;
`;

export const WelcomeHeadSignature = styled.div`
  text-align: left;
  color: var(--primary-colors-7-capgemini-white);
  line-height: 1.6;
  font-weight: bold;
`;

export const WelcomeImgTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
`;

export const WelcomeContainer = styled.div`
  height: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  padding: 0 0.5rem 3rem 1.5rem;
  ${LoaderWrapper} {
    padding: 1rem;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--primary-colors-7-capgemini-white);
    width: .5rem;
    background-clip: padding-box;
  }
  &::-webkit-scrollbar {
    width: .25rem;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  @media (min-width: ${breakpoints.md}px) {
    padding-bottom: 0rem;
  }
`;
