import { selectPage } from "store/actions/navigationActions";
import { store } from "index";
import { history } from "util/history";

const links = [
  "/",
  "/aboutssc",
  "/whyssc",
  "/wroclaw",
  "/poznan",
  "/capgemini",
  "/agenda",
];
const sidebarLinks = ["/", "/aboutssc", "/whyssc", "/wroclaw", "/poznan"];

export const navigationSetup = (url = window.location.pathname) => {
  const state = store.getState();
  const storeCurrentPage = state.navigationReducer.currentPage;
  const storePreviousPage = state.navigationReducer.currentPage;
  const storePreviousPageIndex = state.navigationReducer.currentPageIndex;
  let currentUrl = url;
  if (currentUrl === "next" || currentUrl === "back") {
    currentUrl = oneStepNaviHandler(currentUrl, storePreviousPageIndex);
    history.push(currentUrl);
  }

  const currentUrlIndex = links.indexOf(currentUrl);

  if (storeCurrentPage !== currentUrl && currentUrlIndex >= 0) {
    store.dispatch(
      selectPage(
        currentUrl,
        storePreviousPage,
        currentUrlIndex,
        storePreviousPageIndex
      )
    );
  }
};

const oneStepNaviHandler = (step: string, previousPageIndex: number) => {
  if (step === "next") {
    if (previousPageIndex >= sidebarLinks.length - 1) {
      return sidebarLinks[0];
    }
    return sidebarLinks[previousPageIndex + 1];
  }
  if (previousPageIndex <= 0) {
    return sidebarLinks[sidebarLinks.length - 1];
  }
  return sidebarLinks[previousPageIndex - 1];
};
