import React, { useRef } from "react";
import Modal, { ModalI } from "components/modal/Modal";
import { MobileModalNavigation } from "components/mobileModalNavigation/MobileModalNavigation";
import ContentLoader from "components/contentLoader/ContentLoader";
import OurCompetencesContent from "modalPages/OurCompetencesContent";
import YouGainContent from "modalPages/YouGainContent";
import TeamSpiritContent from "modalPages/TeamSpiritContent";
import GetTheFutureLink from "modalPages/GetTheFutureLink";
import { AboutSSCModalFetchData } from "containers/aboutSSCModal/AboutSSCModalFetchData";
import { MobileTitleText } from "./AboutSSCMobileModalStyles";

export const AboutSSCMobileModal = ({ setModalState, breakpoint, navigationElements }: ModalI) => {
  const ourCompetencesRef = useRef<HTMLDivElement>(null);
  const youGainRef = useRef<HTMLDivElement>(null);
  const teamRef = useRef<HTMLDivElement>(null);
  const officesRef = useRef<HTMLDivElement>(null);
  const sectionRefs = [ourCompetencesRef, youGainRef, teamRef, officesRef];
  const modalRef = useRef<HTMLDivElement>(null);

  const {
    dataLoaded,
    carouselData,
    ourCompetencesTiles,
    ourCompetencesLogos,
    youGainTiles,
    teamSpiritData
  } = AboutSSCModalFetchData();

  const scrollToSection = (refNumber: number) => {
    const selectedRef = sectionRefs[refNumber];
    modalRef.current!.scroll({behavior: "smooth", top: selectedRef.current?.offsetTop! - 100});
  };

  return (
    <Modal
      breakpoint={breakpoint}
      setModalState={setModalState}
      navigationBar={
        <MobileModalNavigation navigateToSection={scrollToSection} navigationElements={navigationElements} />
      }
      modalRef={modalRef}
    >
      <MobileTitleText>ABOUT CAPGEMINI SOFTWARE</MobileTitleText>
      {!dataLoaded ? (
        <ContentLoader />
      ) : (
        <>
          <section ref={ourCompetencesRef}>
            <OurCompetencesContent
              breakpoint={breakpoint}
              ourCompetencesLogos={ourCompetencesLogos}
              ourCompetencesTiles={ourCompetencesTiles}
              carouselData={
                carouselData.length &&
                carouselData.filter(
                  (element: any) =>
                    element.carousel_type === "See Our Competences"
                )
              }
            />
          </section>
          <section ref={youGainRef}>
            <YouGainContent
              youGainTiles={youGainTiles}
              breakpoint={breakpoint}
            />
          </section>
          <section ref={teamRef}>
            <TeamSpiritContent
              teamSpiritData={teamSpiritData}
              breakpoint={breakpoint}
            />
          </section>
          <section>
            <GetTheFutureLink text="Get the Future You Want: " linkto="www.capgemini.com" />
          </section>
        </>
      )}
    </Modal>
  );
};
