import styled from "styled-components";

export const StyledPlusButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  margin: 0 1px 1px 0;
  border: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  background-color: var(--primary-colors-7-capgemini-white);
  position: relative;
  cursor: pointer;
  font-size: 1.5rem;
  &:hover {
    color: var(--primary-colors-2-capgemini-vibrant-blue);
  }
  & .visible {
    display: block;
    cursor: default;
  }
  & .hidden {
    display: none;
    cursor: pointer;
  }
`;

export const StyledPlusButtonChild = styled.div`
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  font-size: 1rem;
`;
