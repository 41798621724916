const la_maddalena = {
  left: 115,
  top: 5,
  desiredLeft: "",
  desiredTop: "",
  id_name: "la_maddalena",
};

const przystan = {
  left: 120,
  top: 5,
  desiredLeft: "",
  desiredTop: "",
  id_name: "przystan",
};

const campo = {
  left: 160,
  top: 25,
  desiredLeft: "",
  desiredTop: "",
  id_name: "campo",
};

const pod_papugami = {
  left: 127,
  top: 18,
  desiredLeft: "",
  desiredTop: "",
  id_name: "pod_papugami",
};

const craft = {
  left: 175,
  top: 13,
  desiredLeft: "",
  desiredTop: "",
  id_name: "craft",
};

const la_scala = {
  left: 122,
  top: 12,
  desiredLeft: "",
  desiredTop: "",
  id_name: "la_scala",
};

const whiskey_in_the_jar = {
  left: 127,
  top: 23,
  desiredLeft: "",
  desiredTop: "",
  id_name: "whiskey_in_the_jar",
};

const winestone = {
  left: 142,
  top: 23,
  desiredLeft: "",
  desiredTop: "",
  id_name: "winestone",
};

const lwia_brama = {
  left: 155,
  top: 10,
  desiredLeft: "",
  desiredTop: "",
  id_name: "lwia_brama",
};

const the_cork = {
  left: 130,
  top: 15,
  desiredLeft: "",
  desiredTop: "",
  id_name: "the_cork",
};

const bernard = {
  left: 142,
  top: 16,
  desiredLeft: "",
  desiredTop: "",
  id_name: "bernard",
};

export const restaurantsLocations = [
  la_maddalena,
  przystan,
  campo,
  pod_papugami,
  craft,
  la_scala,
  whiskey_in_the_jar,
  winestone,
  lwia_brama,
  the_cork,
  bernard,
];

export const mobilePositionRestaurantsCluster = {
  width: 50,
  height: 200,
  backgroundWidth: 640,
  backgroundHeight: 1136,
};
