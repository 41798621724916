import styled from "styled-components";
import { MobilePreloaderI } from "./MobileBackground";

export const MobileBackgroundStyled = styled.div`
  height: 100%;
  overflow-x: hidden;
`;

export const BackgroundImage = styled.img`
  object-fit: cover;
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const MobilePreloader = styled("div")<MobilePreloaderI>`
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 200;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  visibility: hidden;
  opacity: 0;
  transition: opacity 1s linear, visibility 0s linear 1s;

  ${(props) =>
    props.isPreloaderVisible &&
    `
    visibility: visible;
    opacity: 1;
    transition: opacity 1s linear, visibility 0s linear;
    
 `}

  & img:nth-child(1) {
    animation: pulse 2s infinite;
    width: 40vw;
    margin-bottom: 20vh;

    @keyframes pulse {
      0% {
        transform: scale(0.75);
      }
      20% {
        transform: scale(1);
      }
      40% {
        transform: scale(0.75);
      }
      60% {
        transform: scale(1);
      }
      80% {
        transform: scale(0.75);
      }
      100% {
        transform: scale(0.75);
      }
    }
  }
`;
