import { breakpoints } from "customHooks/useBreakpoint";
import styled from "styled-components";

export const OurOfficesStyles = styled.div`
  width: 78%;
  padding-bottom: 5rem;
  margin-right: 1rem;
  @media (max-width: ${breakpoints.lg}px) {
    width: 100%;
  }
  @media (max-width: ${breakpoints.xs}px) {
    width: 95%;
  }
`;

export const Paragraph = styled.p`
  font: inherit;
  color: var(--primary-colors-5-capgemini-dark-grey);
  line-height: 1.5;
  font-size: 1rem;
`;

export const Numbers = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${breakpoints.md}px) {
    flex-direction: row;
  }
  @media (max-width: ${breakpoints.xs}px) {
    flex-direction: column;
  }
`;
