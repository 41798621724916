import useFetch from "customHooks/useFetch";
import { accordionsBackup } from "assets/backup/accordionsBackup";
import { seeOurCompetencesBackup } from "assets/backup/aboutSSCModal/seeOurCompetencesBackup";
import youGainBackup from "assets/backup/aboutSSCModal/youGain/youGainBackup";
import teamSpiritBackup from "assets/backup/aboutSSCModal/teamSpirit/teamSpiritBackup";
import { ourCompetencesLogosBackup } from "assets/backup/aboutSSCModal/ourCompetencesLogos/ourCompetencesLogosBackup";

export const AboutSSCModalFetchData = () => {
  const carouselData: any = useFetch("/carousel", {}, accordionsBackup)
    .response;

  const ourCompetencesTiles: any = useFetch(
    "/about-ssc/competences/tiles",
    {},
    seeOurCompetencesBackup
  ).response;

  const ourCompetencesLogos: any = useFetch(
    "/about-ssc/competences/general",
    {},
    ourCompetencesLogosBackup
  ).response;

  const youGainTiles: any = useFetch(
    "/about-ssc/you-gain/tiles",
    {},
    youGainBackup
  ).response;

  const teamSpiritData: any = useFetch(
    "/about-ssc/team-spirit",
    {},
    teamSpiritBackup
  ).response;

  const dataLoaded = [
    carouselData,
    ourCompetencesTiles,
    youGainTiles,
    [teamSpiritData],
  ].every((element: any) => element.length > 0);
  return {
    dataLoaded,
    carouselData,
    ourCompetencesTiles,
    ourCompetencesLogos,
    youGainTiles,
    teamSpiritData,
  };
};
