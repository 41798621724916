import styled from "styled-components";
import { BreakpointI } from "customHooks/useBreakpoint";

export const WroclawOfficePopupContainer = styled("div")<BreakpointI>`
  box-sizing: border-box;
  z-index: 100;
  display: flex;
  flex-direction: column;
  background: var(--primary-colors-1-capgemini-blue);
  border-radius: 4px;
  max-width: 70rem;
  max-height: 88vh;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);

  ${(props) => props.breakpoint === "lg" && `left: 53%; top: 45%; width: 92vw;`}
  ${(props) => props.breakpoint === "xl" && `left: 53%; top: 50%; width: 90vw;`}
  ${(props) => props.breakpoint === "xxl" && `top: 50%; width: 80vw;`}
  
  &::-webkit-scrollbar-thumb {
    background: var(--primary-colors-7-capgemini-white);

    border-top: solid 1rem transparent;
    border-bottom: solid 1rem transparent;
  }
  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
    border-right: transparent 0.5rem solid;
  }
  &::-webkit-scrollbar {
    width: 0.25rem;
  }
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;

  & button {
    width: 2rem;
    height: 2rem;
    font-size: 1rem;
  }
`;

export const MainContentSection = styled.div`
  margin-bottom: 1rem;
  overflow-y: overlay;
  ::-webkit-scrollbar {
    width: 0.5rem;
    margin-right: 0.25rem;
    margin: 10px 0;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--primary-colors-7-capgemini-white);
    border-radius: 0.5rem;
  }
`;

export const TopSection = styled.section`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  & div:nth-child(-n + 2) {
    margin: 0rem;
  }
`;

export const MiddleSection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  margin: auto;
  line-height: 0;

  #middle_a {
    color: var(--primary-colors-7-capgemini-white);
    line-height: 1.6;
    text-decoration: none;
  }

  & img {
    padding-left: 0.5rem;
  }

  transition: all 0.5s ease-in-out;
  &:focus,
  &:hover,
  &:active {
    text-decoration: underline;
  }
`;

export const BottomSection = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
`;

export const BottomSectionColumn = styled.div`
  color: var(--primary-colors-7-capgemini-white);
  margin-right: 1rem;
  & p {
    font-size: 0.875rem;
    line-height: 1.71;
    margin: 0;
  }
  & p:nth-child(1) {
    font-size: 0.9375rem;
    margin-top: 0rem;
  }

  & ul {
    padding-inline-start: 1rem;
    margin-top: 0rem;
  }
  & ul li {
    font-size: 0.875rem;
    line-height: 1.43;
  }
`;

export const DivideLine = styled.hr`
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background-color: var(--primary-colors-3-capgemini-light-gray);
  margin: 1rem 0;
`;

export const HostName = styled.div`
  display: inline;
  font-size: 20px;
  font-weight: 500;
  margin: 0 1rem 0 4.5rem;
  color: var(--primary-colors-7-capgemini-white);
`;

export const YourPersonal = styled.div`
  p {
    border-right: var(--primary-colors-3-capgemini-light-gray) 1px solid;
    color: var(--primary-colors-3-capgemini-light-gray);
    padding-right: 1.5rem;
    font-weight: normal;
  }
`;

export const HostEmail = styled.div`
  display: inline-flex;
  font-size: 15px;
  font-weight: normal;
  padding: 0 1.5rem;

  img {
    display: inline-block;
    border-radius: 50%;
  }

  p {
    margin-left: 1.5rem;
    color: var(--primary-colors-3-capgemini-light-gray);
  }
`;

export const HostPhone = styled.div`
  display: inline-flex;
  font-size: 15px;
  font-weight: 500;
  padding: 0 0.75rem;

  img {
    width: 1.3rem;
    border-radius: 50%;
  }
  p {
    margin-left: 1.5rem;
    color: var(--primary-colors-3-capgemini-light-white);
  }
`;

export const HostImage = styled.img`
  border: solid white 0.25rem;
  border-radius: 50%;

  position: absolute;
  height: 7rem;
  width: 7rem;
  border: solid white 0.1875rem;
  transform: translate(-50%, 0%);
  display: inline;
`;

export const HostInfoContainer = styled.div`
  padding: 1rem 1rem 1rem 0;
  color: var(--primary-colors-7-capgemini-white);
  display: flex;
  align-content: space-between;
  align-items: center;
  line-height: 1.6;
`;

export const GradientSection = styled.div`
  background: linear-gradient(
    90deg,
    var(--primary-colors-1-capgemini-blue) 0%,
    var(--primary-colors-2-capgemini-vibrant-blue) 100%
  );
  padding: 1.2rem;
`;
