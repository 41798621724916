import React, { useState, useEffect, ChangeEvent, SyntheticEvent } from "react";
import { apiErrorI, useSimpleFetch } from "customHooks/useFetch";
import Button from "components/button/Button";
import { AgendaEventDataI } from "containers/agendaEvent/AgendaEvent";
import { store } from "index";
import { Link } from "react-router-dom";
import { GoBackContainer } from "components/sidepageTopNavigation/SidepageTopNavigationStyles";
import iconLeft from "assets/icon-arrow-left-on-light-normal.svg";
import {
  InfoText,
  AgendaTokenPopupWrapper,
  ButtonsWrapper,
  YourVisitSubtitlePopup,
  YourVisitTitlePopup,
} from "./AgendaTokenPopupStyles";

interface AgendaHostI {
  name: string;
  surname: string;
  email: string;
  phone: string;
  photo: string;
}

interface AgendaDayI {
  date: string;
  weekday: string;
  agenda_events: AgendaEventDataI[];
}

export interface AgendaI {
  company_name: string;
  company_logo: string;
  client: string;
  host: AgendaHostI;
  agenda_days: AgendaDayI[];
}

interface AgendaPopupI extends isVisibleI {
  setAgendaData: Function;
  token?: string;
}

export interface isVisibleI {
  isVisible: boolean;
}

const agendaTokenInfoHandler = (
  value: apiErrorI | null,
  tokenInputValue: string
) => {
  if (!tokenInputValue) {
    return "Please provide agenda token";
  }
  switch (value?.message) {
    case "400: Bad Request":
      return "Invalid token. Please check it and try again";
    case "500: Internal server error":
    case "Failed to fetch":
      return "There is problem with our server, please try later";
    default:
      return "Processing...";
  }
};

export const AgendaTokenPopup = ({
  setAgendaData,
  isVisible,
  token,
}: AgendaPopupI) => {
  const [agendaToken, setAgendaToken] = useState(
    token || localStorage.getItem("agendaToken") || ""
  );
  const [isTokenSubmitted, setIsTokenSubmitted] = useState(!!token);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getAgendaData = () => {
    setIsLoading(true);
    setIsTokenSubmitted(true);
    useSimpleFetch("/agenda", {
      method: "POST",
      body: JSON.stringify({
        user_token: agendaToken,
      }),
    })
      .then((response) => {
        setError(null);
        localStorage.setItem("agendaToken", agendaToken);
        return response.json();
      })
      .then((res) => setAgendaData(res))
      .catch((err) => {
        setError(err);
        localStorage.removeItem("agendaToken");
      })
      .finally(() => setIsLoading(false));
  };

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    getAgendaData();
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAgendaToken(event.target.value);
    setIsTokenSubmitted(false);
  };

  useEffect(() => {
    getAgendaData();
  }, []);

  return (
    <AgendaTokenPopupWrapper isVisible={isVisible}>
      <YourVisitTitlePopup>YOUR VISIT</YourVisitTitlePopup>
      <YourVisitSubtitlePopup>Place here your token</YourVisitSubtitlePopup>
      <form onSubmit={handleSubmit}>
        <label htmlFor="AgendaToken">
          <input
            id="AgendaToken"
            type="text"
            value={agendaToken}
            onChange={handleChange}
          />
        </label>
        <ButtonsWrapper>
          <Link to={store.getState().navigationReducer.previousPage}>
            <GoBackContainer>
              <img src={iconLeft} alt="left" />
              <p>Back to the map</p>
            </GoBackContainer>
          </Link>
          <Button
            buttonType="primaryLight"
            label="Go to Your Visit"
            size="small"
            type="submit"
          />
        </ButtonsWrapper>
      </form>
      <InfoText>
        {isTokenSubmitted &&
          !isLoading &&
          agendaTokenInfoHandler(error, agendaToken)}
      </InfoText>
    </AgendaTokenPopupWrapper>
  );
};
