import image1 from "./01.webp";
import image2 from "./02.webp";
import image3 from "./03.webp";
import image4 from "./04.webp";

const youGainBackup = [
  {
    header: "seamless cooperation",
    content: `<ul>
        <li>flexible delivery model – distributed Agile experience</li>
        <li>nearshore concept – no-time zone barrier and geographical proximity </li>
        <li>German-speaking experts</li>
        <li>scalability – right staff at the right time </li>
      </ul>`,
    image: image1,
  },
  {
    header: "experts understanding your business",
    content: `<ul>
        <li>many years of experience in different industry sectors</li>
        <li>rapid insight into the result – monitoring on every step and consultation</li>
        <li>early adopting to changing requirements</li>
        <li>short time-to-market</li>
        <li>avail ourselves of Service Design which is a tool to precisely identify the real user’s needs and prototype a solution to validate business goals</li>
      </ul>`,
    image: image2,
  },
  {
    header: "frameworks and solution design fulfilling your demands",
    content: `<ul>
        <li>access to wide range of skillset (existing, accomplished staff)</li>
        <li>our Centers of Excellence enable flow of knowledge from prime technologically practices</li>
        <li>we provide you with the best solution both custom development or product-based</li>
      </ul>`,
    image: image3,
  },
  {
    header: "best delivery quality with focus on security",
    content: `<ul>
        <li>high working standards ensuring the finest quality and delivery on time</li>
        <li>engaged, loyal teams from various specialties</li>
        <li>we are ISO certified and meet highest standards in cybersecurity</li>
        <li>industrialized and automated application management</li>
      </ul>`,
    image: image4,
  },
];

export default youGainBackup;
