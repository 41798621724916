import { breakpoints } from "customHooks/useBreakpoint";
import styled from "styled-components";
import { CarouselBoxI } from "./CarouselBox";

export const CarouselBoxStyled = styled.div<CarouselBoxI>`
  background-color: var(--primary-colors-7-capgemini-white);
  width: 70%;
  height: ${(props) => props.height};
  margin: 2rem auto 1.25rem auto;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  border: solid 1px rgba(119, 119, 119, 0.25);
  @media (max-width: ${breakpoints.md}px) {
    width: 80%;
  }
`;

export const CarouselBoxTitle = styled.div`
  color: var(--primary-colors-6-capgemini-deep-purple);
  line-height: 1.25rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
`;

export const ParagraphBox = styled.p`
  font-size: 0.875rem;
  color: var(--primary-colors-5-capgemini-dark-grey);
  text-align: left;
`;

export const CarouselBoxContent = styled.div``;
