import styled from "styled-components";
import { BreakpointI, breakpoints } from "customHooks/useBreakpoint";

export const PopupContainer = styled("div")<BreakpointI>`
  box-sizing: border-box;
  z-index: 100;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    90deg,
    var(--primary-colors-1-capgemini-blue) 0%,
    var(--primary-colors-2-capgemini-vibrant-blue) 100%
  );
  border-radius: 0;
  color: var(--primary-colors-7-capgemini-white);
  justify-content: space-between;
  overflow-y: visible; // Mozilla
  overflow-y: overlay; // Chrome
  overflow-x: hidden;
  padding: 0;
  align-items: center;
  position: relative;
  max-height: none;
  width: 100vw;

  @media (min-width: ${breakpoints.md}px) {
    border-radius: 4px;
    max-height: fit-content;
    height: auto;
    width: 80vw;
    overflow-y: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 1.5rem 2.5rem;
  }

  @media (min-width: ${breakpoints.xl}px) {
    width: 50vw;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--primary-colors-7-capgemini-white);

    border-top: solid 1rem transparent;
    border-bottom: solid 1rem transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-right: transparent 0.5rem solid;
  }
  &::-webkit-scrollbar {
    width: 0.25rem;
  }
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
`;

export const PopupChildrenWrapper = styled.div`
  height: 100%;
`;

export const MobilePopupContainer = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--primary-colors-1-capgemini-blue);
  overflow-y: visible;
`;
