import React, { FC, useEffect, useState, useContext } from "react";
import AnimatedPoint from "components/animatedPoint/AnimatedPoint";
import { PlusButtonText } from "components/plusButtonText/PlusButtonText";
import { PlusButton } from "components/plusButton/PlusButton";
import DoubleAnimatedLinkingLine from "components/doubleAnimatedLinkingLine/DoubleAnimatedLinkingLine";
import { HeadingPageText } from "components/headingPageText/HeadingPageText";
import { ArrowButton } from "components/arrowButton/ArrowButton";
import { ModalNavigationI } from "components/modalNavigation/ModalNavigation";
import Video from "containers/video/Video";
import WhySSCModal from "containers/whySSCModal/WhySSCModal";
import { WhySSCMobileModal } from "containers/whySSCMobileModal/WhySSCMobileModal";
import { MobileBackground } from "containers/mobileBackground/MobileBackground";
import { isMobile } from "customHooks/useBreakpoint";
import { navigationSetup } from "util/navigationSetup/navigationSetup";
import handleResize from "util/handleResize";
import { AppContext } from "App";
import { ArrowContainer, PktArrowContainer } from "./AboutNSCStyles";

const WhySSC: FC = () => {
  const [modalState, setModalState] = useState(false);
  const [desiredPointWidth, setDesiredPointWidth] = useState("");
  const [desiredPointHeight, setDesiredPointHeight] = useState("");
  const [secondDesiredPointWidth, setSecondDesiredPointWidth] = useState("");
  const [secondDesiredPointHeight, setSecondDesiredPointHeight] = useState("");
  const [displayPreloader, setDisplayPreloader] = useState(true);

  const firstPointPosition = {
    width: -130,
    height: 95,
  };
  const secondPointPosition = { width: -142, height: -20 };
  const mobileFirstPointPosition = {
    width: -55,
    height: 200,
    backgroundWidth: 320,
    backgroundHeight: 568,
  };
  const mobileSecondPointPosition = {
    width: -65,
    height: 100,
    backgroundWidth: 320,
    backgroundHeight: 568,
  };


  const calculatePosition = handleResize([
    {
      mobilePosition: mobileFirstPointPosition,
      desktopPosition: firstPointPosition,
      setDesiredPointHeight,
      setDesiredPointWidth,
    },
    {
      mobilePosition: mobileSecondPointPosition,
      desktopPosition: secondPointPosition,
      setDesiredPointHeight: setSecondDesiredPointHeight,
      setDesiredPointWidth: setSecondDesiredPointWidth,
    },
  ]);

  useEffect(() => {
    navigationSetup();
    calculatePosition();
    window.addEventListener("resize", calculatePosition);
    setTimeout(() => setDisplayPreloader(false), 2000);
    return () => {
      window.removeEventListener("resize", calculatePosition);
    };
  }, [desiredPointHeight, desiredPointWidth]);

  const { breakpoint } = useContext(AppContext);

  const navigation: ModalNavigationI[] = [
    {
      href: "#WhatMakesUsStandOut",
      title: "What Makes Us Stand Out",
      isActive: true
    },
    {
      href: "#OurOffices",
      title: "Our Offices",
      isActive: false
    }
  ]

  const desktopView = (
    <Video>
      <div>
        {modalState && (
          <WhySSCModal breakpoint={breakpoint} setModalState={setModalState} navigationElements={navigation} />
        )}
      </div>
      <DoubleAnimatedLinkingLine
        endLineHeightAdjustment={33}
        endLineWidthAdjustment={25}
        displayPopup={modalState}
        startingLeft={desiredPointWidth}
        startingTop={desiredPointHeight}
        secondStartingLeft={secondDesiredPointWidth}
        secondStartingTop={secondDesiredPointHeight}
        endingTop="50%"
        endingLeft="55%"
        color="var(--primary-colors-2-capgemini-vibrant-blue)"
        secondLineColor="var(--primary-colors-2-capgemini-vibrant-blue)"
        startingPoint={
          <AnimatedPoint
            pointColor="vibrantBlue"
            size="small"
            textPosition="left"
          >
            Wrocław
          </AnimatedPoint>
        }
        secondStartingPoint={
          <AnimatedPoint
            pointColor="vibrantBlue"
            size="small"
            textPosition="right"
          >
            Poznań
          </AnimatedPoint>
        }
        endingPoint={
          <div>
            <PlusButtonText
              fontSize="2.25rem"
              margin="auto"
              text="Why Software Center,"
              isTextVisible={!modalState}
            />
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <PlusButton
                receivedFunc={() => setModalState(true)}
                showChildComponent={modalState}
              />
              <PlusButtonText
                fontSize="2.25rem"
                text="Capgemini Poland?"
                isTextVisible={!modalState}
                margin="auto auto 2rem 2rem"
              />
            </div>
          </div>
        }
      />
    </Video>
  );

  const mobileView = (
    <MobileBackground isPreloaderVisible={displayPreloader}>
      <HeadingPageText text="WHY SOFTWARE CENTER" />
      {modalState && <WhySSCMobileModal breakpoint={breakpoint} setModalState={setModalState} navigationElements={navigation} />}
      <PktArrowContainer>
        <DoubleAnimatedLinkingLine
          isDragable={false}
          endLineHeightAdjustment={45}
          endLineWidthAdjustment={35}
          displayPopup={modalState}
          startingLeft={desiredPointWidth}
          startingTop={desiredPointHeight}
          secondStartingLeft={secondDesiredPointWidth}
          secondStartingTop={secondDesiredPointHeight}
          endingTop="55%"
          endingLeft="55%"
          color="var(--primary-colors-2-capgemini-vibrant-blue)"
          secondLineColor="var(--primary-colors-2-capgemini-vibrant-blue)"
          startingPoint={
            <AnimatedPoint
              pointColor="vibrantBlue"
              size="small"
              textPosition="left"
            >
              Wrocław
            </AnimatedPoint>
          }
          secondStartingPoint={
            <AnimatedPoint
              pointColor="vibrantBlue"
              size="small"
              textPosition="left"
            >
              Poznań
            </AnimatedPoint>
          }
          endingPoint={
            <ArrowContainer breakpoint={breakpoint}>
              <ArrowButton
                breakpoint={breakpoint}
                onClick={() => setModalState(true)}
              />
            </ArrowContainer>
          }
        />
      </PktArrowContainer>
    </MobileBackground>
  );
  return isMobile(breakpoint) ? mobileView : desktopView;
};

export default WhySSC;
