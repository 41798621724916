import { breakpoints } from "customHooks/useBreakpoint";
import styled from "styled-components";
import { Line } from "../../components/timeline/TimelineStyles";

export const AgendaContentWrapper = styled.div`
  width: 80%;
  @media (max-width: ${breakpoints.lg}px) {
    width: 92%;
  }
`;

export const AgendaEventsWrapper = styled.div`
  width: 100%;
`;

export const AgendaEventWithTimelineWrapper = styled.div`
  display: flex;
  position: relative;

  @media (max-width: ${breakpoints.sm}px) {
    flex-direction: column;
  }
  &:nth-last-child(1) ${Line}:nth-child(1) {
    visibility: hidden;
  }
`;

export const AgendaWrapper = styled.div`
  background-color: var(--primary-colors-7-capgemini-white);
`;
