import { breakpoints } from "customHooks/useBreakpoint";
import styled from "styled-components";

export const IconsContainer = styled.div`
  position: absolute;
  bottom: 20px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;

  & img {
    width: 1.5rem;
    height: 1.5rem;
    margin-bottom: -0.5rem;
  }

  & p {
    font-size: 0.75rem;
    font-weight: 500;
  }

  & span {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  & a:first-child {
    margin-left: 1rem;
  }

  & a:nth-child(2) {
    margin-right: 1rem;
  }

  & a {
    text-decoration: none;
    color: var(--primary-colors-1-capgemini-blue);
  }

  & a:hover {
    filter: invert(0.4) sepia(1) saturate(20) hue-rotate(162deg) brightness(1);
  }

  @media (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.md}px) {
    & img {
      width: 2.5rem;
      height: 2.5rem;
      margin-bottom: -0.5rem;
    }
    & p {
      font-size: 0.9rem;
    }
  }

  @media (min-width: ${breakpoints.xs}px) and (max-width: ${breakpoints.sm}px) {
    & img {
      width: 2rem;
      height: 2rem;
      margin-bottom: -0.5rem;
    }
    & p {
      font-size: 0.8rem;
    }
  }
  @media (min-width: ${breakpoints.md}px) {
    position: fixed;
  }
`;
