export const mapFilters = [
  {
    label: "Airport",
    name: "airport",
    active: true,
  },
  {
    label: "Our Office",
    name: "office",
    active: true,
  },
  {
    label: "Hotel",
    name: "hotel",
    active: true,
  },
  {
    label: "City Market",
    name: "market",
    active: true,
  },
  {
    label: "Restaurant",
    name: "restaurant",
    active: true,
  },
];
