export const calculatePulsePointPosition = (expectedPosition: {
  width: number;
  height: number;
  backgroundWidth?: number;
  backgroundHeight?: number;
}) => {
  const expectedWidth = expectedPosition.width;
  const expectedHeight = expectedPosition.height;
  const { backgroundWidth = 1920, backgroundHeight = 1080 } = expectedPosition;
  const windowAspectRatio = window.innerWidth / window.innerHeight;
  const videoAspectRatio = backgroundWidth / backgroundHeight;
  let desiredWidth: number;
  let desiredHeight: number;
  if (windowAspectRatio > videoAspectRatio) {
    desiredWidth = (expectedWidth / backgroundWidth) * 100;
    desiredHeight = (expectedHeight / backgroundHeight) * 100;
  } else {
    desiredWidth =
      (expectedWidth /
        (windowAspectRatio / videoAspectRatio) /
        backgroundWidth) *
      100;
    desiredHeight =
      (expectedHeight /
        (windowAspectRatio / videoAspectRatio) /
        backgroundHeight) *
      100;
  }

  return { width: `${desiredWidth}%`, height: `${desiredHeight}%` };
};
