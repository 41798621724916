import React, { useEffect, useState } from "react";
import { ReactComponent as DownloadIcon } from "assets/icon-download.svg";
import useFetch from "customHooks/useFetch";
import Button from "components/button/Button";
import { StyledDownloadButton } from "./DownloadButtonStyles";

export const DownloadButton = () => {
  const [fetchResponse, setFetchResponse] = useState({});

  const userToken = localStorage.getItem("agendaToken") || "";
  const { fetchData, response } = useFetch(
    "/agenda/pdf-export",
    {
      method: "POST",
      body: JSON.stringify({
        user_token: userToken,
      }),
      responseType: "blob",
    },
    {},
    false
  );

  const downloadPdf = () => {
    fetchData().then(() => {
      (fetchResponse as Response).blob().then((res: Blob) => {
        const blob = new Blob([res], {
          type: "application/pdf",
        });
        const downloadUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = downloadUrl;
        a.download = "agenda.pdf";
        document.body.appendChild(a);
        a.click();
      });
    });
  };

  useEffect(() => {
    setFetchResponse(response);
  }, [response]);

  return (
    <StyledDownloadButton>
      <Button
        onClick={downloadPdf}
        buttonType="secondaryLight"
        label="download pdf"
        icon={<DownloadIcon />}
      />
    </StyledDownloadButton>
  );
};
