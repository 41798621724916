export const aboutCapgeminiBackup = {
  id: "BackupData",
  content:
    "<p>Capgemini is a global leader in partnering with companies to transform and manage their business by harnessing the power of technology. The Group is guided everyday by its purpose of unleashing human energy through technology for an inclusive and sustainable future. It is a responsible and diverse organization of 270,000 team members in nearly 50 countries. With its strong 50 year heritage and deep industry expertise, Capgemini is trusted by its clients to address the entire breadth of their business needs, from strategy and design to operations, fueled by the fast evolving and innovative world of cloud, data, AI, connectivity, software, digital engineering and platforms. The Group reported in 2020 global revenues of &euro;16 billion.</p>",
  subtitle: " One of the most attractive foreign direct investment",
  tile_1_title: "Software Center, Capgemini Poland",
  tile_1_content: "Application design, development, testing and maintenance",
  tile_2_title: "Business Services (BSv)",
  tile_2_content:
    "Finance & Accounting, Procurement Services, Knowledge Process Outsourcing",
  tile_3_title: "Cloud Infrastructure Services (CIS)",
  tile_3_content:
    "Service Desk, Service Management, Data Center & Infrastructure",
  tile_4_title: "Application Services Warsaw",
  tile_4_content: "1 Desk, Service Management, Data Center & Infrastructure",
  stat_1_title: "38,3 mln",
  stat_1_content: "#6 largest economy in EU",
  stat_2_title: "500+",
  stat_2_content: "Universities",
  stat_3_title: "280k",
  stat_3_content: "Professionals",
  stat_4_title: ">2,9",
  stat_4_content: "Stable GDP growth",
  stat_5_title: "443k",
  stat_5_content: "ICT Employees",
};
