import { breakpoints } from "customHooks/useBreakpoint";
import styled from "styled-components";

export const BuildingContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  & img {
    border: solid 4px var(--primary-colors-7-capgemini-white);
    max-width: 12.35rem;
    max-height: 12.35rem;
  }

  @media (min-width: ${breakpoints.sm}px) and (max-width: ${breakpoints.lg}px) {
    width: 50%;

    & img {
      width: 11rem;
    }
  }
`;

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 14rem;
  padding-left: 1.2rem;
  font: inherit;
  color: var(--primary-colors-7-capgemini-white);

  @media (max-width: ${breakpoints.xs}px) {
    width: 100%;
    padding-left: 0;
  }
`;

export const BuildingHeadingText = styled.h4`
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
`;

export const ReceptionDeskText = styled.p`
  font: inherit;
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 1.6;
  margin: 0;
  padding: 0;
`;

export const AddressText = styled.p`
  font: inherit;
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.6;
  margin-bottom: 0;
  padding-bottom: 0;
`;

export const BuldingInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  font: inherit;
  font-size: 0.9375rem;
  font-weight: 300;
  line-height: 1.6;
  color: var(--primary-colors-7-capgemini-white);
`;
