import videoGlob from "./videos/0-1.mp4";
import videoGlobToEurope from "./videos/1-2.mp4";
import videoGlobToPoland from "./videos/1-3.mp4";
import videoGlobToWroclaw from "./videos/1-4.mp4";
import videoGlobToPoznan from "./videos/1-5.mp4";
import videoEuropeToGlob from "./videos/2-1.mp4";
import videoEuropeToPoland from "./videos/2-3.mp4";
import videoEuropeToWroclaw from "./videos/2-4.mp4";
import videoEuropeToPoznan from "./videos/2-5.mp4";
import videoPolandToGlob from "./videos/3-1.mp4";
import videoPolandToEurope from "./videos/3-2.mp4";
import videoPolandToWroclaw from "./videos/3-4.mp4";
import videoPolandToPoznan from "./videos/3-5.mp4";
import videoWroclawToGlob from "./videos/4-1.mp4";
import videoWroclawToEurope from "./videos/4-2.mp4";
import videoWroclawToPoland from "./videos/4-3.mp4";
import videoWroclawToPoznan from "./videos/4-4.mp4";
import videoPoznanToGlob from "./videos/5-1.mp4";
import videoPoznanToEurope from "./videos/5-2.mp4";
import videoPoznanToPoland from "./videos/5-3.mp4";
import videoPoznanToWroclaw from "./videos/5-4.mp4";

export const videoList: any = {
  "0:0": videoGlob,
  "0:1": videoGlobToEurope,
  "0:2": videoGlobToPoland,
  "0:3": videoGlobToWroclaw,
  "0:4": videoGlobToPoznan,
  "1:0": videoEuropeToGlob,
  "1:2": videoEuropeToPoland,
  "1:3": videoEuropeToWroclaw,
  "1:4": videoEuropeToPoznan,
  "2:0": videoPolandToGlob,
  "2:1": videoPolandToEurope,
  "2:3": videoPolandToWroclaw,
  "2:4": videoPolandToPoznan,
  "3:0": videoWroclawToGlob,
  "3:1": videoWroclawToEurope,
  "3:2": videoWroclawToPoland,
  "3:4": videoWroclawToPoznan,
  "4:0": videoPoznanToGlob,
  "4:1": videoPoznanToEurope,
  "4:2": videoPoznanToPoland,
  "4:3": videoPoznanToWroclaw,
};
