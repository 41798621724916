import React from "react";
import { ReactComponent as Logo } from "assets/logo/Capgemini_Logo_color_rgb.svg";
import Button from "components/button/Button";
import { Link, useHistory } from "react-router-dom";
import {
  ContentError,
  ErrorButtonsWrapper,
  StyledNotFoundBox,
  SubtitleError,
  TitleError,
} from "./NotFoundBoxStyles";

const NotFoundBox = () => {
  const history = useHistory();

  return (
    <StyledNotFoundBox>
      <Logo />
      <TitleError>404</TitleError>
      <SubtitleError>Oops! Page not found</SubtitleError>
      <ContentError>The page you requested could not be found</ContentError>
      <ErrorButtonsWrapper>
        <Link to="/" style={{width: "100%"}}>
          <Button buttonType="primaryLight" label="Go to Welcome" />
        </Link>
        <Button
          buttonType="secondaryLight"
          label="Previous Page"
          onClick={history.goBack}
        />
      </ErrorButtonsWrapper>
    </StyledNotFoundBox>
  );
};

export default NotFoundBox;
