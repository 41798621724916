import throttle from "lodash.throttle";
import { isMobile } from "customHooks/useBreakpoint";
import { calculatePulsePointPosition } from "./pointPositionCalculator/pointPositionCalculator";

interface handleResizeOptionsI {
  mobilePosition: {
    width: number;
    height: number;
    backgroundWidth?: number;
    backgroundHeight?: number;
  };
  desktopPosition: {
    width: number;
    height: number;
    backgroundWidth?: number;
    backgroundHeight?: number;
  };
  setDesiredPointWidth: Function;
  setDesiredPointHeight: Function;
}

export const resizeHandler = (options: handleResizeOptionsI[]) => {
  options.forEach((element) => {
    const {
      mobilePosition,
      desktopPosition,
      setDesiredPointWidth,
      setDesiredPointHeight,
    } = element;

    const AnimatedPointCords = isMobile()
      ? calculatePulsePointPosition(mobilePosition)
      : calculatePulsePointPosition(desktopPosition);
    setDesiredPointWidth(AnimatedPointCords.width);
    setDesiredPointHeight(AnimatedPointCords.height);
  });
};

const throttledHandleResize = (options: handleResizeOptionsI[]) =>
  throttle(() => resizeHandler(options), 500);

export default throttledHandleResize;
