import React, { FC, useEffect, useState, useContext } from "react";
import AnimatedLinkingLine from "components/animatedLinkingLine/AnimatedLinkingLine";
import AnimatedPoint from "components/animatedPoint/AnimatedPoint";
import { PlusButton } from "components/plusButton/PlusButton";
import { PlusButtonText } from "components/plusButtonText/PlusButtonText";
import PrivacyPolicy from "components/privacyPolicy/PrivacyPolicy";
import { HeadingPageText } from "components/headingPageText/HeadingPageText";
import { ArrowButton } from "components/arrowButton/ArrowButton";
import Video from "containers/video/Video";
import { MobileBackground } from "containers/mobileBackground/MobileBackground";
import WelcomePopup from "containers/welcomePopup/WelcomePopup";
import { navigationSetup } from "util/navigationSetup/navigationSetup";
import handleResize from "util/handleResize";
import { isMobile } from "customHooks/useBreakpoint";
import { AppContext } from "App";
import { MobilePopupContainer } from "./WelcomeStyles";

const Welcome: FC = () => {
  const [displayPopup, setDisplayPopup] = useState(false);
  const [desiredPointWidth, setDesiredPointWidth] = useState("1238");
  const [desiredPointHeight, setDesiredPointHeight] = useState("810");
  const [displayPreloader, setDisplayPreloader] = useState(true);
  const toggleWelcomePopup = () => {
    setDisplayPopup(!displayPopup);
  };
  const desktopPosition = { width: 15, height: -45 };
  const mobilePosition = {
    width: 8,
    height: -70,
    backgroundWidth: 320,
    backgroundHeight: 568,
  };
  const calculatePosition = handleResize([
    {
      mobilePosition,
      desktopPosition,
      setDesiredPointWidth,
      setDesiredPointHeight,
    },
  ]);

  useEffect(() => {
    navigationSetup();
    calculatePosition();
    window.addEventListener("resize", calculatePosition);
    setTimeout(() => setDisplayPreloader(false), 2000);
    return () => {
      window.removeEventListener("resize", calculatePosition);
    };
  }, [desiredPointHeight, desiredPointWidth]);

  const { breakpoint } = useContext(AppContext);

  const desktopView = (
    <Video>
      { displayPopup &&
        <WelcomePopup
            breakpoint={breakpoint}
            receivedFunc={toggleWelcomePopup}
        />
      } 
      <AnimatedLinkingLine
        endLineHeightAdjustment={50}
        endLineWidthAdjustment={10}
        displayPopup={displayPopup}
        startingLeft={desiredPointWidth}
        startingTop={desiredPointHeight}
        endingTop={
          breakpoint === "xl"
            ? "20%"
            : breakpoint === "lg" || breakpoint === "md"
            ? "15%"
            : "10%"
        }
        endingLeft={["xs", "sm", "md"].includes(breakpoint) ? "70%" : "80%"}
        color="var(--primary-colors-2-capgemini-vibrant-blue)"
        startingPoint={<AnimatedPoint pointColor="vibrantBlue" size="small" />}
        endingPoint={
          <div>
            <PlusButtonText text="Welcome!" isTextVisible={!displayPopup} />
            <PlusButton
              receivedFunc={toggleWelcomePopup}
              showChildComponent={displayPopup}
            />
          </div>
        }
        isLinkingLineVisible={!displayPopup}
      />
      <PrivacyPolicy />
    </Video>
  );

  const mobileView = (
    <MobileBackground isPreloaderVisible={displayPreloader}>
      <HeadingPageText text="WELCOME!" />

      {displayPopup && (
        <MobilePopupContainer>
          <WelcomePopup breakpoint={breakpoint} />
        </MobilePopupContainer>
      )}
      <AnimatedLinkingLine
        endLineHeightAdjustment={20}
        endLineWidthAdjustment={30}
        displayPopup={displayPopup}
        startingLeft={desiredPointWidth}
        startingTop={desiredPointHeight}
        endingTop="60%"
        endingLeft="50%"
        color="var(--primary-colors-2-capgemini-vibrant-blue)"
        isDragable={false}
        startingPoint={<AnimatedPoint pointColor="vibrantBlue" size="small" />}
        endingPoint={
          <div>
            <ArrowButton
              breakpoint={breakpoint}
              onClick={() => setDisplayPopup(true)}
            />
          </div>
        }
        isLinkingLineVisible={!displayPopup}
      />
    </MobileBackground>
  );
  return isMobile(breakpoint) ? mobileView : desktopView;
};

export default Welcome;
