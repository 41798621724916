import React from "react";
import { Link } from "react-router-dom";
import agendaMobile from "assets/icon-agenda-mobile.svg";
import { IconsContainer } from "./AgendaMobileIconStyle";

export const AgendaMobileIcon = () => (
  <IconsContainer>
    <span> </span>
    <Link to="/agenda">
      <span>
        <img src={agendaMobile} alt="agenda" />
        <p>Your Visit</p>
      </span>
    </Link>
  </IconsContainer>
);
