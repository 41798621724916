import { PulsatingCircle } from "components/animatedPoint/AnimatedPointStyle";
import ButtonStyles from "components/button/ButtonStyles";
import styled from "styled-components";
import { MapPointI } from "./MapPoint";

export const InsideNumber = styled.span`
  color: var(--primary-colors-7-capgemini-white);
  position: absolute;
`;

export const MapPointContainer = styled("div")<MapPointI>`
  z-index: 10;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: ${({topMargin}) => topMargin};
  margin-left: ${({leftMargin}) => leftMargin};
  pointer-events: none;

  & ${ButtonStyles} {
    transition: opacity 0.5s ease-in-out;
    opacity: 0%;
  }

  :hover {
    z-index: 11;
  }

  & ${PulsatingCircle} {
    pointer-events: all;
    :hover {
      cursor: pointer;
      transition: 0.7s;
      filter: brightness(1.25);

      + ${ButtonStyles} {
        opacity: 85%;
      }
    }
  }

  ${(props) => props.buttonAtBottom && `flex-direction: column;`}
`;