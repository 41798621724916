import { breakpoints } from "customHooks/useBreakpoint";
import styled from "styled-components";

interface IsPastI {
  isPast: boolean;
}

export const TimelineWrapper = styled("div") <IsPastI>`
  display: flex;
  transform: translateY(0.9375rem);
  & div {
    margin-right: 2.8125rem;
    margin-left: 1.5rem;
    display: flex;
    flex-direction: column;

    & p {
      font-size: 0.9375rem;
      font-weight: 500;
    }
    & p:nth-child(1) {
      margin-bottom: 0;
    }
    & p:nth-child(2) {
      margin-top: 0.25rem;
      color: var(--primary-colors-4-capgemini-medium-grey);
    }
    @media (max-width: ${breakpoints.sm}px) {
      flex-direction: row;
      & p:nth-child(1) {
        margin-right: 0.5625rem;
      }
      & p:nth-child(2),
      p {
        margin-top: 1rem;
      }
    }
  }

  &::before {
    content: "";
    margin-left: -0.26875rem;
    width: 0.625rem;
    height: 0.625rem;
    background-color: var(--primary-colors-3-capgemini-light-gray);
    border-radius: 50%;
    z-index: 1;
    margin-top: 1.25rem;
    ${(props) =>
    props.isPast &&
    `background-color: var(--primary-colors-2-capgemini-vibrant-blue);
  `}
  }
`;

export const Line = styled("div") <IsPastI>`
  transform: translateY(0.9375rem);
  margin-top: 1.25rem;
  border-left: 2px solid var(--primary-colors-3-capgemini-light-gray);
  height: 100%;
  position: absolute;
  ${(props) =>
    props.isPast &&
    `border-left: 2px solid var(--primary-colors-2-capgemini-vibrant-blue);
  `}
`;
