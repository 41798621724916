import officeH from "../office/office-h.webp";

const airportPopupBackup = [
  {
    id: 1,
    id_name: "poznan_airport",
    name: "Wrocław Nicolaus Copernicus Airport",
    type: "airport",
    city: "Wroclaw",
    address: "Graniczna 190, 54-530 Wroclaw",
    phone_number: "+48 717 12 71 82",
    website_address: "https://airport.wroclaw.pl",
    image: officeH,
    taxis: [
      { name: "Taxi Company 1", phone_number: "+48 123 456 789" },
      { name: "Taxi Company 2", phone_number: "+48 123 456 798" },
    ],
  },
];

export default airportPopupBackup;
