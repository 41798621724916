import styled from "styled-components";
import { iOS } from "util/detectIphone/detectIphone";
import { AnimatedPointI, getFullColor } from "./AnimatedPoint";

export const PointText = styled.div<AnimatedPointI>`
  transition: 0.7s;
  position: absolute;
  font-weight: 500;
  color: ${(props) => props.textColor};
  ${(props) =>
    props.textPosition === "left" &&
    `
    bottom: -0.2rem;
    right: 1rem;
  `}
  ${(props) =>
    props.textPosition === "right" &&
    `
    bottom: -0.2rem;
    left: 1.2rem;
  `}
  ${(props) =>
    props.textPosition === "top" &&
    `
    top: 50%;
    left: 47.8%;
    z-index: 1;
    background-color: var(--primary-colors-1-capgemini-blue);
    border-radius: 2rem;
    padding: 0 1rem;
    font-size: 0.875rem;
    line-height: 1.71;
    font-weight: 300;
    text-align: center;
;
  `}
    ${(props) =>
    props.textColor === "lightGrey" &&
    `
    color: var(--primary-colors-3-capgemini-light-gray)`}
`;

export const InsideNumber = styled.div<AnimatedPointI>`
  position: relative;
  font-size: 1000;
  z-index: 1001;
  color: black;
  `;

export const PulsatingCircle = styled("div")<AnimatedPointI>`
  background: white;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  height: 1rem;
  width: 1rem;
  transform: scale(1);
  opacity: ${({opacity}) => opacity};

  ${(props) =>
    props.pointColor !== "lightGrey" &&
    `
    animation: pulse 2s infinite;
  `}

  outline-color: ${({ pointColor }) => getFullColor(pointColor)};
  outline-style: solid;
  outline-width: 1px;
  ${() => iOS() && `outline: none;`}

  @keyframes pulse {
    0% {
      transform: scale(1);
      outline-width: 1px;
    }

    50% {
      transform: scale(1.1);
      outline-width: 0.75rem;
      outline-color: transparent;
    }

    100% {
      transform: scale(1);
      outline-width: 0;
      outline-color: transparent;
    }
  }

  ${(props) =>
    props.size === "small" &&
    `
    width: 0.7rem;
    height: 0.7rem;
  `}
  ${(props) =>
    props.size === "medium" &&
    `
    width: 1rem;
    height: 1em;
  `}
  ${(props) =>
    props.size === "large" &&
    `
    width: 1.5rem;
    height: 1.5em;
  `}
  ${(props) =>
    props.size === "cluster" &&
    `
    width: 2.5rem;
    height: 2.5rem;
  `}
  
    ${(props) =>
    props.pointColor &&
    `
    background:  ${props.pointColor};
  `}

  ${({ pointColor }) =>
    `
    background: ${getFullColor(pointColor)};
  `}


    ${(props) =>
    props.specificStyles === "APwroclaw" &&
    `
      position: absolute;
      top: 54%;
      left: 51%;
      z-index: 1;
      background: var(--primary-colors-1-capgemini-blue);
      &:hover {
      cursor:pointer;
      background: var(--primary-colors-2-capgemini-vibrant-blue);
      transition: 0.7s;
      }
      &:hover + ${PointText}{
      background: var(--primary-colors-2-capgemini-vibrant-blue);
      }
      
  `}
`;
