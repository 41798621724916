/* only images served locally from local backend + database or served from static frontend files
will get url which is relative, otherwise external API with images will be used and we expect whole url */

export const baseUrl = process.env.REACT_APP_SERVER_URL;
export const isExternalDatabase = process.env.REACT_APP_DATABASE === "external";

export const useImgUrl = (url: string) => (
  // if(!url) return "";
  // return isExternalDatabase ? url : `${baseUrl}${url}`; 
  url
);
