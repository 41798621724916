import React, { useEffect, useRef, useState } from "react";
import Modal, { ModalI } from "components/modal/Modal";
import ModalButtons from "components/modalButtons/ModalButtons";
import ModalNavigation from "components/modalNavigation/ModalNavigation";
import ContentLoader from "components/contentLoader/ContentLoader";
import { isMobile } from "customHooks/useBreakpoint";
import OurOfficesContent from "modalPages/OurOfficesContent";
import WhatMakesUsStandOutContent from "modalPages/WhatMakesUsStandOutContent";
import {
  ModalContent,
  ModalHeaderWrapper,
  ModalNavigationWrapper,
  ModalHeaderHeading,
  WhySSCModalWrapper
} from "./WhySSCModalStyles";
import { WhySSCModalFetchData } from "./WhySSCModalFetchData";

const WhySSCModal = ({ setModalState, breakpoint, navigationElements }: ModalI) => {
  const whatMakesUsStandOutRef = useRef<HTMLDivElement>(null);
  const ourOfficesRef = useRef<HTMLDivElement>(null);
  const sectionRefs = [whatMakesUsStandOutRef, ourOfficesRef];

  const [navigation, setNavigation] = useState([...navigationElements || []]);
  const prevScrollY = useRef(0);
  const { whatMakesUsStandOutData, ourOfficesData, carouselData, dataLoaded} = WhySSCModalFetchData();

  useEffect(() => {
    onLinkChange();
  }, [dataLoaded]);

  const onScroll = (e: any) => {
    const currentScrollY = e.target.scrollTop;
    prevScrollY.current = currentScrollY;
    onLinkChange(currentScrollY);
  };

  const scrollToSection = (refNumber: number) => {
    const selectedRef = sectionRefs[refNumber];
    if (selectedRef.current) {
      selectedRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onLinkChange = (position = 1) => {
    const constantBottomMargin = 20;
    removeActiveFromMenuEl();

    if (!whatMakesUsStandOutRef || !ourOfficesRef) return;
  
    const whatMakesUsStandOutHeight = whatMakesUsStandOutRef.current?.clientHeight || 0;

    if (!position || position < whatMakesUsStandOutHeight - constantBottomMargin) {
      addActiveToMenuEl('#WhatMakesUsStandOut');
    } else if (position >= whatMakesUsStandOutHeight - constantBottomMargin) {
      addActiveToMenuEl('#OurOffices');
    }
  };
  
  const addActiveToMenuEl = (elementHref: string) => (
    setNavigation(navigation?.map(el => ({... el, isActive: el.href === elementHref}) || []))
  )

  const removeActiveFromMenuEl = () => (
    setNavigation(navigation?.map(el => ({...el, isActive: false})) || [])
  )

  return (
    <Modal breakpoint={breakpoint} setModalState={setModalState}>
      <ModalHeaderWrapper>
        <ModalHeaderHeading>WHY CAPGEMINI SOFTWARE</ModalHeaderHeading>
      </ModalHeaderWrapper>
      <WhySSCModalWrapper>
        {!dataLoaded ? (
          <ContentLoader />
          ) : (
          <>
            <ModalNavigationWrapper>
              <ModalNavigation navigateToSection={scrollToSection} navigationElements={navigation} />
            </ModalNavigationWrapper>
            <ModalContent breakpoint={breakpoint} onScroll={onScroll}>
              <section ref={whatMakesUsStandOutRef}>
                <WhatMakesUsStandOutContent breakpoint={breakpoint} whatMakesUsStandOut={whatMakesUsStandOutData} />
              </section>
              <section ref={ourOfficesRef}>
                <OurOfficesContent
                  ourOfficesData={ourOfficesData}
                  carouselDataYourBenefits={
                    carouselData?.length &&
                    carouselData.filter(
                      (element: any) => element.carousel_type === "Our Offices"
                    )
                  }
                  carouselDataWroclaw={
                    carouselData?.length &&
                    carouselData.filter(
                      (element: any) => element.carousel_type === "Wrocław"
                    )
                  }
                  carouselDataPoznan={
                    carouselData?.length &&
                    carouselData.filter(
                      (element: any) => element.carousel_type === "Poznań"
                    )
                  }
                  breakpoint={breakpoint}
                />
              </section>
            </ModalContent>
          </>
        )}
      </WhySSCModalWrapper>
      {isMobile(breakpoint) ? (
        ""
      ) : (
        <ModalButtons
          leftButtonText="About Software Center, Capgemini Poland"
          rightButtonText="Wrocław Office"
          leftButtonUrl="/aboutssc"
          rightButtonUrl="/wroclaw"
        />
      )}
    </Modal>
  );
};

export default WhySSCModal;