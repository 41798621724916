import styled from "styled-components";

export const TextContainer = styled.div`
  text-align: center;
  font: inherit;
  width: 85vw;
  margin: 0 auto;
  font-size: 1rem;
`;

export const AboutCapgeminiFirstHeading = styled.h1`
  font-size: 2.25rem;
  color: var(--primary-colors-6-capgemini-deep-purple);
  text-transform: uppercase;
`;

export const AboutCapgeminiSecondHeading = styled.h3`
  font-size: 1.5rem;
  font-weight: 300;
  color: var(--primary-colors-1-capgemini-blue);
  text-align: center;
`;

export const ContentTextContainer = styled.div`
  & p {
    color: var(--primary-colors-5-capgemini-dark-grey);
    line-height: 1.5;
  }
`;

export const SubtitleText = styled.p`
  color: var(--primary-colors-1-capgemini-blue);
  font-weight: bold;
`;
