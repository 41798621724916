import styled from "styled-components";
import { BreakpointI } from "customHooks/useBreakpoint";

export const GoBackContainer = styled.span`
  display: flex;
  cursor: pointer;
  & p {
    color: var(--primary-colors-1-capgemini-blue);
  }

  &:hover p,
  &:hover img {
    filter: invert(0.4) sepia(1) saturate(20) hue-rotate(162deg) brightness(1);
  }
`;

export const ImageContainer = styled.div<BreakpointI>`
  display: flex;
  align-content: center;
  justify-content: space-between;
  cursor: pointer;
  word-break: break-word;
  ${(props) =>
    props.breakpoint === "xs" &&
    `
   padding-right: 0.5rem;
   margin-left: auto;
  `}
`;

export const HeaderContainer = styled.div<BreakpointI>`
  margin-top: 2rem;
  width: 100%;
  display: inline-grid;
  grid-template-columns: 1fr 1fr 1fr;

  & a:nth-child(1) {
    grid-column: 1/2;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: start;
  }

  & a:nth-child(2) {
    grid-column: 2/3;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${(props) =>
    props.breakpoint === "xs" &&
    `
    grid-template-columns: 2fr 1fr;
  `}
`;
