import { LoaderWrapper } from "components/contentLoader/ContentLoaderStyles";
import { StyledTextDataIcon } from "components/textDataIcon/TextDataIconStyles";
import { breakpoints } from "customHooks/useBreakpoint";
import { PopupContainer } from "components/popup/PopupStyles";
import styled from "styled-components";

export const PopupWrapper = styled.div`
  height: auto;

  ${PopupContainer} {
    padding: 1.5rem;
    width: 100%;
    height: auto;
    @media (min-width: ${breakpoints.md}px) {
      max-width: 50rem;
      align-items: initial;
    }
  }

  ${StyledTextDataIcon} {
    margin: 0 1.5rem 0 0;
    color: var(--primary-colors-3-capgemini-light-gray);
    svg {
      width: 1.5rem;
      height: 1.5rem;
      fill: var(--primary-colors-3-capgemini-light-gray);
    }
  }

  ${LoaderWrapper} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (min-width: ${breakpoints.md}px) {
    height: 100%;
  }
`;

export const AirportTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  width: 90%;
  margin: 0;
`;

export const AirportSubtitle = styled.h4`
  font-size: 0.937rem;
  font-weight: bold;
  line-height: 1.6;
  margin: 1rem 0.25rem 0.75rem 0;
`;

export const UpperSection = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: ${breakpoints.md}px) {
    justify-content: initial;
  }
`;

export const Contact = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0;

  ${StyledTextDataIcon} {
    margin-bottom: 0.75rem;
  }

  @media (min-width: ${breakpoints.xs}px) {
    flex-direction: row;

    ${StyledTextDataIcon} {
      margin-bottom: 0;
    }
  }
`;

export const InformationSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.25rem;

  @media (min-width: ${breakpoints.md}px) {
    width: 80%;
  }
`;

export const TaxiDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  color: var(--primary-colors-3-capgemini-light-gray);
  p {
    margin: 0;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 1rem;
  }
`;

export const SubtitleContent = styled.div`
  margin-left: 1.5rem;
  > * {
    margin: 0 0 0.75rem 0;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${breakpoints.xs}px) {
    display: normal;
  }
`;

export const AirportImage = styled.img`
  box-sizing: border-box;
  width: 11.25rem;
  height: 11.25rem;
  border: 0.25rem solid var(--primary-colors-7-capgemini-white);
  margin-bottom: 1.25rem;

  @media (min-width: ${breakpoints.md}px) {
    margin-bottom: 0;
  }
`;

export const LinksSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${StyledTextDataIcon} {
    margin-top: 1.25rem;
    margin-bottom: 0;
  }

  @media (min-width: ${breakpoints.xs}px) {
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
  }
`;

export const DivideLine = styled.div`
  height: 1px;
  width: 100%;
  opacity: 0.2;
  background-color: var(--primary-colors-3-capgemini-light-gray);
  justify-self: center;
`;
