import { MapPoint, MapPointI } from "components/mapPoint/MapPoint";
import React from "react";

interface PoisI {
  api: Array<ApiI>;
  poi: Array<PoiI>;
  setPopupData: React.Dispatch<ApiI>;
  onPointClick: Function;
  pointColor: string;
}

export interface ApiI {
  id_name: string;
  is_visible: boolean;
  name: string;
  e_mail: string;
  phone_number: string;
  website_address: string;
  image: string;
  address: string;
  gmaps_link?: string;
}

export interface PoiI {
  left: number;
  top: number;
  desiredLeft: string;
  desiredTop: string;
  id_name: string;
  how_to_get_there?: string;
}

const Restaurants = ({
  api,
  poi,
  setPopupData,
  onPointClick,
  pointColor,
}: PoisI) => {
  const clickHandler = (apiPopupData: ApiI) =>
    setPopupData({ ...apiPopupData });
  const getPoints = (
    restaurantPoints: Array<PoiI>,
    apiPoints: Array<ApiI>
  ): Array<any> => {
    const finalRestaurants = restaurantPoints.map(
      ({ desiredLeft, desiredTop, id_name }) => {
        for (let i = 0; i < apiPoints.length; i += 1) {
          if (apiPoints[i].id_name === id_name && apiPoints[i].is_visible) {
            return {
              label: apiPoints[i].name,
              leftMargin: desiredLeft,
              topMargin: desiredTop,
              clickEvent: () => clickHandler({ ...apiPoints[i] }),
            };
          }
        }
        return null;
      }
    );
    return finalRestaurants.filter((finalRestaurant) => !!finalRestaurant);
  };

  return (
    <>
      {api.length &&
        getPoints(poi, api).map(
          ({ label, leftMargin, topMargin, clickEvent }) => (
            <MapPoint
              key={label}
              label={label}
              leftMargin={leftMargin}
              topMargin={topMargin}
              onClick={() => {
                clickEvent();
                onPointClick();
              }}
              pointColor={pointColor as MapPointI["pointColor"]}
            />
          )
        )}
    </>
  );
};

export default Restaurants;
