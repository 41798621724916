import hostImage from "../../../host-image.webp";
import buildingK from "./office-k.webp";
import buildingH from "./office-h.webp";

export const officePopupBackupData = {
  id: "wroclawPopupBackupData",
  building_k_photo: buildingK,
  building_k_reception: "Reception desk on the 4th floor",
  building_k_name: "Business Garden",
  building_k_street: "Legnicka St 49K",
  building_k_city: "54-202 Wroclaw, Poland",
  building_h_photo: buildingH,
  building_h_reception: "Reception desk on the 5th floor",
  building_h_name: "Business Garden",
  building_h_street: "Legnicka St 49H",
  building_h_city: "54-202 Wroclaw, Poland",
  parking_field: "Parking for clients is located between buildings G & H",
  content1:
    "<p><strong>If it&#39;s your first time at Capgemini...</strong></p>\r\n\r\n<p>please ask your host to pick you up from the building reception and guide you into the office. Please have your ID with you, as it is required for the sign-in procedure.</p>",
  content2:
    "<p><strong>Here your business is safe:</strong></p>\r\n\r\n<ul>\r\n\t<li>Access to the building is protected with tripood turnstiles.</li>\r\n\t<li>Elevators are equipped with the AC readers which control access to the floors and are blocked until associate will use approved proximity card.</li>\r\n\t<li>All employeees &amp; guests are required to consider security procedures.</li>\r\n</ul>\r\n\r\n<p></p>",
  content3:
    "<p><strong>People matter for us:</strong></p>\r\n\r\n<p>Our offices offer our employees a comfortable space to work in modern building with the recent automation systems &amp; the LEED Platinum ecological certifcate. All facilties in Wrocław &amp; Poznań are also equipped with extensive recreational, sports and gastronomic amenities.</p>\r\n\r\n<p></p>",
  host_image: hostImage,
  host_contact:
    "<p><strong>Anna Kowalska</strong></p>\r\n\r\n<p>your personal host</p>\r\n\r\n<p>anna.kowalska@capgemini.com</p>\r\n\r\n<p>+48 123 456 789</p>",
  host_name: "<p><strong>Anna Kowalska</strong></p>",
  host_email: "<p>anna.kowalska@capgemini.com</p>",
  host_phone: "<p>+48 123 456 789</p>",
  host_description: "your personal host",
};

export interface WroclawPopupDataI {
  id: string;
  host_image: string;
  host_name: string;
  host_email: string;
  host_phone: string;
  host_description: string;
  parking_field: string;
  content1: string;
  content2: string;
  content3: string;
  building_k_photo: string;
  building_k_reception: string;
  building_k_name: string;
  building_k_street: string;
  building_k_city: string;
  building_h_photo: string;
  building_h_reception: string;
  building_h_name: string;
  building_h_street: string;
  building_h_city: string;
}
