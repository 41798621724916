import React from "react";
import { PulsatingCircle, PointText, InsideNumber } from "./AnimatedPointStyle";

export interface AnimatedPointI {
  pointColor?:
    | "white"
    | "blue"
    | "vibrantBlue"
    | "darkGrey"
    | "lightGrey"
    | "peacock"
    | "green"
    | "teal"
    | "sapphire"
    | "deepPurple";
  size?: "small" | "medium" | "large" | "cluster";
  textPosition?: "left" | "right" | "top";
  textColor?: string;
  onClick?: () => void;
  children?: any;
  specificStyles?: "APwroclaw";
  opacity?: string;
  insideNumber?: number;
}

export const getFullColor = (pointColor?: string) => {
  switch (pointColor) {
    case "peacock":
      return "var(--secondary-colors-cool-color-palette-10-capgemini-peacock)";
    case "sapphire":
      return "var(--secondary-colors-cool-color-palette-11-capgemini-sapphire)";
    case "green":
      return "var(--secondary-colors-cool-color-palette-8-capgemini-green)";
    case "blue":
      return "var(--primary-colors-2-capgemini-vibrant-blue)";
    case "teal":
      return "var(--secondary-colors-cool-color-palette-9-capgemini-teal)";
    case "deepPurple":
      return "var(--primary-colors-6-capgemini-deep-purple)";
    default:
      return "var(--primary-colors-2-capgemini-vibrant-blue)";
  }
};

export const AnimatedPoint = ({
  size = "medium",
  pointColor = "white",
  textPosition,
  children,
  textColor,
  specificStyles,
  opacity,
  insideNumber,
  ...props
}: AnimatedPointI) => (
  <>
    <PulsatingCircle
      size={size}
      pointColor={pointColor}
      specificStyles={specificStyles}
      opacity={opacity}
      {...props}
    />
    <InsideNumber>
      {insideNumber}
    </InsideNumber>
    <PointText textPosition={textPosition} textColor={textColor}>
      {children}
    </PointText>
  </>
);

export default AnimatedPoint;
