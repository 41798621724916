import React, { useState, useEffect } from "react";
import throttle from "lodash.throttle";
import { ArrowInCircle } from "components/arrowInCircle/ArrowInCircle";
import { BackToTopButtonStyled } from "./BackToTopButtonStyles";

export interface BackToTopButtonI {
  isDarkMode: boolean;
  elementRef?: HTMLDivElement | null;
  isVisible?: boolean;
  scrollMainPaneCallback?: Function;
  type?: "popup" | "modal";
}

export const BackToTopButton = ({
  isDarkMode,
  elementRef,
  scrollMainPaneCallback,
  type,
  ...props
}: BackToTopButtonI) => {
  const [showButton, setShowButton] = useState(false);
  const toggleButtonHeight = 5;

  const checkScrollTop = throttle(() => {
    if (elementRef) {
      if (elementRef.scrollTop > toggleButtonHeight) {
        setShowButton(true);
      }
      if (elementRef.scrollTop <= toggleButtonHeight) {
        setShowButton(false);
      }
    }
  }, 400);

  const scrollTop = () => {
    if (elementRef) {
      elementRef.scroll({ top: 0, behavior: "smooth" });
    }
    if(scrollMainPaneCallback) {
      scrollMainPaneCallback();
    }
  };

  useEffect(() => {
    if (elementRef) {
      elementRef.addEventListener("scroll", checkScrollTop);
    }
    return () => {
      if (elementRef) {
        elementRef.removeEventListener("scroll", checkScrollTop);
      }
    };
  }, [elementRef]);

  return (
    <BackToTopButtonStyled
      isDarkMode={isDarkMode}
      onClick={scrollTop}
      isVisible={showButton}
      type={type || "modal"}
      {...props}
    >
      <ArrowInCircle isDarkMode={isDarkMode} direction="up" />
    </BackToTopButtonStyled>
  );
};
