import styled from "styled-components";
import { PopupContainer } from "components/popup/PopupStyles";
import { StyledTextDataIcon } from "components/textDataIcon/TextDataIconStyles";

export const PopupWrapper = styled.div`
  height: 100%;

  ${PopupContainer} {
    padding: 1.25rem 1.25rem 0 1.25rem;
    width: 46.25rem;
    height: fit-content;
    align-items: initial;
  }
  ${StyledTextDataIcon} {
    color: var(--primary-colors-3-capgemini-light-gray);
    svg {
      width: 1.5rem;
      height: 1.5rem;
      fill: var(--primary-colors-3-capgemini-light-gray);
    }
  }
`;

export const UpperSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
`;

export const PoiTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0;
`;

export const InformationSection = styled.div``;

export const PoiImage = styled.img`
  box-sizing: border-box;
  width: 11.25rem;
  height: 11.25rem;
  border: 0.25rem solid var(--primary-colors-7-capgemini-white);
  margin-right: 1.25rem;
`;

export const PoiSubtitle = styled.h3`
  font-size: 0.9375rem;
  font-weight: bold;
  line-height: 1.6;
  margin: 1rem 0 0.5rem 0;
`;

export const SubtitleContent = styled.div`
  margin-left: 0.75rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: var(--primary-colors-3-capgemini-light-gray);
  > * {
    margin: 0 0 0.5rem 0;
  }

  ${StyledTextDataIcon}:nth-last-child(1) {
    justify-content: flex-end;
  }
`;

export const Contact = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > * {
    margin: 0 1rem 0 0;
  }
`;

export const DivideLine = styled.div`
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background-color: var(--primary-colors-3-capgemini-light-gray);
`;

export const LinkSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9375rem;
  font-weight: 500;
  text-align: center;
`;
