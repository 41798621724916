import { breakpoints } from "customHooks/useBreakpoint";
import styled from "styled-components";

export const ServicesMapContainer = styled.div`
  position: relative;
  & img {
    position: absolute;
    left: 50%;
    top: -15px;
    transform: translate(-50%, 0);
    height: calc(100% + 30px);
  }

  @media (max-width: ${breakpoints.lg}px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    & img {
      position: unset;
      left: unset;
      top: unset;
      transform: unset;
      height: auto;
    }
  }
  @media (max-width: ${breakpoints.xs}px) {
    & img {
      width: 85%;
    }
  }
`;
export const AboutServicesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  row-gap: 0.5rem;
  background-color: var(--tertiary-colors-21-capgemini-dark-white);

  & ${ServicesMapContainer} {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
  }
  & span:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
  & span:nth-child(2) {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }
  & span:nth-child(3) {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
  }
  & span:nth-child(4) {
    grid-column: 3 / 4;
    grid-row: 2 / 3;
  }

  & span:nth-child(odd) {
    margin-top: 10%;
  }
  & span:nth-child(even) {
    margin-bottom: 10%;
  }

  & span {
    justify-self: stretch;
    padding: 1% 4%;
    box-sizing: border-box;
  }

  @media (max-width: ${breakpoints.lg}px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & span {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & span:nth-child(odd) {
      margin-top: unset;
    }
    & span:nth-child(even) {
      margin-bottom: unset;
    }
  }
`;
