export const calculatePulsePointPosition = (calculatedPoint: {
  left: number;
  top: number;
  id_name: string;
  backgroundWidth?: number;
  backgroundHeight?: number;
  how_to_get_there?: string;
}) => {
  const expectedWidth = calculatedPoint.left;
  const expectedHeight = calculatedPoint.top;
  const { backgroundWidth = 1920, backgroundHeight = 1080 } = calculatedPoint;
  const windowAspectRatio = window.innerWidth / window.innerHeight;
  const videoAspectRatio = backgroundWidth / backgroundHeight;
  let desiredWidth: number;
  let desiredHeight: number;
  if (windowAspectRatio > videoAspectRatio) {
    desiredWidth = (expectedWidth / backgroundWidth) * 100;
    desiredHeight = (expectedHeight / backgroundHeight) * 100;
  } else {
    desiredWidth =
      (expectedWidth /
        (windowAspectRatio / videoAspectRatio) /
        backgroundWidth) *
      100;
    desiredHeight =
      (expectedHeight /
        (windowAspectRatio / videoAspectRatio) /
        backgroundHeight) *
      100;
  }

  return {
    desiredLeft: `${desiredWidth}%`,
    desiredTop: `${desiredHeight}%`,
    top: calculatedPoint.top,
    left: calculatedPoint.left,
    id_name: calculatedPoint.id_name,
    how_to_get_there: calculatedPoint.how_to_get_there
  };
};
