import { breakpoints } from "customHooks/useBreakpoint";
import styled from "styled-components";

export const ImagesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (min-width: ${breakpoints.sm}px) {
    flex-direction: row;
  }
`;

export const CitiesText = styled.h2`
  text-align: center;
  width: 100%;
  color: var(--primary-colors-1-capgemini-blue);
  font-size: 1.25rem;
  font-weight: 300;
  margin: 1.6rem 0 1.25rem 0;
`;
