import { AppContext } from "App";
import { isMobile } from "customHooks/useBreakpoint";
import React, { useContext, useEffect, useState } from "react";
import {
  DescriptionLabel,
  MapFilterWrapper,
  FilterElementsWrapper,
  Dot,
  Line,
  FilterName,
  FilterElement,
  FilterMobileContainer,
  FilterMobileWrapper,
} from "./MapFilterStyled";

export interface FilterElI {
  label: string;
  name: string;
  active: boolean;
}

export interface MapFilterI {
  elements: Array<FilterElI>;
  onFilterChange: Function;
  onFilterAllOn: Function;
  onFilterAllOff: Function;
  verticalLabel?: string;
}

export const typeColor = new Map([
  [
    "attraction",
    "var(--secondary-colors-cool-color-palette-11-capgemini-sapphire)",
  ],
  ["airport", "var(--secondary-colors-cool-color-palette-8-capgemini-green)"],
  ["office", "var(--primary-colors-2-capgemini-vibrant-blue)"],
  ["market", "var(--secondary-colors-cool-color-palette-10-capgemini-peacock)"],
  ["hotel", "var(--secondary-colors-cool-color-palette-9-capgemini-teal)"],
  ["restaurant", "var(--primary-colors-6-capgemini-deep-purple)"],
]);

const getColor = (type: string) => typeColor.get(type);

export const MapFilter = ({
  elements,
  onFilterChange,
  verticalLabel,
  onFilterAllOff,
  onFilterAllOn,
}: MapFilterI) => {
  const [allSelected, setAllSelected] = useState(true);

  const defaultColor = "var(--primary-colors-1-capgemini-blue)";

  const { breakpoint } = useContext(AppContext);

  const toggleShowAll = () => {
    if (!allSelected) {
      onFilterAllOn();
      setAllSelected(true);
    } else {
      onFilterAllOff();
      setAllSelected(false);
    }
  };

  useEffect(() => {
    if (elements.every((element) => element.active)) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [elements]);

  return (
    <>
      {!isMobile(breakpoint) ? (
        <MapFilterWrapper>
          <FilterElementsWrapper>
            {elements.map(({ label, name, active }) => (
              <FilterElement
                onClick={() => onFilterChange(name)}
                isActive={active}
                key={name}
                dotColor={getColor(name) || defaultColor}
              >
                <FilterName>{label}</FilterName>
                <Dot
                  dotColor={getColor(name) || defaultColor}
                  isActive={active}
                />
              </FilterElement>
            ))}
            <FilterElement
              onClick={toggleShowAll}
              isActive={allSelected}
              dotColor={defaultColor}
            >
              <FilterName>{allSelected ? "Hide all" : "Show all"}</FilterName>
              <Dot isActive={allSelected} dotColor={defaultColor} />
            </FilterElement>
          </FilterElementsWrapper>
          <Line />
          {verticalLabel && (
            <DescriptionLabel>{verticalLabel}</DescriptionLabel>
          )}
        </MapFilterWrapper>
      ) : (
        <FilterMobileContainer>
          <FilterMobileWrapper>
          <MapFilterWrapper>
            <FilterElementsWrapper>
              {elements.map(({ label, name, active }) => (
                <FilterElement
                  onClick={() => onFilterChange(name)}
                  isActive={active}
                  key={name}
                  dotColor={getColor(name) || defaultColor}
                >
                  <FilterName>{label}</FilterName>
                  <Dot
                    dotColor={getColor(name) || defaultColor}
                    isActive={active}
                  />
                </FilterElement>
              ))}
              <FilterElement
                onClick={toggleShowAll}
                isActive={allSelected}
                dotColor={defaultColor}
              >
                <FilterName>{allSelected ? "Hide all" : "Show all"}</FilterName>
                <Dot isActive={allSelected} dotColor={defaultColor} />
              </FilterElement>
            </FilterElementsWrapper>
            <Line />
          </MapFilterWrapper>
          </FilterMobileWrapper>
          {verticalLabel && <DescriptionLabel>{verticalLabel}</DescriptionLabel>}
        </FilterMobileContainer>
      )}
    </>
  );
};
