import imageGlob from "./images/world-bgr.webp";
import imageEurope from "./images/europe-static-bgr.webp";
import imagePoland from "./images/poland-static-bgr.webp";
import imageWroclaw from "./images/wroclaw-mobile-static.webp";
import imagePoznan from "./images/poznan-mobile-static.webp";

export const imageList = [
  imageGlob,
  imageEurope,
  imagePoland,
  imageWroclaw,
  imagePoznan,
];
