import React, { useContext } from "react";
import { PoisPopupApiI } from "containers/PoiPopup/desktop/PoiPopup";
import ContentLoader from "components/contentLoader/ContentLoader";
import Popup from "components/popup/Popup";
import TextDataIcon from "components/textDataIcon/TextDataIcon";
import { WroclawContext } from "pages/Wroclaw/Wroclaw";
import { BreakpointI } from "customHooks/useBreakpoint";
import { ReactComponent as IconPhone } from "assets/icon-mobile-phone.svg";
import { ReactComponent as IconLink } from "assets/icon-link.svg";
import { ReactComponent as IconEnvelope } from "assets/icon-envelope-regular.svg";
import {
  Contact,
  DivideLine,
  InformationSection,
  LinkSection,
  PoiImage,
  PoiSubtitle,
  PoiTitle,
  PopupWrapper,
  SubtitleContent,
  UpperSection,
} from "./PoiPopupStyles";

export interface PoiPopupI extends BreakpointI {
  poiData: Array<PoisPopupApiI>;
  idSection?: string;
}

export const PoiPopupMobile = ({ poiData, breakpoint }: PoiPopupI) => {

  const { attractionRef } = useContext(WroclawContext);

  return (
  <PopupWrapper ref={attractionRef}>
    {poiData.length &&
      poiData.map(
        ({
          image,
          name,
          phone_number,
          address,
          website_address,
          e_mail,
          gmaps_link,
        }) => (
          <Popup breakpoint={breakpoint}>
            {!poiData.length ? (
              <ContentLoader isDarkMode />
            ) : (
              <>
                <UpperSection>
                  <PoiImage src={image} />
                  <InformationSection>
                    <PoiTitle>{name}</PoiTitle>
                    <PoiSubtitle>
                      Address {phone_number && " and contact"}
                    </PoiSubtitle>
                    <SubtitleContent>
                      <p>{address}</p>
                      <Contact>
                        <TextDataIcon
                          title={phone_number}
                          textSize={0}
                          Icon={IconPhone}
                          iconPosition="left"
                        />
                        {website_address && (
                          <TextDataIcon
                            title="website"
                            href={website_address}
                            textSize={0}
                            Icon={IconLink}
                          />
                        )}
                      </Contact>
                      {e_mail && (
                        <TextDataIcon
                          title={e_mail}
                          textSize={0}
                          Icon={IconEnvelope}
                          iconPosition="left"
                        />
                      )}
                    </SubtitleContent>
                  </InformationSection>
                </UpperSection>
                <DivideLine />
                <LinkSection>
                  <TextDataIcon
                    title="How to get there"
                    href={gmaps_link}
                    textSize={0}
                    Icon={IconLink}
                  />
                </LinkSection>
              </>
            )}
          </Popup>
        )
      )}
  </PopupWrapper>
  )
};
