import React, { SetStateAction, Dispatch } from "react";
import Button from "components/button/Button";
import ContentLoader from "components/contentLoader/ContentLoader";
import Popup from "components/popup/Popup";
import { BreakpointI } from "customHooks/useBreakpoint";
import {
  BottomSection,
  TitleStyled,
  ContentStyled,
  PopupWrapper,
} from "./CityPopupStyles";

export interface PopupDataI {
  title: string;
  content: string;
}

export interface CityPopupI extends BreakpointI {
  setPopupState?: Dispatch<SetStateAction<boolean>>;
  additionalCallback: Function;
  popupData: PopupDataI;
}

export const CityPopup = ({
  setPopupState,
  popupData,
  breakpoint,
  additionalCallback,
}: CityPopupI) => {
  const { title, content } = popupData;

  return (
    <PopupWrapper>
      <Popup
        breakpoint={breakpoint}
        setPopupState={setPopupState}
        displayHeaders
      >
        {!Object.keys(popupData).length ? (
          <ContentLoader isDarkMode />
        ) : (
          <>
            <TitleStyled>{title}</TitleStyled>
            <ContentStyled>{content}</ContentStyled>
            <BottomSection>
              <Button
                buttonType="secondaryDark"
                label="See points on the map"
                onClick={() => { if(setPopupState) setPopupState(false); additionalCallback(true) }}
              />
            </BottomSection>
          </>
        )}
      </Popup>
    </PopupWrapper>
  );
};
export default CityPopup;
