import styled from "styled-components";
import { ButtonI } from "./Button";

const ButtonStyles = styled("button") <ButtonI>`
  font-family: "Ubuntu", sans-serif;
  font-weight: 500;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  color: var(--primary-colors-7-capgemini-white);
  margin: 0.75rem;
  border-radius: 1.5rem;
  font-size: 1rem;
  transition: 0.5s;
  transition: visibility 0s;
  & path {
    fill: var(--primary-colors-7-capgemini-white);
  }

  ${(props) =>
    props.buttonType === "primaryLight" &&
    `
    background-color: var(--primary-colors-1-capgemini-blue);
    border: .15rem solid var(--primary-colors-1-capgemini-blue);
    &: hover {
      background-color: var(--primary-colors-2-capgemini-vibrant-blue);
      border: .15rem solid var(--primary-colors-2-capgemini-vibrant-blue);
    }
  `}
  ${(props) =>
    props.buttonType === "primaryLight" &&
    props.disabled &&
    `
    background-color: var(--primary-colors-3-capgemini-light-gray);
    border: .15rem solid var(--primary-colors-3-capgemini-light-gray);
    &: hover {
     background-color: var(--primary-colors-3-capgemini-light-gray);
     border: .15rem solid var(--primary-colors-3-capgemini-light-gray);
     cursor: default;
    }
  `}
  
  ${(props) =>
    props.buttonType === "primaryDark" &&
    `
    background-color: var(--primary-colors-1-capgemini-blue);
    border: .15rem solid var(--primary-colors-1-capgemini-blue);
    &: hover {
      background-color: var(--primary-colors-7-capgemini-white);
      border: .15rem solid var(--primary-colors-7-capgemini-white);
      color: var(--primary-colors-2-capgemini-vibrant-blue);
      & path {
        fill: var(--primary-colors-2-capgemini-vibrant-blue);
      }
    }
  `}
  
  ${(props) =>
    props.buttonType === "primaryDark" &&
    props.disabled &&
    `
    background-color: var(--primary-colors-3-capgemini-light-gray);
    opacity: 0.2;
    border: .15rem solid rgba(236, 236, 236, 0.2);
    color: rgba(18, 171, 219, 0.2);
    
    & path {
      color: rgba(18, 171, 219, 0.2);
    }
    &: hover {
      background-color: var(--primary-colors-3-capgemini-light-gray);
      opacity: 0.2;
      border: .15rem solid rgba(236, 236, 236, 0.2);
      color: rgba(18, 171, 219, 0.2);
      cursor: default;
      & path {
        fill: rgba(18, 171, 219, 0.2);
      }
    }
  `}

  ${(props) =>
    props.buttonType === "secondaryLight" &&
    `
    color: var(--primary-colors-1-capgemini-blue);
    background-color: transparent;
    border: .15rem solid var(--primary-colors-1-capgemini-blue);
    & path {
      fill: var(--primary-colors-1-capgemini-blue);
    }
    &: hover {
      color: var(--primary-colors-2-capgemini-vibrant-blue);
      border: .15rem solid var(--primary-colors-2-capgemini-vibrant-blue);
      & path {
        fill: var(--primary-colors-2-capgemini-vibrant-blue);
      }
    }
  `}
  ${(props) =>
    props.buttonType === "secondaryLight" &&
    props.disabled &&
    `
    color: var(--primary-colors-3-capgemini-light-gray);
    border: solid .15rem var(--primary-colors-3-capgemini-light-gray);
    & path {
      fill: var(--primary-colors-3-capgemini-light-gray);
    }
    &: hover {
      color: var(--primary-colors-3-capgemini-light-gray);
      border: solid .15rem var(--primary-colors-3-capgemini-light-gray);
      cursor: default;
      & path {
        fill: var(--primary-colors-3-capgemini-light-gray);
      }
    }
  `}
  ${(props) =>
    props.buttonType === "secondaryDark" &&
    `
    color: var(--primary-colors-1-capgemini-blue);
    background-color: transparent;
    border: .15rem solid var(--primary-colors-3-capgemini-light-gray);
    color: var(--primary-colors-3-capgemini-light-gray);
    & path {
      fill: var(--primary-colors-1-capgemini-blue);
    }
    &: hover {
      color: var(--primary-colors-7-capgemini-white);
      border: .15rem solid var(--primary-colors-7-capgemini-white);
      & path {
        fill: var(--primary-colors-7-capgemini-white);
      }
    }
  `}
  ${(props) =>
    props.buttonType === "secondaryDark" &&
    props.disabled &&
    `
    color: rgba(0, 112, 173, 0.5);
    background-color: transparent;
    border: .15rem solid rgba(0, 112, 173, 0.5);
    & path {
      fill: rgba(0, 112, 173,0.5);
    }
    &: hover {
      color: rgba(0, 112, 173, 0.5);
      border: .15rem solid rgba(0, 112, 173, 0.5);
      cursor: default;
      & path {
        fill: rgba(0, 112, 173, 0.5);
      }
    }
  `}
  
  ${(props) =>
    props.buttonType === "primaryPurple" &&
    `
    background-color: var(--primary-colors-6-capgemini-deep-purple);
    border: .15rem solid var(--primary-colors-6-capgemini-deep-purple);
  `}
  
  ${(props) =>
    props.buttonType === "mapButton" &&
    `
    transition: 0.7s;
    background-color: var(--primary-colors-1-capgemini-blue);
    border: .15rem solid var(--primary-colors-1-capgemini-blue);
    cursor: default;

  `}
  
  ${(props) =>
    props.size === "small" &&
    `
    font-size: .9rem;
    padding: .3rem .9rem;
  `}

  ${(props) =>
    props.size === "medium" &&
    `
    padding: .6rem 1.25rem;
  `}

  ${(props) =>
    props.size === "large" &&
    `
    font-size: 1.1rem;
    padding: .8rem 2rem;
  `}

    ${(props) =>
    props.borderRadius !== 0 &&
    `
    border-radius: ${props.borderRadius}px;
  `}

  ${(props) =>
    props.borderColor &&
    `
    border: .05rem solid ${props.borderColor};
  `}

  ${(props) =>
    props.isClickPoint &&
    `
    margin: 0;
    transform: translate(-50%,0);
    position: absolute;
    bottom: 2%;
    left: 50%;
    z-index: 1;
    cursor: none;
    background-color: var(--primary-colors-7-capgemini-white);
    color:  var(--primary-colors-5-capgemini-dark-grey);
    pointer-events: none;
    font-size: 0.75rem;
    line-height: 1.33;
    font-weight: 500;
    padding: 0.5rem 0.2rem;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    `}
  
  ${(props) =>
    props.icon &&
    `
    display: flex;
    align-items: center;
    & svg {
      margin-left: 0.5rem;
    }
    `}

    ${({isMapButton}) => isMapButton && `
        position: absolute;
        bottom: 8%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0.5rem 0.5rem;
        color: var(--primary-colors-1-capgemini-blue);
        font-family: Ubuntu;
        font-size: .9375rem;
        font-weight: 500;
        white-space: nowrap;
        pointer-events: auto; 
        max-width: 100vw;
    `}

`;

export default ButtonStyles;
