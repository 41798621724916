import { breakpoints } from "customHooks/useBreakpoint";
import styled from "styled-components";

interface ImageI {
  src?: string
}

export const ContentBoxTextWrapper = styled.div`
  padding-bottom: 1.5rem;
  width: 100%;
  @media (min-width: ${breakpoints.sm}px) {
    width: 60%; 
  }
`;

export const ContentBoxContainer = styled.div<{ color: string }>`
  border-radius: 0.25rem;
  margin-bottom: 1.25rem;
  padding: 0.5rem 1.25rem 0;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  background-color: ${(props) => props.color};
  color: var(--primary-colors-7-capgemini-white);
  @media (min-width: ${breakpoints.sm}px) {
    ${ContentBoxTextWrapper} {
      margin-right: 1rem;
    }
    flex-direction: row-reverse;
    &:nth-child(odd) {
      flex-direction: row;
      ${ContentBoxTextWrapper} {
        margin-left: 1rem;
      }
    }
  }
`;

export const ContentBoxTitle = styled.div`
  text-transform: uppercase;
  font-weight: 500;
  width: 80%;
  margin: 0.83em 0;

  h2 {
    margin: 0;
    font-size: 2rem;
    &:nth-child(even) {
      text-indent: 2.5rem;
    }
  }

  @media (min-width: ${breakpoints.lg}px) {
    width: 70%;
  }
`;

export const ContentBoxText = styled.div`
  font-size: 1rem;
  line-height: 1.43;
  width: 100%;

  .tooltip {
    position: relative;
    display: inline-block;
    color: var(--primary-colors-2-capgemini-vibrant-blue);
  }

  div {
    display: inline-block;
    margin: 0;
  }

  .tooltip .tooltiptext {
    font-size: 0.75rem;
    font-weight: 300;
    font: inherit;
    visibility: hidden;
    width: 18.75rem;
    background-color: var(--primary-colors-3-capgemini-light-gray);
    color: var(--primary-colors-5-capgemini-dark-grey);
    border-radius: 0.375rem;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -11rem;
    opacity: 0;
    transition: opacity 1s;
    box-shadow: 0 4px 2px -2px rgb(121, 122, 130);
    padding-left: 1rem;

    @media (max-width: ${breakpoints.md}px) {
      max-width: 90%;
      margin-left: unset;
      bottom: 1rem;
      transform: translateX(-50%);
      position: fixed;
    }
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: 0.5rem;
    border-width: 0.5rem;
    border-style: solid;
    border-top-color: var(--tertiary-colors-20-capgemini-blue-grey);
    @media (max-width: ${breakpoints.md}px) {
      content: unset;
    }
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  ul {
    margin: 0 0 0 5px;
    padding: 0 0 0 10px;
    line-height: 1.43;
    @media (min-width: ${breakpoints.xl}px) {
      line-height: 1.65;
    }
  }

  @media (min-width: ${breakpoints.xl}px) {
    width: 90%;
  }
`;

export const ContentBoxImgStyled = styled.img<ImageI>`
  width: 60%;
  display: flex;
  align-self: center;
  object-fit : contain;
  min-width: 15.75rem;
  max-width: 20rem;
  @media (min-width: ${breakpoints.sm}px) {
    width: 40%; 
  }
`;