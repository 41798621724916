import officeH from "assets/backup/wroclaw/office/office-h.webp";

const restaurantsBackup = [
  {
    id: 25,
    id_name: "la_maddalena",
    type: "restaurant",
    city: "Wroclaw",
    name: "La Maddalena",
    address: "Pomorska 1, 50-215 Wrocław",
    image: officeH,
    website_address: "http://lamaddalena.pl/kontakt/",
    gmaps_link: "https://goo.gl/maps/5byVqca7RvoLK24D7",
    is_visible: true,
  },
  {
    id: 26,
    id_name: "przystan",
    type: "restaurant",
    city: "Wroclaw",
    name: "Przystań",
    address: "Księcia Witolda 2, 50-202 Wrocław",
    image: officeH,
    website_address: "https://przystan.wroc.pl/indeks/en/contact/",
    gmaps_link: "https://goo.gl/maps/cYED1YCScroGyLaq8",
    is_visible: false,
  },
  {
    id: 27,
    id_name: "campo",
    type: "restaurant",
    city: "Wroclaw",
    name: "Campo",
    address: "Podwale 83, 50-414 Wrocław",
    image: officeH,
    website_address: "https://campomoderngrill.pl/",
    gmaps_link: "https://goo.gl/maps/95G4qCs4DGqbyL497",
    is_visible: true,
  },
  {
    id: 28,
    id_name: "pod_papugami",
    type: "restaurant",
    city: "Wroclaw",
    name: "Pod Papugami",
    address: "Sukiennice 9a, 50-107 Wrocław",
    image: officeH,
    website_address: "https://podpapugami.com.pl/#kontakt",
    gmaps_link: "https://g.page/podpapugami?share",
    is_visible: true,
  },
  {
    id: 29,
    id_name: "craft",
    type: "restaurant",
    city: "Wroclaw",
    name: "Craft",
    address: "Plac Solny 4, 50-060 Wrocław",
    image: officeH,
    website_address: "https://craftrestauracja.pl/#contact",
    gmaps_link: "https://goo.gl/maps/nes2b9ih2nHaPowq7",
    is_visible: true,
  },
  {
    id: 30,
    id_name: "la_scala",
    type: "restaurant",
    city: "Wroclaw",
    name: "La Scala",
    address: "Rzeźnicza 24-25, 50-132 Wrocław",
    image: officeH,
    website_address: "https://www.facebook.com/LaScalaRestauracja/",
    gmaps_link: "https://goo.gl/maps/NFeLuBQa62iZbTzd8",
    is_visible: true,
  },
  {
    id: 31,
    id_name: "whiskey_in_the_jar",
    type: "restaurant",
    city: "Wroclaw",
    name: "Whiskey in the Jar",
    address: "Rynek 23-24, 50-101 Wrocław",
    image: officeH,
    website_address: "https://whiskeyinthejar.pl/wroclaw/",
    gmaps_link: "https://goo.gl/maps/gFVyrBVtJmB59hCWA",
    is_visible: true,
  },
  {
    id: 32,
    id_name: "winestone",
    type: "restaurant",
    city: "Wroclaw",
    name: "Winestone",
    address: "Galeria Dominikańska, plac Dominikański 3, 50-159 Wrocław",
    image: officeH,
    website_address:
      "https://restaurants.accor.com/gb/restaurant-3374_R001-winestone-wroclaw.shtml",
    gmaps_link: "https://goo.gl/maps/w4XuteUGeL32gZfR8",
    is_visible: true,
  },
  {
    id: 33,
    id_name: "lwia_brama",
    type: "restaurant",
    city: "Wroclaw",
    name: "Lwia Brama",
    address: "Katedralna 9, 50-328 Wrocław",
    image: officeH,
    website_address: "https://restauracjalwiabrama.pl/rezerwacja/",
    gmaps_link: "https://g.page/lwiabrama2?share",
    is_visible: true,
  },
  {
    id: 34,
    id_name: "the_cork",
    type: "restaurant",
    city: "Wroclaw",
    name: "The Cork",
    address: "Igielna 14/15, 50-117 Wrocław",
    image: officeH,
    website_address: "https://www.facebook.com/thecorkwroclaw/",
    gmaps_link: "https://goo.gl/maps/165HQuyvM5HQL1HR7",
    is_visible: true,
  },
  {
    id: 35,
    id_name: "bernard",
    type: "restaurant",
    city: "Wroclaw",
    name: "Bernard",
    address: "Rynek 35, 50-102 Wrocław",
    image: officeH,
    website_address: "http://bernard.wroclaw.pl/kontakt.html",
    gmaps_link: "https://g.page/bernard-wroclaw-res?share",
    is_visible: true,
  },
];

export default restaurantsBackup;
