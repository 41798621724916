import React from "react";
import { CalendarDay } from "components/calendarDay/CalendarDay";
import { isDatePast } from "util/timeChecks/timeChecks";
import { isMobile } from "customHooks/useBreakpoint";
import { AgendaCalendarWrapper } from "./AgendaCalendarStyles";

export interface AgendaCalendarDayI {
  date: string;
  weekday: string;
}

interface AgendaCalendarI {
  dates: AgendaCalendarDayI[];
  selectedDay: number;
  setSelectedDay: Function;
}

export const AgendaCalendar = ({
  dates,
  selectedDay,
  setSelectedDay,
}: AgendaCalendarI) => (
  <AgendaCalendarWrapper>
    {dates.length > 0 &&
      dates.map((day: { date: string; weekday: string }, index) => (
        <CalendarDay
          key={day.weekday}
          date={`${day.date.substring(8, 10)}.${day.date.substring(5, 7)}`}
          weekday={isMobile() ? day.weekday.substring(0, 3) : `${day.weekday},`}
          receivedFunc={() => setSelectedDay(index)}
          isActive={selectedDay === index}
          isPast={isDatePast(day.date)}
        />
      ))}
  </AgendaCalendarWrapper>
);
