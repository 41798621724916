import React from "react";
import { ModalSectionHeader } from "components/modalSectionHeader/ModalSectionHeader";
import Tile, { TileI } from "./tile/Tile";
import { YouGainWrapper } from "./YouGainStyles";

interface YouGainI {
  youGainTiles: TileI[];
}

const YouGain = ({ youGainTiles }: YouGainI) => (
  <YouGainWrapper>
    <ModalSectionHeader text="You Gain" />
    <>
      {youGainTiles.length > 1 &&
        youGainTiles.map(({ header, content, image, color }) => (
          <Tile
            key={header}
            header={header}
            content={content}
            image={image || ""}
            color={color}
          />
        ))}
    </>
  </YouGainWrapper>
);

export default YouGain;
