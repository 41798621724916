const monopol = {
  left: 135,
  top: 33,
  desiredLeft: "",
  desiredTop: "",
  id_name: "monopol",
  how_to_get_there: "https://goo.gl/maps/BXvtPQWXejcESpTv6"
};

const radisson = {
  left: 142,
  top: 19,
  desiredLeft: "",
  desiredTop: "",
  id_name: "radisson",
  how_to_get_there: "https://goo.gl/maps/i3GHLpv5zDCdj47D7",
};

const art = {
  left: 129,
  top: 19,
  desiredLeft: "",
  desiredTop: "",
  id_name: "art",
  how_to_get_there: "https://g.page/ArtHotelWroclaw?share",
};

const puro = {
  left: 120,
  top: 27,
  desiredLeft: "",
  desiredTop: "",
  id_name: "puro",
  how_to_get_there: "https://goo.gl/maps/qYURh6rFymLW5YR16",
};

const mercure = {
  left: 145,
  top: 25,
  desiredLeft: "",
  desiredTop: "",
  id_name: "mercure",
  how_to_get_there: "https://g.page/mercure-wroclaw-centrum?share",
};

const mariott = {
  left: 130,
  top: 26,
  desiredLeft: "",
  desiredTop: "",
  id_name: "mariott",
  how_to_get_there: "https://goo.gl/maps/R8ZmqvDsHZKKvPKu6",
};

export const hotelsLocation = [monopol, radisson, art, puro, mercure, mariott];

export const mobilePositionHotelsCluster = {
  width: 60,
  height: 280,
  backgroundWidth: 640,
  backgroundHeight: 1136,
};

