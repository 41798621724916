import React, { useContext } from "react";
import PageHeader from "components/pageHeader/PageHeader";
import MuteButtonContainer from "containers/muteButton/MuteButton";
import Sidebar from "containers/sidebar/Sidebar";
import { isMobile } from "customHooks/useBreakpoint";
import { AppContext } from "App";
import {matchPath, useLocation} from "react-router-dom";
import { MainPaneStyles, MainPaneChild } from "./MainPaneStyles";
import {AboutUsAgendaMobileIcon} from "../aboutUsAgendaMobileIcon/AboutUsAgendaMobileIcon";
import {AgendaMobileIcon} from "../agendaMobileIcon/AgendaMobileIcon";

const MainPane = (props?: any) => {
  const { breakpoint } = useContext(AppContext);
  const { pathname } = useLocation();
  const isAboutAss = matchPath("/aboutssc", pathname)?.isExact;

  return (
    <MainPaneStyles ref={props.refDrill}>
      {!isMobile(breakpoint) ? <Sidebar breakpoint={breakpoint} /> : ""}

      <PageHeader isDarkMode={false} breakpoint={breakpoint} />
      <MainPaneChild >{props.children}</MainPaneChild>
      {!isMobile(breakpoint) ? (
        <MuteButtonContainer />
      ) : (
          isAboutAss ? <AgendaMobileIcon /> : <AboutUsAgendaMobileIcon />
      )}
    </MainPaneStyles>
  );
};

export default MainPane;
