import React from "react";
import { OurCompetences } from "containers/ourCompetences/OurCompetences";
import { ModalPage } from "./ModalPagesStyles";

const OurCompetencesContent = ({
  breakpoint,
  carouselData,
  ourCompetencesTiles,
  ourCompetencesLogos,
}: any) => (
  <ModalPage id="OurCompetences" breakpoint={breakpoint}>
    <OurCompetences
      breakpoint={breakpoint}
      carouselData={carouselData}
      ourCompetencesTiles={ourCompetencesTiles}
      ourCompetencesLogos={ourCompetencesLogos}
    />
  </ModalPage>
);

export default OurCompetencesContent;
