import useFetch from "customHooks/useFetch";
import { officePopupBackupData } from "assets/backup/wroclaw/office/popupBackupData";
import hotelsPoiBackup from "assets/backup/wroclaw/hotelsBackup";
import { cityPopupBackupData } from "assets/backup/wroclaw/cityPopupBackupData";
import airportPopupBackup from "assets/backup/wroclaw/airport/airportPopupBackup";
import poisBackup from "assets/backup/wroclaw/poisBackup";
import restaurantsBackup from "assets/backup/wroclaw/restaurantsBackup";

export const WroclawFetchData = () => {
  const officeData: any = useFetch("/wroclaw/screen", {}, officePopupBackupData)
    .response;

  const hotelsData: any = useFetch(
    "/wroclaw/hotels",
    [],
    hotelsPoiBackup
  ).response;

  const cityData: any = useFetch(
    "/wroclaw/welcome",
    [],
    cityPopupBackupData
  ).response;

  const airportData: any = useFetch(
    "/wroclaw/airports",
    {},
    airportPopupBackup
  ).response;

  const attractionData: any = useFetch(
    "/wroclaw/attractions",
    [],
    poisBackup
  ).response;

  const restaurantData: any = useFetch(
    "/wroclaw/restaurants",
    [],
    restaurantsBackup
  ).response;

  const dataLoaded = [officeData, hotelsData, cityData, airportData, attractionData, restaurantData].every(
    (element: any) => element.length
  );
  return {
    dataLoaded,
    officeData,
    hotelsData,
    cityData,
    airportData,
    attractionData,
    restaurantData
  };
};
