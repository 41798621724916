import styled from "styled-components";
import { ServiceShapeContainerI } from "./ServiceShape";

export const ServiceShapeIcon = styled.div`
  grid-column: 1 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
  & img {
    transition: 0.6s;
    border-radius: 50%;
    background-color: var(--primary-colors-1-capgemini-blue);
  }
  margin-right: 0.5rem;
`;
export const ServiceShapeContent = styled.div`
  grid-column: 2 / 3;
`;

export const ServiceShapeHeaderText = styled.p`
  font-weight: 500;
  font-size: 1rem;
  color: var(--primary-colors-1-capgemini-blue);
  margin-bottom: 0;
`;
export const ServiceShapeText = styled.p`
  font-size: 0.875rem;
  line-height: 1.43;
  color: var(--primary-colors-5-capgemini-dark-grey);
  margin-top: 0;
`;
export const ServiceShapeContainer = styled.div<ServiceShapeContainerI>`
  display: inline-grid;
  grid-template-columns: 1fr 4fr;
  padding: 0rem 2.75rem 0rem 1.125rem;
  border-radius: 3rem;
  border: solid 2px var(--primary-colors-3-capgemini-light-gray);
  background-color: var(--primary-colors-7-capgemini-white);
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  transition: 0.6s;

  &:focus,
  &:hover,
  &:active {
    border: solid 2px var(--primary-colors-2-capgemini-vibrant-blue);
    background-color: var(--primary-colors-7-capgemini-white);
    cursor: pointer;
  }

  &:focus ${ServiceShapeIcon} img,
  &:hover ${ServiceShapeIcon} img,
  &:active ${ServiceShapeIcon} img {
    background-color: var(--primary-colors-2-capgemini-vibrant-blue);
  }

  ${(props) =>
    props.isFocused &&
    `
        border: solid 2px var(--primary-colors-2-capgemini-vibrant-blue);
        background-color: var(--primary-colors-7-capgemini-white);
        cursor: pointer;
        
      & ${ServiceShapeIcon} img {
        background-color: var(--primary-colors-2-capgemini-vibrant-blue);
      }
    `};
`;
